<template>
    <div class="notification-view">
        
        <div style="min-height: 25px;">
            <div 
                v-if="notificationsCount" 
                class="notificaiton-read" @click="markNotificationAsRead"> 
                Mark notifications as read
            </div>
        </div>
        <table class="table-users">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>User</th>
                    <!-- <th>Fields</th> -->
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="notification in notifications" :key="'notification' + notification.created" 
                    :class="`is-read-${ notification.is_read ? 'true' : 'false' }`">
                    <td class="time-relative" :title="new Date(notification.created).toLocaleDateString() + ' ' + new Date(notification.created).toLocaleTimeString()">{{ formatDate(notification.created) }}</td>
                    <td>{{ notification.message }}</td>
                    <td>{{ notification.username }}</td>
                    <!-- <td>{{ notification.fields }}</td> -->
                    <td>
                        <img 
                            @click="goToElement(notification)" 
                            src="@/assets/preview.svg" 
                            alt="close" 
                            title="preview" 
                            class="preview"/>
                        <!-- <br>
                        file - {{ notification.kyc_file }} <br>
                        person - {{ notification.person }} <br>
                        entity - {{ notification.entity }} <br>
                        mbd - {{ notification.mbd_related }} <br>
                        ubo - {{ notification.ubo_related }} -->
                    </td>
                </tr>
            </tbody>
        </table>
        <vue-awesome-paginate
            class="pagination"
            :total-items="notificationsCountTotal"
            :items-per-page="50"
            :max-pages-shown="10"
            v-model="currentPage"
            @click="clickCallback"
        />
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, defineEmits } from 'vue'

const emit = defineEmits(['updatingURL'])

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);


import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'
import Utils from '@/utils/index.js'
import store from '../store';
import { mapState } from 'vuex';
import { VuejsPaginate } from 'vuejs-paginate';

import API from '../api/index.js'


const router = useRouter()

const notifications = ref([])
const user = computed(() => store.state.user);
const notificationsCountTotal = ref(null);
const notificationsCount = computed(() => store.state.notificationsCount);
const currentPage = ref(1);
onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }
    if (user.value.type !== 'COMPLIANCE_OFFICER') {
        router.push('/user-info')
        return;
    }
    const response = await API.getNotificationList(currentPage.value);
    await API.getNotificationCount();
    notifications.value = response.notifications
    notificationsCountTotal.value = response.count
});
async function clickCallback(pageNum) {
    currentPage.value = pageNum
    const response = await API.getNotificationList(currentPage.value);
    notifications.value = response.notifications
    notificationsCountTotal.value = response.count
}
async function markNotificationAsRead() {
    await API.markNotificationAsRead('ALL')
    notifications.value.forEach(async (notification) => {
        notification.is_read = true
    })
    API.getNotificationCount();
}
function formatDate(date) {
    return dayjs(date).fromNow();
}

function selectId(element) {
    let id = ''
    let base = 'corporate'
    if (element.kyc_file) {
        id = element.kyc_file
    }
    if (element.person && !id) {
        id = element.person
    }
    if (element.entity && !id) {
        id = element.entity
    }
    if (element.mbd_related) {
        if (!id) {
            id = element.mbd_related
        }
        base = 'mbod'
    }
    if (element.ubo_related) {
        if (!id) {
            id = element.ubo_related
        }
        if (base != 'mbod') {
            base = 'ubo'
        }
    }
    return {
        base,
        id: 'id' + id
    }
    // return `?view=${base}&focus_element=id${id}`
}
async function goToElement(element) {
    await API.markNotificationAsRead(element._id)
    API.getNotificationCount();
    let info = selectId(element)
    emit('updatingURL', info)
}
</script>

<style scoped lang="scss">



.notification-view {
    width: 80vw;
    margin: auto;
}
.time-relative {
    width: 100px;
    padding-right: 0;
}
.table-users {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #e6eaed;
}
.is-read-false {
    background: #f9f9f9;
}
td {
    padding: 10px 30px;
    text-align: left;
}
th {
    text-align: left;
    padding: 10px 30px;
    background: #E6EAED;
}
.preview {
    padding: 10px;
    cursor: pointer;
    width: 30px;
}
.pagination {
    display: flex;
    gap: 10px;

  &:deep(li) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

  }
  &:deep(.paginate-buttons) {
    height: 40px;

    width: 40px;
   
    border-radius: 20px;

    cursor: pointer;

    background-color: rgb(242, 242, 242);

    border: 1px solid rgb(217, 217, 217);

    color: black;
  }

  &:deep(.paginate-buttons:hover) {
    background-color: #d8d8d8;
  }

  &:deep(.active-page) {
    background-color: #3498db;

    border: 1px solid #3498db;

    color: white;
  }

  &:deep(.active-page:hover) {
    background-color: #2988c8;
  }
}

.notificaiton-read {
    width: 100%;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
}
</style>