<template>
    <div class="flex column bg-white">
        <div id="editor">

        </div>
    </div>
</template>


<script setup>

import Quill from 'quill';
import "quill/dist/quill.snow.css";

import { ref, onMounted, defineEmits, computed } from 'vue';
const user = computed(() => store.state.user);
const emit = defineEmits(['quillContent']);
const props = defineProps(['commentSection']);

import { useRouter } from 'vue-router'
const router = useRouter()

const formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
];
let quill = null;


onMounted(() => {
  if (![
    'COMPLIENCE_OFFICER',
    'EXTERNAL_AUDITOR',
    'INTERNAL_AUDITOR'
  ].includes(user.type)) {
    router.push({query: {view: ''}})
  }

  quill = new Quill("#editor", {
    formats,
    theme: "snow",
  });

  quill.on("text-change", () => {
    emit("quillContent", quill.getContents());
  });
  if (props.commentSection) {
    console.log('commentSection', props.commentSection)
    quill.setContents(props.commentSection);
  }
});
</script>

<style lang="scss">
    #editor {
        height: calc(100vh - 400px);
    }
    .bg-white {
        background: white;
    }
    .ql-toolbar {
        text-align: left;
    }
</style>