<template>
    <div class="main-section-wrapper">
      <div class="wrapper-of-uploade">
        <div class="flex column gap-10">
          <div class="text" v-if="title"> 
            <div 
              v-for="(part, index) in titleParts" 
              :class="`
                title ${props.second_line_normal ? 'second_line_normal' : ''} 
                title-len${titleParts.length} 
                title-${index}`">
              {{ part }}
            </div>
          </div>
          <div class="additinal-info flex row ">
            <div v-if="yearPresent">
              <InpComponent 
                  type="options"
                  :options="yearOptions"  
                  theme="light" 
                  :label="year_title || 'Year'"
                  key_ref="year"
                  :value="localYear" 
                  @inputChange="inputChangeFunc"
                  :error="year_error"
              />
            </div>
            <div v-if="fiscal_year_ending_on_present">
              <InpComponent 
                  type="date"
                  theme="light" 
                  label="Fiscal Year Ending On"
                  key_ref="fiscal_year_ending_on"
                  :value="localFiscalYear" 
                  @inputChange="inputChangeFunc"
                  :error="fiscal_year_ending_on_error"
              />
            </div>
          </div>
        </div>
        <button v-if="!filePresentLocal" class='area-width' 
          :class="{'full-width': fullWidth }"
          
          @click="$refs.fileInput.click()">
          <img src="@/assets/pin.svg">
          Attach File
        </button>
      </div>
      <div v-if="filePresentLocal" class="margin-div margin-top-10 buttons">
        <button @click="openFile">Open File</button>
        <button @click="Download">Download</button>
        <button v-if="user.type != 'DATA_CONTROLLER' "@click="DeleteFile">Delete</button>
      </div>
      <div v-if="issueDatePresent" class="styled-input" :class="error ? 'error-present': ''">
        <label class="light_label" :for="inputId">{{ label }}</label>
          <input :value="`${issueDateValue ? issueDateValue.slice(0,10) : null}`" type="date" class='light_label light' @input="dateInputChangeFunc">
      </div>

      <div
        class="drop-area"
        @dragover.prevent="dragOver"
        @dragleave.prevent="dragLeave"
        @drop.prevent="dropFile"
        @click="$refs.fileInput.click()"
        :class="{ 'drag-over': isDragOver, 'full-width': fullWidth }"
        >
          <p v-if="filePresentLocal">File Attached <br>{{ filePresentLocal.filename }}</p>
          <p v-else-if="!selectedFile">Or move the file into this area</p>
          <p v-else>Selected File: {{ selectedFile.name }}</p>
          <input type="file" ref="fileInput" accept=".pdf" style="display: none" @change="handleFileChange" />
      </div>
    </div>
</template>

<script setup>
import { ref, defineEmits, computed, withDefaults } from 'vue'
import InpComponent from '@/components/InpComponent.vue'

import API from '../api/index.js'
import store from '../store';

const user = computed(() => store.state.user);


const emit = defineEmits(['handleFileChange', 'dateInputChangeFunc', 'changeInfo' ],)


const selectedFile = ref(null)
const props = defineProps([
  'title', 'fileType', 
  'filePresent', 'issueDatePresent', 
  'issueDateValue', 'error', 'file',
  'year_title',
  'fiscal_year_ending_on_present',
  'fiscal_year_ending_on',
  'second_line_normal', 'fullWidth',
  'label', 'index', 'year', 'yearPresent'])
const titleParts = computed(() => props.title && props.title.split('\n'));
const isDragOver = ref(false)

const fiscal_year_ending_on_error = ref('')
const year_error = ref('')
const filePresentLocal = ref(props.filePresent)
const localFile = ref(props.file)
function dragOver() {
      isDragOver.value = true;
    }

const localFiscalYear = ref(props.fiscal_year_ending_on)
const localYear = ref(props.year)

function dragLeave() {
    isDragOver.value = false;
}
function dropFile(event) {
    isDragOver.value = false;
    handleFileChange(event);
}
function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    
    if (event_type == 'year') {
      localYear.value = event_value
    }
    if (event_type == 'fiscal_year_ending_on') {
      localFiscalYear.value = event_value
    }


    let year_val = localYear.value;
    let fiscal_year_ending_on_val = localFiscalYear.value;
    if (year_val && fiscal_year_ending_on_val) {
      if (+year_val > +fiscal_year_ending_on_val.slice(0, 4) &&  +year_val != +fiscal_year_ending_on_val.slice(0, 4)) {
        fiscal_year_ending_on_error.value = 'Years do not match';
        year_error.value = 'Years do not match';
        return;
      }
    }
    fiscal_year_ending_on_error.value = '';
    year_error.value = '';
   
    emit('changeInfo', {
      event_type: 'year', 
      event_value: localYear.value, 
      fileType: props.fileType
    })

    emit('changeInfo', {
      event_type: 'fiscal_year_ending_on', 
      event_value: localFiscalYear.value, 
      fileType: props.fileType
    })

}

const yearOptions = ref([
    {value: '2021', text: '2021'},
    {value: '2022', text: '2022'},
    {value: '2023', text: '2023'},
    {value: '2024', text: '2024'},
    {value: '2025', text: '2025'},
    {value: '2026', text: '2026'},
    {value: '2027', text: '2027'},
    {value: '2028', text: '2028'},
    {value: '2029', text: '2029'},
    {value: '2030', text: '2030'},
    {value: '2031', text: '2031'},
    {value: '2032', text: '2032'},
    {value: '2033', text: '2033'},
    {value: '2034', text: '2034'},
    {value: '2036', text: '2036'},
    {value: '2037', text: '2037'},
])

function handleFileChange(event) {
  const files = event.target.files || event.dataTransfer.files;
  
  if (files.length > 0) {
      selectedFile.value = files[0];
  }

  emit('handleFileChange', {
    file: files[0], 
    fileType: props.fileType, 
    id: props.index
  })
}



function dateInputChangeFunc(event) {
  emit('dateInputChangeFunc', {date: event.target.value, fileType: props.fileType})
}

async function DeleteFile() {
  if (window.confirm('Do you want to delete this file?')) {
    const result = await API.deleteObject(localFile.value.id, 'kyc_file', filePresentLocal.value.filename + 'file');
    if (result) {
      store.dispatch('addNotification', {text: filePresentLocal.value.filename + ' file has been deleted', type: 'success'})
      selectedFile.value = null;
      filePresentLocal.value = null;
      localFile.value = null;
    }
  }
}
function Download() {
  API.DownloadKYCFile(localFile.value.serverName, localFile.value.filename);

}


</script>
<style scoped lang="scss">
  .drop-area {
      width: 335px;
      height: 115px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8997A5;
      border-radius: 5px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e");
      margin-top: 8px;
      cursor: pointer;
      &:hover {
          background-color: #003f891a;;
      }
   
  }

.margin-top-10 {
  margin-top: 10px;
}
.buttons {
  height: 37px;
}
.margin-div {
  display: flex;
  gap: 10px;
  button {
    flex: 1;
  }
}

.main-section-wrapper {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.wrapper-of-uploade {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  justify-content: flex-end;
}

.area-width {
  width: 335px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
      margin-right: 10px;
  }
}
.title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.title-0.title-len2 {
  margin: 0;
}

.title-1.title-len2:not(.second_line_normal) {
  font-size: 16px;
  color: black;
}

.styled-input {
    margin-top: 10px;
    max-width: 340px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #3D5873;
    text-align: left;
  }
  
  input, select {
    background: #38314e;
    border: 1px solid #2b263a;
    outline: none;
    caret-color: #2b263a;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 16px;
    height: 22px;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0;
    transition: padding 0.3s;
    &.select-elem {
      width: 100%;
      height: 30px;
    }
    &:focus {
      padding-bottom: 10px;
    }
    padding-left: 0;
  }
  .light {
    background: unset;
    color: black;
    caret-color: black;
  }
  
  .dark {
    background: #38314e;
    color: white;
    caret-color: #2b263a;
  }
  .error-present {
    input, select {
      border-color: red;
      border-width: 1.5px;
    }
  }
  .light_label {
    font-size: 13px;
    margin-bottom: 0;

  }
  .error {
    color: red;
    text-align: left;
    padding-left: 14px;
  }

  .full-width[class] {
    width: 100%;
  }
  .additinal-info {
    display: flex;
    gap: 40px;
    margin-right: 10px;
    > * {
      flex: 1;
    }
  }
</style>