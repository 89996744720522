<template>
    <div>
        <AdminTable v-if="users" 
            :data="users" 
            :header="header" 
            :headers="headers" 
        ></AdminTable>

    </div>
</template>


<!-- { -->
<!-- text: 'Error loading user data',
type: 'error'} -->

<script>

import AdminTable from '@/components/AdminTable.vue'

import { mapState } from 'vuex';
import BO_API from '../api/bo_api.js'
export default {
    name: 'UsersView',
    components: {
        AdminTable,
    },
    data() {
        return {
            users: null,
            header: 'Users List',
            headers: [
                {"name": 'Name', "key": 'username', "link_field": "_id"}, 
                {"name": 'Email', "key": 'email'},
                {"name": "Registered", "key": 'created'},
                {"name": 'Last Login', "key": 'lastLogin'},

            ]
        };
    },
    async mounted(){
        await this.loadAllData()
    },
    methods: {
        async loadAllData(){
            await this.loadUsageData()
        },
        async loadUsageData() {
            const data_resp = await BO_API.getUsers();
            if (data_resp && data_resp.data && data_resp.data.users){
                this.users = data_resp.data.users
            }
        }
    },
    computed: {
        ...mapState(['']),
    },
};
</script>

<style scoped lang="scss">
    .main {
        width: 100vw;
        height: calc(100vh - 61px);
        display: flex;
        background: white;
    }
    .left-nav {
        padding: 20px;
        box-sizing: border-box;
        flex: 1;
        border-right: 1px solid gray;
    }
    .main-area {
        padding: 20px;
        box-sizing: border-box;
        flex: 5;
    }
    .date-selectors {
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 10px;
    }
</style>
