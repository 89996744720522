<template>
       
    <div class="flex column gap30 margin-top-40" :id="'id' + entity.id">
        <div class="main-holder flex column">
            <InpComponent
                class="name-input"
                label="Name of the Entity"
                type="text"
                light_label="true"
                placeholder="Corporation Inc."
                theme="light"
                :key_ref="'name'"
                :value="entity.name.value"
                :error="entity.name.error"
                @inputChange="inputChangeFunc"
            ></InpComponent>
            <AddressComp
                :key="`address-${entity.id}`"
                class="name-input"
                :address="entity.address"
                @addressChange="changeAddress"
            >
            </AddressComp>
            <table style="height: 100%;">

                <thead>
                    <tr>
                        <td>Available Documents</td>
                        <td>Y</td>
                        <td>N</td>
                        <td>Comments Data Processor</td>
                        <td>Comments Complience Officer</td>

                    </tr>
                </thead>
                <tbody>
                    <TableFileRow 
                    :id="entity.entity_aoi?.id"
                    @handleFileChange="handleFileChange"
                    @commentChange="commentChange"
                    :comment_data_handler_value="entity.entity_aoi?.comment_data_handler"
                    :comment_compliance_officer_value="entity.entity_aoi?.comment_compliance_officer"
                    fileType='entity_aoi' 
                    :filePresent="!entity.entity_aoi.new_file && entity.entity_aoi?.file"  
                    :file="entity?.entity_aoi?.file"  
                    :second_line_normal="true"
                    :title="`Articles of Incorporation / Articles of Association /\nMemorandum of Association`" 
                />
                <TableFileRow 
                    :id="entity.entity_coc?.id"
                    @handleFileChange="handleFileChange"
                    @commentChange="commentChange"
                    :comment_data_handler_value="entity.entity_coc?.comment_data_handler"
                    :comment_compliance_officer_value="entity.entity_coc?.comment_compliance_officer"
                    fileType='entity_coc' 
                    :filePresent="!entity.entity_coc.new_file && entity.entity_coc?.file"  
                    :file="entity?.entity_coc.file"  
                    :second_line_normal="true"
                    :title="`Chamber of Commerce / Certificate of Incorporation`" 
                />
                <TableFileRow 
                    :id="entity.entity_members?.id"
                    @handleFileChange="handleFileChange"
                    @commentChange="commentChange"
                    :comment_data_handler_value="entity.entity_members?.comment_data_handler"
                    :comment_compliance_officer_value="entity.entity_members?.comment_compliance_officer"
                    fileType='entity_members' 
                    :filePresent="!entity.entity_members.new_file && entity.entity_members?.file"  
                    :file="entity?.entity_members.file"  
                    :second_line_normal="true"
                    :title="`Share Register / Register of Members`" 
                />
                <TableFileRow 
                    :id="entity.entity_rodo?.id"
                    @handleFileChange="handleFileChange"
                    @commentChange="commentChange"
                    :comment_data_handler_value="entity.entity_rodo?.comment_data_handler"
                    :comment_compliance_officer_value="entity.entity_rodo?.comment_compliance_officer"
                    fileType='entity_rodo'
                    :filePresent="!entity.entity_rodo.new_file && entity.entity_rodo?.file"  
                    :file="entity?.entity_rodo.file"  
                    :title="`Register of Directors(optional)`" 
                />
                <TableFileRow 
                    :id="entity.entity_soi?.id"
                    @handleFileChange="handleFileChange"
                    @commentChange="commentChange"
                    :comment_data_handler_value="entity.entity_soi?.comment_data_handler"
                    :comment_compliance_officer_value="entity.entity_soi?.comment_compliance_officer"
                    fileType='entity_soi'
                    :filePresent="!entity.entity_soi.new_file && entity.entity_soi?.file"  
                    :file="entity?.entity_soi.file"  
                    :title="`Certificate of Incumbency`" 
                />
                
                <TableFileRow 
                    v-if="isSupervised && isAbroad"
                    :id="entity.entity_license?.id"
                    @handleFileChange="handleFileChange"
                    @commentChange="commentChange"
                    :comment_data_handler_value="entity.entity_license?.comment_data_handler"
                    :comment_compliance_officer_value="entity.entity_license?.comment_compliance_officer"
                    fileType='entity_license' 
                    :filePresent="!entity.entity_license.new_file && entity.entity_license?.file"  
                    :file="entity?.entity_license.file"  
                    :title="`License`" 
                />
                </tbody>
            </table>
          
        </div>
        <div v-if="!isSupervised && isAbroad" class='ubo_subsection'>
            <div class="flex row align-center mb-20">
                <h3 class="doc-header">Ultimate Beneficial Owners that own more than 10% of the company </h3>
                <div style="margin-left: auto">
                    <button class="add-new no-margin fit-content" @click="addNewUBO()">Add UBO</button>
                </div>
            </div>
            <UBO v-for="(ubo, index) in localEntity['mbd_ubo']"
                :key="`index_mbd-${ubo.id}`"
                :ubo="ubo.struct"
                :location_type="ubo.location_type"
                :id="ubo.id"
                :frozen_location="true"
                @handleStructureUpdates="handleStructureUpdates"
                @deleteUbo="deleteUbo"
                
                >
            </UBO>
        </div>
    </div>
</template>

<script setup>

import { ref, defineEmits, computed, withDefaults, defineProps } from 'vue'
import TableFileRow from '@/components/TableFileRow.vue'

import InpComponent from '@/components/InpComponent.vue'
import Utils from '@/utils/index.js'
import AddressComp from './AddressComp.vue'

import {
    getUboStructure
} from '../utils/kyc_utils.js'

import UBO from '@/views/UBO.vue'


const props = defineProps(['entity'])
const emit = defineEmits(['handleEntityChange'])
const localEntity = ref(props.entity)

const isSupervised = computed(() => {
    return (localEntity.value.supervised.new_value || 
        (localEntity.value.supervised.value.toString() == 'true' && localEntity.value.supervised.new_value === '')
    )
})
const isAbroad = computed(() => {
    return (
        (localEntity.value.location_type.new_value === 'abroad') ||
        (localEntity.value.location_type.value === 'abroad' && localEntity.value.location_type.new_value === '' )
    )
})

function changeAddress(event) {
    localEntity.value.address[event.property].new_value = event.value;
    emit('entityChange', {
        entity: localEntity.value
    })
    
}
function commentChange(event) {
    const comment_type = event.comment_type;
    localEntity.value[event.fileType][comment_type] = event.comment;
    localEntity.value[event.fileType][`new_${comment_type}`]= true;
    emit('entityChange', {
        entity: localEntity.value
    })
}

function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    localEntity.value[event_type].new_value = event_value;
    emit('entityChange', {
        id: localEntity.value.id,
        entity: localEntity.value
    })
}

function handleStructureUpdates(event) {
    let dataUbo = localEntity.value['mbd_ubo'].find(el => event.index === el.id)
    dataUbo = event.ubo
    emit('entityChange', {
        id: localEntity.value.id,
        entity: localEntity.value
    })
    // if (event.file) {
    //     let cell = selectedFiles.value[event.file.index] || [];
    //     cell.push({
    //         type: event.file.type,
    //         file: event.file.file, 
    //     }) 
    //     selectedFiles.value[event.file.index] = cell;
    // }
}

function deleteUbo(event) {
    let index = localEntity.value['mbd_ubo'].findIndex(el => el.id = event.id)
    if (index != -1) {
        store.dispatch('addNotification', {text: 'Something went wrong', type: 'error'})
        return;
    }
    localEntity.value['mbd_ubo'].splice(index, 1);
    emit('entityChange', {
        id: localEntity.value.id,
        entity: localEntity.value
    })
}



function addNewUBO(){
    const newUbo = getUboStructure(
        'abroad', 
    )
    localEntity.value['mbd_ubo'].unshift(newUbo)
}
function handleFileChange(event) {
    localEntity.value[event.fileType].file = event.file;
    localEntity.value[event.fileType].new_file = true
    emit('entityChange', {
        id: localEntity.value.id,
        entity: localEntity.value
    })
}
</script>

<style scoped lang="scss">
.flex {
    display: flex;
    &.row {
        flex-direction: row;
    }
    &.column {
        flex-direction: column;
    }
}
.passport-fields {
    margin-top: 20px;
}
.name-input {
    max-width: 340px;

}
.supervision {

}
.flex-start {
    align-items: flex-start;

}
.gap30 {
    gap: 30px;
}
textarea {
    resize: none; 
    box-sizing: border-box;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    padding: 15px;
    width: 685px;
    height: 150px;
}
.add-new {
    margin-left: auto;
}
.margin-top-40 {
    margin-top: 40px;
}
.margin-top-20 {
    margin-top: 20px;
}
.country-select {
    max-width: 340px;
}
.ubo_subsection {
    padding: 20px;
    background-color: rgb(236, 236, 236);
    margin-bottom: 40px;
    border-radius: 5px;
}
table {
        border-collapse: collapse;
    }
    thead {
        font-weight: bold;
    }
    td {
        padding: 10px;
        border: 1px solid black;
    }
</style>