<template>
    <div class>
    <table>
    <span class="title">{{header}}</span>

        <thead>
            <tr>
                <th v-for="(item, index) in headers" :key="`${index}-${item.name}-${header}`">
                   {{item.name}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in data" :key="`${index}-${item.name}`">
                <td v-for="(td_item, td_index) in headers" :key="`row-${td_index}-${td_item.name}`">
                    <router-link 
                        v-if="item[td_item.link_field]" 
                        :to="{
                            name: 'bo_user', 
                            params: {'id': item[td_item.link_field] },
                            props: true
                        }">{{item[td_item.key]}}</router-link>
                    <span v-else>
                        {{ 
                            td_item.format ? 
                            td_item.format(item[td_item.key]) 
                            : 
                            item[td_item.key] 
                        }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
</template>
  
  <script>
  // @ is an alias to /src
  
  export default {

    name: 'AdminTable',
    props: {
        data: Array,
        header: String,
        headers: Array,
    },
    data() {
        return {
        }
    },

    components: {
  
    }
  }
  </script>
  

<style scoped>
    td {
        padding: 8px;
    }
    tr {
        outline: 1px solid black;
    }
    table {
        position: relative;
        margin-top: 30px;
    }
    .title {
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        top: -25px;
        left: 0;
        right: 0;

    }

</style>