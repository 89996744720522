<template>
    <header class="header">
        
        <nav class="header-nav">
            
            <ul>
                <li v-if="user && user.is_super_user && user.type != 'DATA_CONTROLLER'">
                    <router-link to="/admin">Admin</router-link>
                </li>
                <li v-if="user && user.type != 'DATA_CONTROLLER'">
                    <router-link to="/user-info">User Information</router-link> 
                </li>
                <li v-if="user && user.type != 'DATA_CONTROLLER'">
                    <router-link to="/convert">Convert</router-link> 
                </li>
                <li v-if="user && user.type != 'DATA_CONTROLLER'">
                    <router-link to="/file-list">Uploads history</router-link> 
                </li>
                <li>
                    <router-link to="/contact">Contact</router-link> 
                </li>
                <li v-if="user && user.type != 'DATA_CONTROLLER'">
                    <router-link to="/saved-info">Saved Info</router-link> 
                </li>
                <li v-if="user && user.type != 'DATA_CONTROLLER'">
                    <router-link to="/wallet">Wallet</router-link> 
                </li>
                <li v-if="user">
                    <router-link to="/kyc">KYC</router-link> 
                </li>
                <li>
                    <span v-if="user && user.type != 'DATA_CONTROLLER'" @click="downloadSample()">Download Sample</span> 
                </li>
            </ul>
            <ul class="special">
                <li v-if="!user" class="register">
                    <router-link to="/register">Register</router-link>
                </li>
                <li v-if="!user">
                    <router-link to="/login">Login</router-link>
                </li>
            </ul>
        </nav>
        <div v-if="user" class='greeting section-white'>
            Welcome {{ user.username }} 
            <span v-if="credits_balance"> Your credits: {{credits_balance.toFixed(2)}}</span>
        </div>
        <div v-if="user" class='section-white logout' @click="logout()">
            Logout
        </div>
    </header>
</template>

<script>

import { mapState } from 'vuex';
import API from '../api/index.js'

export default {
    name: 'Header',
    data() {
        return {
            // Define your local data properties here
        }
    },
    computed: {
        ...mapState(['user', 'credits_balance', 'notificationsCount']),
        // Define your computed properties here
    },
    methods: {
        // Define your methods here
        logout() {
            this.$store.commit('setUser', null)
            localStorage.removeItem('token');
            window.location.href = window.location.href;
        },
        downloadSample() {
            API.downloadFile('sample', {name: 'sample.xlsx'})
        }
    },
    mounted() {
        // Load the data when the component is mounted
    }
}



</script>

<style lang="scss" scoped>
.header-nav {
    padding: 0;
    margin-left: 140px;
    width: 100%;
    display: flex;
}


.header {
    // position: fixed;
    // z-index: 1000;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E6EAED;
}
.header-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    &.special {
        width: fit-content;
    }
}

.header-nav li {
    display: inline-block;
    margin: 0 15px;
    font-size: 12px;
    a {
        padding: 15px 0;
    }
}
 
.header-nav a, .header-nav span{
    text-decoration: none;
    color: #091440;
    cursor: pointer;
    font-weight: bolder;
}

.header-nav a:hover, .header-nav span:hover{
    color: #10193e;
}

.section-white {
    text-decoration: none;
    color: #091440; 
    display: inline-block;
    margin: 0 15px;

}
.greeting {
    margin-left: auto;
    white-space: nowrap;
    font-size: 12px;
}
.logout {
    font-size: 12px;
    font-weight: bolder;
    cursor: pointer;
    &:hover {
        color: #10193e;
    }
}
.register {
    margin-left: auto;
}
.notification-parent[class] {
    position: relative;
    margin-right: 30px;
}
.notification-counter {
    background: #f59f9f;
    border-radius: 50%;
    padding: 5px;
    top: -2px;
    right: -20px;
    position: absolute;
}
@media screen and (max-width: 1250px){
    .header-nav {
        padding: 0;
        margin-left: 0px;
        width: 100%;
        display: flex;
    }
    .header-nav li {
        font-size: 1vw;
    }
    .greeting {
        font-size: 1vw;
    }
    .logout {
        font-size: 1vw;
    }
}
</style>
