<template>
    <div class='control'>
        <div v-if="platform_control">
            <div class="input-wrapper">
                <InpComponent
                    label="Monthly Subscription Cost (EUR/USD)"
                    type="number"
                    theme="light"
                    :key_ref="'subscription_cost'"
                    :value="platform_control?.subscription_cost"
                    :error="subscription_cost.error"
                  @inputChange="inputChangeFunc"
                ></InpComponent>
              </div>
              <div class="input-wrapper">
                <InpComponent
                    label="Number of Rows covered by subscription"
                    type="number"
                    theme="light"
                    :key_ref="'subscription_amount_rows'"
                    :value="platform_control?.subscription_amount_rows"
                    :error="subscription_amount_rows.error"
                  @inputChange="inputChangeFunc"
                ></InpComponent>
              </div>
              <div class="input-wrapper">
                <InpComponent
                    label="Cost(EUR/USD) per row if conversion is more than subscription amount"
                    type="number"
                    theme="light"
                    :key_ref="'subscription_cost_per_row'"
                    :value="platform_control?.subscription_cost_per_row"
                    :error="subscription_cost_per_row.error"
                  @inputChange="inputChangeFunc"
                ></InpComponent>
              </div>
              <button @click="saveChanges()">
                Save Changes
              </button>
        </div>

        <div v-else>
            <h1>Loading...</h1>
        </div>
    </div>
</template>


<script>


import AdminTable from '@/components/AdminTable.vue'
import InpComponent from '@/components/InpComponent.vue'

import { mapState } from 'vuex';
import BO_API from '../api/bo_api.js'
import store from '../store';



export default {
    name: 'PlatformControlView',
    components: {
        AdminTable,
        InpComponent,
    },
    data() {
        return {
            platform_control: null,
            subscription_cost: {value: '', error: '', checkActive: false },
            subscription_amount_rows: {value: '', error: '', checkActive: false },
            subscription_cost_per_row: {value: '', error: '', checkActive: false },
            last_updated_at: null,
        };
    },
    async mounted(){
        await this.loadPlatformControl()
    },
    methods: {
        async loadPlatformControl(){
            const resp = await BO_API.getPlatformControl()
            if (resp.platformControl) {
                this.platform_control = resp.platformControl
                this.subscription_cost.value = resp.platformControl.subscription_cost
                this.subscription_amount_rows.value = resp.platformControl.subscription_amount_rows
                this.subscription_cost_per_row.value = resp.platformControl.subscription_cost_per_row
                this.last_updated_at = resp.platformControl.last_updated_at
            }
        },
        inputChangeFunc(value) {
            this[value[0]].value = value[1]
            if (this[value[0]].checkActive) {
                this.validateData(value[0])
            }
        },
        validateData(propName) {
            let propertyName = propName;
            const validator = {
                subscription_cost: function(){
                    if (!this.subscription_cost.value < 0 || !this.subscription_cost.value > 1000) { 
                        this.subscription_cost.error = 'Please check specified value';
                    }
                },
                subscription_amount_rows: function(){
                    if (!this.subscription_amount_rows.value < 0 || !this.subscription_amount_rows.value > 1000) { 
                        this.subscription_amount_rows.error = 'Please check specified value';
                    }
                },
                subscription_cost_per_row: function(){
                    if (!this.subscription_cost_per_row.value < 0 || !this.subscription_cost_per_row.value > 1000) { 
                        this.subscription_cost_per_row.error = 'Please check specified value';
                    }
                }
            };
            let hasError = '';

            if (propertyName) {
                validator[propertyName].bind(this)()
                if (this[propertyName].error) {
                    this[propertyName].checkActive = true;
                    hasError = this[propertyName].error;
                }
            } else {
                for (let key in validator) {
                    validator[key].bind(this)()
                    if (this[key].error) {
                        this[key].checkActive = true;
                        hasError = this[key].error;
                    }
                }
            }
            return hasError;
        },
        async saveChanges() {
            for (let key in this){
                if (this[key].checkActive && [
                    'subscription_cost_per_row',
                    'subscription_amount_rows',
                    'subscription_cost',
                ].includes(key)) {
                    this[key].checkActive = false;
                }
            }
            const validationResultsErrors = this.validateData(null);
            if (validationResultsErrors) {
                store.dispatch('addNotification', {text: 'Error loading user data', type: 'error'})
                return
            }
            const resp = await BO_API.updatePlatformControl({
                subscription_cost_per_row: this.subscription_cost_per_row.value,
                subscription_amount_rows: this.subscription_amount_rows.value,
                subscription_cost: this.subscription_cost.value
            })
            if (resp.status == 200) {
                store.dispatch('addNotification', {text: 'Changes saved', type: 'success'})
            }   
        }
    },
    computed: {
        ...mapState(['user']),
    },
    watch: {
        user: function (val) {
        if (!val) {
            this.$router.push('/login')
        }
        },
    }
};
</script>

<style scoped lang="scss">
    .control {
        width: 250px;
        height: calc(100vh - 61px);
        display: flex;
        background: white;
    }
    .input-wrapper {
        :deep(div label) {
            color: black;
        }
    }
    button {
        padding: 10px;
        font-size: 15px;
    }
</style>
