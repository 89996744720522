<template>
    <div class='top-section'>
        <div class='user-info'>
            <span>Registration username: <b>{{user && user.user.username}}</b></span>
            <span>Registration email: <b>{{user && user.user.email}}</b></span>
            <span>Registration email verified ?: <b>{{user && user.user.email_verified}}</b></span>
            <span>Registration date: <b>{{user && user.user.created}}</b></span>
            <span>Current credits: <b>{{user && user.credits_balance.toFixed(2)}}</b></span>
            <span class="subscription-section">Current subscription:
                <div class='subscription action-block' v-if="user && user.subsctions">
                    <span>Status <b>{{user.subsctions.status}}</b></span>
                    <span>Subscription start: <b>{{user && user.subsctions.activated_at}}</b></span>
                    <span>Subscription end: <b>{{user && user.subsctions.expire_at}}</b></span>
                    <span>Remaining time: <b>{{subscriptionTime}}</b></span>
                </div>
                <div v-else>
                    No Subscriptions yet
                </div>
            </span>
        </div>
        <div class='actions' v-if="user">
            <div class='give-credit-block action-block'>
                <span>Give Credits</span>
                <input type="number" v-model="credits_to_give">
                <button class="button" @click="giveCredit()">Send</button>
            </div>
            <div class='give-credit-block action-block' style='justify-content: space-between;'>
                <span>Acitvate Subscription</span>
                <button class="button" @click="ActivateSubscription()">Acitvate</button>
            </div>
        </div>
        <div>
            <AdminTable v-if=" user && user.credits" 
                :data="user.credits" 
                header='Credits list' 
                :headers="credits_headers"
        ></AdminTable>
        </div>
    <div v-if='user'>
        <UserSection 
            :user="user">
        </UserSection>
        <div>
            <AdminTable v-if=" user && user.user_files" 
                :data="user.user_files" 
                header='Uploaded Files' 
                :headers="user_files_headers">
            </AdminTable>
            <AdminTable v-if=" user && user.loginHistory" 
                    :data="user.loginHistory" 
                    header='Login History' 
                    :headers="login_headers"
            ></AdminTable>
        </div>
    </div>  
   
   

    <div v-else>
        <div v-if="user == null">
            LOADING DATA
        </div>
        <div v-else>
            No info
        </div>
    </div>
    </div>
</template>

<script>

import InpComponent from '@/components/InpComponent.vue'
import UserSection from '@/components/UserSection.vue'
import AdminTable from '@/components/AdminTable.vue'
import Utils from '../utils/index.js'
import { mapState } from 'vuex';
import BO_API from '../api/bo_api.js'
import API from '../api/index.js'
import store from '@/store/index.js';
const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
export default {
    name: 'UserView',
    components: {
        InpComponent,
        UserSection,
        AdminTable,
    },
    props: {
        id: String,
    },
    data() {
        return {
            user: null,
            credits_to_give: 0,
            credits_headers: [
                {"name": 'Type', "key": 'type'}, 
                {"name": 'Amount', "key": 'amount', "format": (val) => val.toFixed(2)}, 
                {"name": 'Date', "key": 'datetime'},
                {"name": "Initiator", "key": 'initiator'},
                {"name": "Comment", "key": 'comment'},
            ],
            login_headers: [
                {"name": 'Date', "key": 'loginDate'},
                {"name": "IP", "key": 'ip'},
                {"name": "userAgent", "key": 'userAgent'},
            ],
            user_files_headers: [
                {"name": 'File Name', "key": 'filename'}, 
                {"name": 'Size', "key": 'filesize', "format": (val) => val ? Utils.calculateFileSize(val) : ''}, 
                {"name": 'Date', "key": 'uploadDate', "format": (val) => val ? new Date(val).toLocaleDateString('en-GB', options) : ''},
                {"name": "Rows", "key": 'rows'},
                {"name": "conversionFee", "key": 'conversionFee', "format": (val) => val ? val.toFixed(2) : ''},
                {"name": "conversionFeeComment", "key": 'conversionFeeComment'},
                // {"name": "Action", "key": 'action', },
            ],
        };
    },
    async mounted(){
        await this.loadUserData(this.id)
    },
    methods: {
        async loadUserData(id){
            const data_resp = await API.getUser(id);
            if (data_resp && data_resp.user){
                this.user = data_resp
            } else {
                this.user = {}
            }
        },
        async giveCredit(){
            const resp = await BO_API.giveCredit({
                user_id: this.id,
                amount: this.credits_to_give
            })
            console.log('resp', resp)
            if (resp.status == 200) {
                this.credits_to_give = 0;
            }
        },
        async ActivateSubscription(){
            const resp = await BO_API.ActivateSubscription({
                user_id: this.id,
            })
            if (resp.error) {
                store.dispatch('addNotification', {
                    text: resp.error,
                    type: 'error'
                })  
            }
            console.log('resp', resp)
        },
        async loadUsageData() {
            
        }
    },
    computed: {
        ...mapState(['']),
        subscriptionTime(){
            if (this.user && this.user.subsctions){
                const now = new Date();
                const end = new Date(this.user.subsctions.expire_at);
                const diff = end - now;
                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                const hours = Math.floor(diff / (1000 * 60 * 60) % 24);
                const minutes = Math.floor(diff / (1000 * 60) % 60);
                return `${days} days ${hours} hours ${minutes} minutes`
            }
            return ''
        }
    },
};
</script>

<style lang="scss" scoped>


.subscription[class] {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-top: 10px;
}

.input-wrapper {
    :deep(div label) {
        color: black;
    }
}
.actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;
}
.action-block {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 15px;
    box-sizing: border-box;
    gap: 20px;
    background: #d1d1d1;
    border-radius: 12px;
    button {
        padding: 5px 0;
        width: 100%;
    }
    input {
        background: white;
        color: black;
        border-radius: 3px;
        padding: 5px;
        caret-color: black;
    }
}
.top-section {
    color: black;
}
.home {
    width: 600px;
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  }
  
  .date {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &:deep(input){
      width: 70%;
      margin-right: 5px;
    }
  
  }
  .input-holder {
    display: flex;
    align-items: center;
  
  }
  .user-info {
    display: flex;
    text-align: left;
    flex-direction: column;
  }
  .button-holder {
    width: 150px;
    height: 40px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    button {
      height: 40px;
  
    }
  }
  
  .input-section {
    box-sizing: border-box;
  }
  .input-wrapper {
    flex: 1;  
  }
 
  .row {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    
  }
  .section {
    max-width: 650px;
  }
  
  .section-header {
    color: black;
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    margin: 20px 0;
  }
  .error {
    margin-top: -10px;
    margin-bottom: 10px;
    color: red;
  }
  .text {
    color: white;
    margin-right: 5px;
  }



</style>