<template>
<tr :id="'id' + records_id">
    <td class="kyc-item-section">
        <h3>
            {{ header }}
        </h3>
        <UploaderTable 
            @handleFileChange="handleFileChange" 
            :filePresent="filePresent"    
            :file="file"  
            :title="title"
            :fileType="fileType"
            @dateInputChangeFunc="dateInputChangeFunc"
            @changeInfo="changeInfo"
            :issueDatePresent="issueDatePresent"
            :index="index"  
            :fiscal_year_ending_on_present="fiscal_year_ending_on_present"
            :fiscal_year_ending_on="fiscal_year_ending_on"
            :year_title="year_title"
            :year="year"
            :yearPresent="yearPresent"
            :second_line_normal="second_line_normal"
            :issueDateValue="issueDateValue"  
            :label="label"
            :fullWidth="true"
        />
    </td>
    <td class="yes-no">
        <text v-if="textareaValue && filePresent">Y</text>
    </td>
    <td class="yes-no">
        <text v-if="!textareaValue || !filePresent">N</text>
    </td>

    <td class="document-comments">
        <textarea 
            @input="DataHandlerComment" 
            v-model="textareaValue" 
            placeholder="Write your comment here" 
            class='data-holder-comment'>
        </textarea>
    </td>

    <td class="document-comments">
        <textarea 
            :disabled="user.type !== 'COMPLIANCE_OFFICER'"
            @input="ComplianceOfficerComment" 
            v-model="textareaValueComplience" 
            placeholder="Write your comment here" 
            class='data-holder-comment'>
        </textarea>
    </td>

</tr>

</template>
<script setup>
import { ref, defineEmits, computed, withDefaults } from 'vue'




import InpComponent from '@/components/InpComponent.vue'
import UploaderTable from '@/components/UploaderTable.vue'

import API from '../api/index.js'
import store from '../store';

const emit = defineEmits(['handleFileChange', 'dateInputChangeFunc', 'commentChange', 'changeInfo' ],)
const user = computed(() => store.state.user);


const selectedFile = ref(null)


function dateInputChangeFunc(event) {
  emit('dateInputChangeFunc', event)
}
function handleFileChange(event) {
  emit('handleFileChange', event)
}
function changeInfo(event) {
    console.log('changeInfo', event)
    emit('changeInfo', event)
}
function ComplianceOfficerComment(event) {
    emit('commentChange', {
        comment: textareaValueComplience.value,
        fileType: props.fileType,
        comment_type: 'comment_compliance_officer'
    })
}

function DataHandlerComment(event) {
    emit('commentChange', {
        comment: textareaValue.value,
        fileType: props.fileType,
        comment_type: 'comment_data_handler'
    })
}

const props = defineProps([
  'header',
  'title', 
  'fileType', 
  'file',
  'filePresent', 
  'issueDatePresent', 
  'issueDateValue', 
  'error', 
  'file',
  'fiscal_year_ending_on_present',
  'fiscal_year_ending_on',
  'year_title',
  'id',
  'second_line_normal',
  'label', 
  'index',
  'year',
  'yearPresent',
  'comment_data_handler_value',
  'comment_compliance_officer_value'
  ])
const textareaValue = ref(props.comment_data_handler_value)
const textareaValueComplience = ref(props.comment_compliance_officer_value)
const records_id = computed(() => 
    props.file ? props.file.id : props.id
)
</script>
<style lang="scss" scoped>
    textarea {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        box-sizing: border-box;
    }
   
    .data-holder-comment-section {
        font-size: 12px;
        font-weight: 600;
        margin-top: 20px;
      
        text-align: left;
    }
    .no-margin {
        margin-top: 0px;
    }
    .data-holder-comment {
        height: 100%;
        min-height: 120px;
        width: 100%;
    }
    .document-comments {
        vertical-align: bottom;
        
    }
    .kyc-item-section {
        vertical-align: bottom;
    }
    td {
        padding: 10px;
        border: 1px solid black;
    }
    h3 {
        text-align: left;
        margin: 0px;
        margin-bottom: 4px;
        font-size: 16px;
    }
    td {
        width: 30%;
        height: 100%;
    }

    .yes-no {
        width: 20px;
    }
    
    tr > td:first-child {
        min-width: 33%;
        width: 33%;
    }

</style>