<template>

  <div class="home">
    
    <div class='section'>
      <div class="email-verification" 
        v-if="
          (userInformation 
          && userInformation.email 
          && !userInformation.email_verified)
          || (user && !user.email_verified)
        "
        >
        <h3>Attention!</h3>
        <p>In order for the platform to work, we kindly ask you to confirm the email</p>
        <div v-if="userInformation && !userInformation.email_verified">
          email {{ userInformation.email }} is not verified
        </div>
        <div v-if="user && !user.email_verified && userInformation.email != user.email">
          email {{ user.email }} is not verified
        </div>
        <button
          class="verify-button"
          @click="sendVerificationEmail()"
          v-if="(userInformation && !userInformation.email_verified) || (user && !user.email_verified)"
        >Verify 
        </button>
      </div>

      <div class="input-section">
      <div class='section-header'>User</div>
        <div class='row'>
          <div class="input-wrapper">
            <InpComponent
              label="Organization Id"
              type="text" 
              placeholder="123"
              theme="light"
              :key_ref="'rentity_id'"
              :value="userInformation?.rentity_id"
              :error="rentity_id.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper">
            <InpComponent
                label="User Name"
                type="text"
                placeholder="Name of the Entity"
                theme="light"
                :key_ref="'rentity_branch'"
                :value="userInformation?.rentity_branch"
                :error="rentity_branch.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>
        <div class='row'>
          <div class="input-wrapper">
              <InpComponent
                  label="Title"
                  type="text"
                  placeholder="Your Title"
                  theme="light"
                  :key_ref="'title_name'"
                  :value="userInformation?.title_name"
                  :error="title_name.error"
                @inputChange="inputChangeFunc"
              ></InpComponent>
            </div>
          <div class="input-wrapper">
            <InpComponent
                label="First Name"
                type="text"
                placeholder="Don"
                theme="light"
                :key_ref="'first_name'"
                :value="userInformation?.first_name"
                :error="first_name.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>
        <div class='row'>
          <div class="input-wrapper">
            <InpComponent
                label="Last Name"
                type="text"
                placeholder="Jon"
                theme="light"
                :key_ref="'last_name'"
                :value="userInformation?.last_name"
                :error="last_name.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper">
            <InpComponent
                label="Email"
                type="text"
                placeholder="Male"
                theme="light"
                :key_ref="'email'"
                :value="userInformation?.email"
                :error="email.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>
        <div class='row'> 
          <div class="input-wrapper">
            <InpComponent
                label="Gender"
                type="options"
                :options="[{text: 'Male', value: 'Male'}, {text: 'Female', value: 'Female'}]"
                placeholder="Male"
                theme="light"
                :key_ref="'gender'"
                :value="userInformation?.gender"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper date" >
            <span class='text'>Birth Date</span>
            <div class='input-holder'>
              <InpComponent
                  label=""
                  type="number"
                  placeholder="DD"
                  theme="light"
                  :key_ref="'birth_date_day'"
                  :value="userInformation?.birth_date ? userInformation?.birth_date.slice(0,10).split('-')[2] : ''"
                  :max="31"
                  :min="1"
                @inputChange="inputChangeFunc"
              ></InpComponent>
              /
              <InpComponent
                  label=""
                  type="number"
                  placeholder="MM"
                  theme="light"
                  :key_ref="'birth_date_month'"
                  :value="userInformation?.birth_date ? userInformation?.birth_date.slice(0,10).split('-')[1] : ''"
                  :max="12"
                  :min="1"
                @inputChange="inputChangeFunc"
              ></InpComponent>
              /
              <InpComponent
                  label=""
                  type="number"
                  placeholder="YYYY"
                  theme="light"
                  :key_ref="'birth_date_year'"
                  :max="2004"
                  :value="userInformation?.birth_date ? userInformation?.birth_date.slice(0,10).split('-')[0] : ''"
                @inputChange="inputChangeFunc"
              ></InpComponent>
            </div>
            <div class='error' v-if="birth_date_full.error">{{birth_date_full.error}}</div>

          </div>

        </div>
        <div class='row'> 
          <div class="input-wrapper">
            <InpComponent
                label="Id Number"
                type="number"
                placeholder="Identification number"
                theme="light"
                :key_ref="'id_number'"
                :value="userInformation?.id_number"
                :error="id_number.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper">
            <InpComponent
                label="Nationality"
                type="options"
                :options="Utils && Utils.getCountryList()"
                placeholder="Canada"
                theme="light"
                :key_ref="'nationality'"
                :value="userInformation?.nationality"
                :error="nationality.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>
        <div class='row'> 
          <div class="input-wrapper">
            <InpComponent
                label="Occupation"
                type="text"
                placeholder="Financial Analyst"
                theme="light"
                :key_ref="'occupation'"
                :value="userInformation?.occupation"
                :error="occupation.error"

              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper"></div>
        </div>
        <div class='row'> 
          <div class="input-wrapper">
            <InpComponent
                label="Pasport Number"
                type="text"
                placeholder="number"
                theme="light"
                :key_ref="'passport_number'"
                :value="userInformation?.passport_number"
                :error="passport_number.error"
                
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper">
            <InpComponent
              label="Pasport Country"
              type="options"
              :options="Utils && Utils.getCountryList()"
              placeholder="Canada"
              theme="light"
              :key_ref="'pasport_country'"
              :value="userInformation?.pasport_country"

              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>
      </div>
    </div>

    
    <div class='section'>
      <div class="input-section">
        <div class='section-header'>Address (addresses of the reporting entity)</div>
        <!-- {text: 'Private', value: 'Private'}, 
        {text: 'Business', value: 'Business'},
        {text: 'Operational', value: 'Operational'},
        {text: 'IP Address', value: 'IP Address'} -->
        <div class='row'>
          <div class="input-wrapper">
            <InpComponent
              label="Type"
              type="options"
              :options="[
                {text: 'Business', value: 'Business'},
              ]"
              placeholder="Private"
              theme="light"
              :key_ref="'address_type'"
              :value="userAddress?.address_type"
              :error="address_type.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper">
            <InpComponent
                label="Address"
                type="text"
                placeholder="Suite 1200, 407 2nd Street, SW Calgary"
                theme="light"
                :key_ref="'address'"
                :value="userAddress?.address"
                :error="address.error"

              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>
        <div class='row'>

          <div class="input-wrapper">
            <InpComponent
                label="Town"
                type="text"
                placeholder="Name of Town/district/.. as part of a City "
                theme="light"
                :key_ref="'town'"
                :value="userAddress?.town"
                :error="town.error"

              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>


          <div class="input-wrapper">
            <InpComponent
                label="City"
                type="text"
                placeholder="Edmonton"
                theme="light"
                :key_ref="'city'"
                :value="userAddress?.city"
                :error="city.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>
        <div class='row'>
          <div class="input-wrapper">
            <InpComponent
              label="Zip"
              type="number"
              placeholder="00000"
              theme="light"
              :key_ref="'zip'"
              :value="userAddress?.zip"
              :error="zip.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper">
            <InpComponent
              label="Country"
              type="options"
              :options="Utils && Utils.getCountryList()"
              placeholder="Canada"
              theme="light"
              :key_ref="'country'"
              :value="userAddress?.country"
              :error="country.error"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>
        <div class='row'>
          <div class="input-wrapper">
            <InpComponent
              label="State"
              type="text"
              placeholder="Alberta"
              theme="light"
              :key_ref="'state'"
              :value="userAddress?.state"
              :error="state.error"

              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper"></div>
        </div>
      </div>
    </div>

    <div class='section'>
      <div class="input-section">
        <div class='section-header'>Phone</div>

        <div class='row'>
          <div class="input-wrapper">
            <!-- {text: 'Operational', value: 'Operational'},
            {text: 'IP Address', value: 'IP Address'} -->
            <InpComponent
              label="Contact type"
              type="options"
              :options="[
                {text: 'Private', value: 'Private'}, 
                {text: 'Business', value: 'Business'},
                
              ]"
              placeholder="Private"
              theme="light"
              :key_ref="'phone_type'"
              :value="userPhone?.phone_type"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper">
            <InpComponent
                label="Communication type"
                type="options"
                :options="[
                  {text: 'Mobile Phone', value: 'Mobile Phone'},
                  {text: 'Landline Phone', value: 'Landline Phone'},
                  {text: 'Fax', value: 'Fax'}, 
                ]"
                placeholder="Mobile Phone"
                theme="light"
                :key_ref="'communication_type'"
                :value="userPhone?.communication_type"
              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>

        <div class='row'>
          <div class="input-wrapper">
            <InpComponent
              label="Country Prefix"
              type="options"
              :options="Utils.getCountryPrefixes()"
              placeholder="1"
              theme="light"
              :key_ref="'phone_prefix'"
              :value="userPhone?.phone_prefix"
              :error="phone_prefix.error"

              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
          <div class="input-wrapper">
            <InpComponent
                label="Number"
                type="number"
                placeholder="6134543232"
                theme="light"
                :key_ref="'phone_number'"
                :value="userPhone?.phone_number"
                :error="phone_number.error"

              @inputChange="inputChangeFunc"
            ></InpComponent>
          </div>
        </div>
      </div>
    </div>

    <div class="button-area">
      <div class="button-holder">
        <button class='button' type="submit" @click="saveChanges()">Save</button>
      </div>
    </div>
   
  </div>
</template>

<script>
// @ is an alias to /src
import API from '@/api/index.js'
import InpComponent from '@/components/InpComponent.vue'
import Utils from '@/utils/index.js'
import { mapState, mapActions } from 'vuex';
import store from '../store';

export default {
  data() {
    return {
      rentity_id: {value: '', error: '', checkActive: false },
      rentity_branch: {value: '', error: '', checkActive: false },
      title_name: {value: '', error: '', checkActive: false },
      first_name: {value: '', error: '', checkActive: false },
      last_name: {value: '', error: '', checkActive: false },
      email: {value: '', error: '', checkActive: false },
      gender: {value: '', error: '', checkActive: false },
      birth_date_day: {value: '', error: '', checkActive: false },
      birth_date_month: {value: '', error: '', checkActive: false },
      birth_date_year: {value: '', error: '', checkActive: false },
      birth_date_full: {value: '', error: '', checkActive: false },
      id_number: {value: '', error: '', checkActive: false },
      nationality: {value: '', error: '', checkActive: false },
      occupation: {value: '', error: '', checkActive: false },
      passport_number: {value: '', error: '', checkActive: false },
      pasport_country: {value: '', error: '', checkActive: false },
    
      address_type: {value: '', error: '', checkActive: false },
      address: {value: '', error: '', checkActive: false },
      town: {value: '', error: '', checkActive: false },
      city: {value: '', error: '', checkActive: false },
      zip: {value: '', error: '', checkActive: false },
      country: {value: '', error: '', checkActive: false },
      state: {value: '', error: '', checkActive: false },

      phone_type: {value: '', error: '', checkActive: false },
      communication_type: {value: '', error: '', checkActive: false },
      phone_prefix: {value: '', error: '', checkActive: false },
      phone_number: {value: '', error: '', checkActive: false },
      // Define your local data properties here
    }
  },
  methods: {
    ...mapActions(['addNotification']),
    async sendVerificationEmail() {
      const resp = await API.sendVerificationEmail()
      console.log('sendVerificationEmail', resp)
    },
    inputChangeFunc(value) {
      let updated = false;
      if (value[0] == 'birth_date_day' && this.birth_date_day.value && value[1] > 31 ) {
        this.birth_date_day.value = 31;
        updated = true;
      }
      if (value[0] == 'birth_date_month' && this.birth_date_month.value && value[1] > 12) {
        this.birth_date_month.value = 12;
        updated = true;
      }
      if (value[0] == 'birth_date_day' && this.birth_date_day.value && value[1] < 1) {
        this.birth_date_day.value = 1;
        updated = true;
      }
      if (value[0] == 'birth_date_month' && this.birth_date_month.value && value[1] < 1) {
        this.birth_date_month.value = 1;
        updated = true;
      }
      if (!updated) {
        this[value[0]].value = value[1]
      }
      if (['birth_date_day', 'birth_date_month', 'birth_date_year'].includes(value[0])) {
        console.log('date updated')
        console.log({
          birth_date_day: this.birth_date_day.value,
          birth_date_month: this.birth_date_month.value,
          birth_date_year: this.birth_date_year.value,
          str_len: this.birth_date_year.value ? this.birth_date_year.value.toString().length : 'none'
        })
        if (this.birth_date_day.value && this.birth_date_month.value && this.birth_date_year.value && this.birth_date_year.value.toString().length > 3) {
          let date;
          console.log('date updated and fields are set')

          try {

            const dateErrors = Utils.isValidDate(this.birth_date_year.value, this.birth_date_month.value, this.birth_date_day.value);
            console.log('dateErrors', dateErrors)
            if (dateErrors) {
              this.addNotification({type: 'error', text: dateErrors})
              return;
            }

            console.log('dateErrors',dateErrors) 
            let month = this.birth_date_month.value < 10 ? '0' + this.birth_date_month.value.toString() : this.birth_date_month.value;
            let day = this.birth_date_day.value < 10 ? '0' + this.birth_date_day.value.toString() : this.birth_date_day.value;
            let full = this.birth_date_year.value + '-' + month + '-' + day;
            let date = new Date(full);
            console.log('date', date)
            this.birth_date_full.value = date.toISOString();
            console.log('this.birth_date_full.value', this.birth_date_full.value)
          } catch (e) {
            console.log('error', 'incorrect date format', e)
          }
        }
      }
      if (this[value[0]].checkActive) {
        console.log('value[0]', value[0])
        this.validateData(value[0])
      }
    },
    
    validateData(propName) {
      let propertyName = propName;
      console.log('propertyName', propertyName)
      const validator = {
        rentity_id: function(){
          console.log('this.rentity_id.value', this.rentity_id)
          this.rentity_id.error = Utils.validateEntityId(this.rentity_id.value)
        },
        rentity_branch: function(){
          this.rentity_branch.error = Utils.validateEntityName(this.rentity_branch.value)
        },
        title_name: function(){
          this.title_name.error = Utils.validateTitle(this.title_name.value)
        },
        first_name: function(){
          this.first_name.error = Utils.validateInputName(this.first_name.value, 'first_name')
        },
        last_name: function(){
          this.last_name.error = Utils.validateInputName(this.last_name.value, 'last_name')
        },
        email: function(){
          const email_validation_passed = Utils.validateEmail(this.email.value);
          if (email_validation_passed) {
            this.email.error = '';
          } else {
            this.email.error = 'Please provide a valid email';
          }
        },
        gender: function() {
          if (!this.gender.value) {
            this.gender.error = 'Please select a gender';
          }
        },
        birth_date_full: function(){
          this.birth_date_full.error = Utils.isValidDate(this.birth_date_year.value, this.birth_date_month.value, this.birth_date_day.value)
        },
        id_number: function(){
          this.id_number.error = Utils.validateIdNumber(this.id_number.value)
        },
        nationality: function(){
          if (!this.nationality.value) {
            this.nationality.error = 'Please select a nationality';
          }
        },
        occupation: function(){
          this.occupation.error = Utils.validateOccupation(this.occupation.value)
        },
        passport_number: function(){
          this.passport_number.error = Utils.validatePassportNumber(this.passport_number.value)
        },
        pasport_country: function(){
          if (this.passport_number.value && !this.pasport_country.value) {
            this.pasport_country.error = 'Please select a country';
          }
        },
      
        address_type: function(){
          if (!this.address_type.value) {
            this.address_type.error = 'Please select an address type';
          }
        },
        address: function(){
          this.address.error = Utils.validateAddress(this.address.value)
        },
        town: function(){
          this.town.error = Utils.validateTown(this.town.value)
        },
        city: function(){
          this.city.error = Utils.validateCity(this.city.value)
        },
        zip: function(){
          this.zip.error = Utils.validateZip(this.zip.value)
        },
        state: function(){
          this.state.error = Utils.validateState(this.state.value)
        },

        country: function(){
          if (!this.country.value) {
            this.country.error = 'Please select a country';
          }
        },
        phone_type: function(){
          if (!this.phone_type.value) {
            this.phone_type.error = 'Please select a phone type';
          }
        },
        communication_type: function(){
          if (!this.communication_type.value) {
            this.communication_type.error = 'Please select a communication type';
          }
        },
        phone_prefix: function(){
          if (!this.phone_prefix.value) {
            this.phone_prefix.error = 'Please select a country prefix';
          }
        },
        phone_number: function(){
          this.phone_number.error = Utils.validatePhoneNumber(this.phone_number.value)
        },
      };
      let hasError = '';

      if (propertyName) {
        validator[propertyName].bind(this)()
        if (this[propertyName].error) {
          this[propertyName].checkActive = true;
          hasError = this[propertyName].error;
          console.log(propertyName, 'hasError', this[propertyName].error)
        }
      } else {
        for (let key in validator) {
          validator[key].bind(this)()
          if (this[key].error) {
            this[key].checkActive = true;
            hasError = this[key].error;
            console.log(key, 'hasError', this[key].error)
          }
        }
      }
      console.log('hasError', hasError)
      return hasError;
    },
    async saveChanges() {
      for (let key in this){
        if (this[key].checkActive) {
          this[key].checkActive = false;
        }
      }
      const validationResultsErrors = this.validateData(null);
      if (validationResultsErrors) {
        this.addNotification({type: 'error', text: "Check provided data"})
        return
      }
      const resp = await API.saveUserChanges({
        rentity_id: this.rentity_id.value,
        rentity_branch: this.rentity_branch.value,
        title_name: this.title_name.value,
        first_name: this.first_name.value,
        last_name: this.last_name.value,
        email: this.email.value,
        gender: this.gender.value,
        birth_date: this.birth_date_full.value ? this.birth_date_full.value : '',
        id_number: this.id_number.value,
        nationality: this.nationality.value,
        occupation: this.occupation.value,
        passport_number: this.passport_number.value,
        pasport_country: this.pasport_country.value,

        address_type: this.address_type.value,
        address: this.address.value,
        town: this.town.value,
        city: this.city.value,
        zip: this.zip.value,
        country: this.country.value,
        state: this.state.value,

        phone_type: this.phone_type.value,
        communication_type: this.communication_type.value,
        phone_prefix: this.phone_prefix.value,
        phone_number: this.phone_number.value,
      })
      console.log('saveChanges')
    }
  },
  watch: {
    user: function (val) {
      if (!val) {
        this.$router.push('/login')
      }
    },
    userInformation: function (val) {
      if (this.userInformation && this.userInformation.birth_date) {
        this.birth_date_day.value = this.userInformation?.birth_date.slice(0,10).split('-')[2];
        this.birth_date_month.value = this.userInformation?.birth_date.slice(0,10).split('-')[1];
        this.birth_date_year.value = this.userInformation?.birth_date.slice(0,10).split('-')[0];
        this.birth_date_full.value = this.userInformation?.birth_date;
      }
    }
  },
  mounted(){
    if (!this.user) {
      this.$router.push('/login')
    }
    console.log(this.userInformation)
    this.rentity_id.value = this.userInformation?.rentity_id;
    this.rentity_branch.value = this.userInformation?.rentity_branch;
    this.title_name.value = this.userInformation?.title_name;
    this.first_name.value = this.userInformation?.first_name;
    this.last_name.value = this.userInformation?.last_name;
    this.email.value = this.userInformation?.email;
    this.gender.value = this.userInformation?.gender;
    this.birth_date_full.value = this.userInformation?.birth_date;
    this.birth_date_day.value = this.userInformation?.birth_date ? this.userInformation?.birth_date.slice(0,10).split('-')[2] : '';
    this.birth_date_month.value = this.userInformation?.birth_date ?this.userInformation?.birth_date.slice(0,10).split('-')[1] : '';
    this.birth_date_year.value = this.userInformation?.birth_date ?this.userInformation?.birth_date.slice(0,10).split('-')[0] : '';

    this.id_number.value = this.userInformation?.id_number;
    this.nationality.value = this.userInformation?.nationality;
    this.occupation.value = this.userInformation?.occupation;
    this.passport_number.value = this.userInformation?.passport_number;
    this.pasport_country.value = this.userInformation?.pasport_country;

    this.address_type.value = this.userAddress?.address_type;
    this.address.value = this.userAddress?.address;
    this.town.value = this.userAddress?.town;
    this.city.value = this.userAddress?.city;
    this.zip.value = this.userAddress?.zip;
    this.country.value = this.userAddress?.country;
    this.state.value = this.userAddress?.state;

    this.phone_type.value = this.userPhone?.phone_type;
    this.communication_type.value = this.userPhone?.communication_type;
    this.phone_prefix.value = this.userPhone?.phone_prefix;
    this.phone_number.value = this.userPhone?.phone_number;

  },
  computed: {
    ...mapState([
      'user', 
      'userInformation', 
      'userAddress', 
      'userPhone'
    ]),
    Utils() {
      return Utils
    }
    // Define your computed properties here
  },
  name: 'UserInfo',
  components: {
    InpComponent
  }
}
</script>

<style scoped lang="scss">

.email-verification {
  // color: white;
  text-align: left;
}

.verify-button {
  width: 100px;
  height: 35px;
  margin-top: 10px;
}
.home {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  overflow-y: auto;
  padding: 20px 0;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.date {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  &:deep(input){
    width: 70%;
    margin-right: 5px;
  }

}
.input-holder {
  display: flex;
  align-items: center;

}
.button-holder {
  width: 150px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  button {
    height: 40px;

  }
}

.input-section {
  box-sizing: border-box;
}
.input-wrapper {
  flex: 1;  
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  
}
.section {
  width: 55%;
  max-width: 650px;
}

.section-header {
  color: #3D5873;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  margin: 20px 0;
}
.error {
  margin-top: -10px;
  margin-bottom: 10px;
  color: red;
}
.text {
  color: #3D5873;
  margin-right: 5px;
}
</style>