const BO_URL = process.env.VUE_APP_API_BASE + '/bo/v1/';


// import store from '../store';
import { instance } from './index.js'
import API from './index.js'
import store from '../store';

const getUsageData = async (startDate,endDate) => {
    try {
        const response = await API.instance.get(`${BO_URL}get-usage-info?startDate=${startDate}&endDate=${endDate}`);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading usage data', type: 'error'})
        throw new Error(error);

    }
}
const getUsers = async (startDate,endDate) => {
    try {
        const response = await API.instance.get(`${BO_URL}get-users`);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading user data', type: 'error'})
        throw new Error(error);
    }
}

const giveCredit = async ({user_id, amount}) => {
    try {
        let error_ = null;
        const response = await API.instance.post(`${BO_URL}give-credit`, {
            user_id,
            amount
        }).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
        });
        if (error_) {
            return error_;
        }
        store.dispatch('addNotification', {text: 'Credit added', type: 'success'})

        return response;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error during Credit addition', type: 'error'})
        throw new Error(error);
    }
}
const ActivateSubscription = async ({user_id}) => {
    try {
        let error_ = null;
        const response = await API.instance.post(`${BO_URL}activate-subscription`, {
            user_id,
        }).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
        });
        if (error_) {
            return error_;
        }
        store.dispatch('addNotification', {text: 'Subsription activated', type: 'success'})

        return response;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error during Subsription activation ', type: 'error'})
        throw new Error(error);
    }
}
const getPlatformControl = async () => {
    try {
        let error_ = null;
        const response = await API.instance.get(`${BO_URL}get-platform-control`).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
        });
        if (error_) {
            return error_;
        }
        
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading platrofm control ', type: 'error'})
        throw new Error(error);
    }
}

const updatePlatformControl = async (object) => {
    try {
        let error_ = null;
        const response = await API.instance.post(`${BO_URL}set-platform-control`, {
            ...object
        }).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
                store.dispatch('addNotification', {text: 'Error updating platrofm control ', type: 'error'})
            }
        });
        if (error_) {
            return error_;
        }
        return response;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error updating platrofm control ', type: 'error'})
        throw new Error(error);
    }
}

export default {
    getUsageData,
    getUsers,
    giveCredit,
    ActivateSubscription,
    getPlatformControl,
    updatePlatformControl,
}

