<template>
    <div class="register">
        <div class="register-wrap">
            <div class="title">
                
                Reset Password
            </div>
            <form @submit.prevent="saveData">
                
                <input type="password" :class="{error: passwordOne}" v-model="passwordOneInput" placeholder="New Password" @input="passwordEntered">
                <input type="password" :class="{error: passwordError}" v-model="passwordInput" placeholder="Confirm Password" @input="passwordEntered">
                <span class='error-item' v-if="passwordError">{{passwordError}}</span>
                <button class='button' type="submit" @click="send()">Reset</button>

            </form>
        </div>
    </div>
</template>

<script setup>

import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import API from '../api/index.js'
const route = useRoute()
const router = useRouter()

const passwordError = ref(null)
const passwordOne = ref(null)

const passwordOneInput = ref(null)
const passwordInput = ref(null)
const token = ref(null)

import store from '../store/index.js'
const user = computed(() =>store.state.user)

function passwordEntered(){
    passwordError.value = null
}

async function send(){
    if (passwordOneInput.value !== passwordInput.value) {
        passwordError.value = 'Passwords do not match'
    } else {
        passwordError.value = null
    }
    console.log('token', token.value)

    const response = await API.resetPassword({
        password: passwordInput.value,
        token: token.value
    })
    console.log('response', response)
    if (response.status === 200) {
        store.dispatch('addNotification', {
            text: 'Password reset successfully',
            type: 'success'
        })
        router.push('/login')
    } else {
        store.dispatch('addNotification', {
            text: response.data.message,
            type: 'error'
        })
    }
}

onMounted(()=>{
    if (user.value) {
        router.push('/')
    }
    const query = route.query
    token.value = query.token
})
function save(  ) {
    console.log('save')
}

</script>


<style scoped lang="scss">

  .title {
    margin-bottom: 20px;
  }
  .error-item {
    color: red;
    margin: 0;
    height: fit-content;
    text-align: left;
    font-size: 9px;
    margin: 0;
    margin-left: -15px;
    margin-top: -5px;
  }
  .register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: #38314e;
    font-size: 23px;
    font-weight: bold;
    text-align: left;

  }
  .register-wrap {
    background-color: white;
    margin-top: -60px;
    border-radius: 5px;

    padding: 30px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 265px;
    > * {
      margin: 10px;
      width: 100%;
      height: 35px;
      font-size: 15px;
    }
    input {
      border: 1px solid #2b263a;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-radius: 0;
      outline: none;
      caret-color: #2b263a;
      background-color: white;
      color: #38314e;
      padding: 3px 10px;
      box-sizing: border-box;

      &.error {
        border: 1px solid red;
      }
    }
    button {
      height: fit-content;
    }
   
  }
</style>