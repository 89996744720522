<template>
  <div class="register">
    <div class="register-wrap">
      <div class="title">AMLLINK tool</div>
      <form @submit.prevent="saveData">
        <input type="text" :class="{error: usernameError}" v-model="username" placeholder="Username" @input="validateUsernameField">
        <span class='error-item' v-if="usernameError">{{usernameError}}</span>
        <input type="email" :class="{error: emailError}" v-model="email" placeholder="Email" @input="validateEmailField">
        <span class='error-item' v-if="emailError">{{emailError}}</span>
        <input type="password" :class="{error: passwordError}" v-model="password" placeholder="Password" @input="validatePasswordField">
        <span class='error-item' v-if="passwordError">{{passwordError}}</span>
        <button class='button' type="submit" @click="register()">Register</button>

      </form>
    </div>
  </div>
</template>

<script>

import Utils from '../utils/index.js'
import API from '../api/index.js'
import { mapState } from 'vuex';

export default {
  name: 'RegisterVue',
  components: {

  },
  data() {
    return {
      // Define your local data properties here
      username: '',
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      usernameError: false,

    }
  },
  methods: {
    validateEmailField() {
      if (this.emailError) {
        if (Utils.validateEmail(this.email)) {
          this.emailError = false;
        }
      }
    },
    validatePasswordField() {
      if (this.passwordError) {
        if (Utils.validatePassword(this.password)) {
          this.passwordError = false;
        }
      }
    },
    validateUsernameField() {
      if (this.usernameError) {
        if (Utils.validateName(this.username)) {
          this.usernameError = false;
        }
      }
    },
    async register() {
      let error = false
      if (!Utils.validateEmail(this.email)) {
        this.emailError = 'Invalid email';
        error = true;
      }
      if (!Utils.validatePassword(this.password)) {
        this.passwordError = 'Password must be from 6 to 30 characters long';
        error = true;
      }
      if (!Utils.validateName(this.username)) {
        this.usernameError = 'Username must be from 3 to 20 characters long';
        error = true;
      }
      if (error) {
        return;
      }
      // Send the data to the server
      const resp = await API.register({
        username: this.username,
        email: this.email,
        password: this.password
      })
      if (resp.error) {
        this.emailError = resp.error;
      }
      console.log('Registering user:', this.username, this.email, this.password);
    },
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.$router.push('/user-info')
    }
  },
  computed: {
    ...mapState(['user']),
  },
}
</script>

<style scoped lang="scss">

  .title {
    margin-bottom: 20px;
  }
  .error-item {
    color: red;
    margin: 0;
    height: fit-content;
    text-align: left;
    font-size: 9px;
    margin: 0;
    margin-left: -15px;
    margin-top: -5px;
  }
  .register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: #38314e;
    font-size: 23px;
    font-weight: bold;
    text-align: left;

  }
  .register-wrap {
    background-color: white;
    margin-top: -60px;
    border-radius: 5px;

    padding: 30px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 265px;
    > * {
      margin: 10px;
      width: 100%;
      height: 35px;
      font-size: 15px;
    }
    input {
      border: 1px solid #2b263a;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-radius: 0;
      outline: none;
      caret-color: #2b263a;
      background-color: white;
      color: #38314e;
      padding: 3px 10px;
      box-sizing: border-box;

      &.error {
        border: 1px solid red;
      }
    }
    button {
      height: fit-content;
    }
   
  }
</style>