<template>
    <div class="popup" v-if="showPopup">
        <div :class="['popup-message', response_text.type == 'error' ? 'success' : 'failure']">
            {{ response_text.text }}
        </div>
    </div>
</template>


<!-- { -->
<!-- text: 'Error loading user data',
type: 'error'} -->

<script>

import { mapState } from 'vuex';

export default {
    name: 'ResponseInfo',

    data() {
        return {
            showPopup: false,
            isSuccess: false,
            message: ''
        };
    },
    methods: {
        showSuccess(message) {
            this.showPopup = true;
            this.isSuccess = true;
            this.message = message;

            setTimeout(() => {
                this.hidePopup();
            }, 3000);
        },
        showFailure(message) {
            this.showPopup = true;
            this.isSuccess = false;
            this.message = message;

            setTimeout(() => {
                this.hidePopup();
            }, 3000);
        },
        hidePopup() {
            this.showPopup = false;
            this.isSuccess = false;
            this.message = '';
        }
    },
    computed: {
        ...mapState(['response_text']),
    },
};
</script>

<style scoped>
.popup {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
}

.popup-message {
    padding: 10px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
}

.success {
    background-color: #4caf50;
}

.failure {
    background-color: #f44336;
}
</style>
