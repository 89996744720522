<template>
    <div class="main-wrapper flex column gap-40">
        <div class="flex column gap30">
            <div class="flex row ">
                <div class="type-selector">
                    <InpComponent v-if="!props.frozen_location"
                        class="country-select"
                        label="Origin"
                        type="options"
                        :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                        placeholder="Local"
                        theme="light"
                        :disabled="ubo.api"
                        light_label="true"
                        :kyc_validate="true"
                        :key_ref="'ubo | location_type'"
                        :value="ubo['location_type'].value"
                        @inputChange="inputChangeFunc"

                    ></InpComponent>
                </div>
                <div style="margin-left: auto">
                    <button @click="DeleteUbo()">Delete</button>
                </div>
            </div>
            <PersonComp
                
                :key="`${ubo.id}_person_view_${ubo?.struct?.person?.id}`"
                :person="ubo.person"
                ubo_person="true"
                :source_of_funds="true"
                :source_of_wealth="true"
                :bank_reference_letter="true"
                :google_search="true"
                :world_check="true"
                @personChange="personChange"
            >
            </PersonComp>
        </div>
    </div>
</template>


<script setup>
import { ref, defineEmits, computed, withDefaults, defineProps } from 'vue'
import UploaderTable from '@/components/UploaderTable.vue'
import PersonComp from '@/components/PersonComp.vue'
import InpComponent from '@/components/InpComponent.vue'
import API from '../api/index'

const emit = defineEmits(['handleStructureUpdates', 'update_ubo', 'deleteUbo'],)

const props = defineProps(['ubo', 'id', 'location_type', 'frozen_location'])

const localUBO = ref(props.ubo)

function handleFileChange(event) {
    localUBO.value[event.fileType].file = event.file;
    localUBO.value[event.fileType].new_file = true
    emit('handleStructureUpdates', {
        ubo: localUBO.value 
    })
}

async function DeleteUbo(){
    if (window.confirm('Are you sure you want to delete this UBO?')) {
        const result = await API.deleteObject(props.id, 'ubo', 'UBO');
        if (result) {
            emit('deleteUbo', props.id);
        }
    }
}

function personChange(event) {
    localUBO.value.person = event.person;
    emit('handleStructureUpdates', { ubo: localUBO.value })
}

function inputChangeFunc(event) {

        // if (localUBO.value.api) {
        //     return
        // }

        const event_type = event[0];
        const event_value = event[1];
        const [type, field] = event_type.split(' | ');

        if (type == 'ubo') {
            localUBO.value[field].new_value = event_value;
         
            emit('update_ubo', {
                ubo: props.id,
                field,
                value: event_value
            })
        }
    }
</script>


<style scoped lang="scss">
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .main-wrapper {
        border-radius: 5px;
        margin-bottom: 40px;
        background-color: white;
        padding: 20px;
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .margin-top-40 {
        margin-top: 40px;
    }
    .country-select {
        max-width: 340px;
    }
    .type-selector {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
</style>