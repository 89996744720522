<template>
    <div class='date-selectors'>
        start
        <input type='date' id='start-date'>
        end
        <input type='date' id='end-date'>
        <button style='width: 200px; height: 35px;' @click="loadAllData()">Load</button>

    </div>
    <AdminTable v-if="usage_ranged" 
        :data="usage_ranged" 
        :header="header" 
        :headers="headers" 
    ></AdminTable>
</template>


<script>


import AdminTable from '@/components/AdminTable.vue'

import { mapState } from 'vuex';
import BO_API from '../api/bo_api.js'
export default {
    name: 'AdminView',
    components: {
        AdminTable,
    },
    data() {
        return {
            start: null,
            end: null,
            usage_ranged: null,
            header: 'Usage Data',
            headers: [
                {"name": 'email', "key": '_id'}, 
                {"name": 'count', "key": 'totalRows'}
            ]
        };
    },
    mounted(){
        this.start = document.getElementById('start-date');
        this.end = document.getElementById('end-date');

        this.start.value = new Date().toISOString().slice(0,10)
        this.end.value = new Date().toISOString().slice(0,10)
    },
    methods: {
        async loadAllData(){
            await this.loadUsageData()
        },
        async loadUsageData() {
            const data_resp = await BO_API.getUsageData(this.start.value, this.end.value);
            if (data_resp && data_resp.data && data_resp.data.result){
                this.usage_ranged = data_resp.data.result
            }
        }
    },
    computed: {
        ...mapState(['']),
    },
};
</script>

<style scoped lang="scss">
    .main {
        width: 100vw;
        height: calc(100vh - 61px);
        display: flex;
        background: white;
    }
    .left-nav {
        padding: 20px;
        box-sizing: border-box;
        flex: 1;
        border-right: 1px solid gray;
    }
    .main-area {
        padding: 20px;
        box-sizing: border-box;
        flex: 5;
    }
    .date-selectors {
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 10px;
    }
</style>
