import { createRouter, createWebHistory } from 'vue-router'
import UserInfo from '../views/UserInfo.vue'
import ContactView from '../views/ContactView.vue'

import RegisterVue from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import AdminView from '../admin_views/AdminView.vue'
import UsersView from '../admin_views/UsersView.vue'
import DashboardView from '../admin_views/DashboardView.vue'
import UserView from '../admin_views/UserView.vue'
import PlatofrmControlView from '../admin_views/PlatformControlView.vue'
import FileListView from '../views/FileListView.vue'
import WalletView from '../views/WalletView.vue'
import SavedView from '../views/SavedView.vue'
import VerifyView from '../views/VerifyView.vue'

import KYCView from '../views/KycView.vue'
import NotificationsView from '../views/NotificationsView.vue'
import ResetPasswordView from '../views/ResetPassword.vue'

const routes = [
  {
    path: '/user-info',
    name: 'userInfo',
    component: UserInfo
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterVue
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ResetPasswordView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: WalletView
  },
  {
    path: '/saved-info',
    name: 'saved-info',
    component: SavedView
  },
  {
    path: '/file-list',
    name: 'file-list',
    component: FileListView
  },
  {
    path: '/kyc',
    name: 'kyc',
    component: KYCView
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsView,
  },
  {
    path: '/verify',
    name: 'verify',
    component: VerifyView
  },
  {
    path: '/admin',
    name: 'admin',
    // props: true,
    component: AdminView,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'users/:id/',
        name: 'bo_user',
        props: true,
        component: UserView,
      },
      {
        path: 'users',
        name: 'bo_users',
        component: UsersView,
      },
      {
        path: 'platform_control',
        name: 'platform_control',
        component: PlatofrmControlView,
      }
      
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/convert',
    name: 'convert',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ConverFileView.vue')
  }
]

const router = createRouter({
  mode: 'history',
  base: '/',
  history: createWebHistory(),
  routes
})

export default router
