<template>
    <div class="home">
        <div class='section'>
            <div class="input-section">
            <div class='section-header'>User</div>
      
              <div class='row'>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                    label="Organization Id"
                    type="text"
                    theme="light"
                    :value="user.userInformation?.rentity_id"
                    
                  ></InpComponent>
                </div>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="User Name"
                      type="text"
                      theme="light"
                      :value="user.userInformation?.rentity_branch"
                      
                  ></InpComponent>
                </div>
              </div>
              <div class='row'>
                <div class="input-wrapper">
                    <InpComponent
                      disabled='true'
                        label="Title"
                        type="text"
                        theme="light"
                        :value="user.userInformation?.title_name"
                        
                    ></InpComponent>
                  </div>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="First Name"
                      type="text"
                      theme="light"
                      :value="user.userInformation?.first_name"
                      
                  ></InpComponent>
                </div>
              </div>
              <div class='row'>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Last Name"
                      type="text"
                      theme="light"
                      :value="user.userInformation?.last_name"
                      
                  ></InpComponent>
                </div>
                <div class="input-wrapper">
                  <span>UserInfo email verified? <b>{{user && user.userInformation?.email_verified}}</b></span>
                  <InpComponent
                    disabled='true'
                      label="Email"
                      type="text"
                      theme="light"
                      :value="user.userInformation?.email"
                      
                  >
                  
                  </InpComponent>

                </div>
              </div>
              <div class='row'> 
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Gender"
                      type="options"
                      :options="[{text: 'Male', value: 'Male'}, {text: 'Female', value: 'Female'}]"
                      theme="light"
                      :value="user.userInformation?.gender"
                  ></InpComponent>
                </div>
                <div class="input-wrapper date" >
                  <span class='text'>Birth Date</span>
                  <div class='input-holder'>
                    <InpComponent
                      disabled='true'
                        label=""
                        type="number"
                        theme="light"
                        :value="user.userInformation?.birth_date ? user.userInformation?.birth_date.slice(0,10).split('-')[2] : ''"
                        :max="31"
                        :min="1"
                    ></InpComponent>
                    /
                    <InpComponent
                      disabled='true'
                        label=""
                        type="number"
                        theme="light"
                        :value="user.userInformation?.birth_date ? user.userInformation?.birth_date.slice(0,10).split('-')[1] : ''"
                        :max="12"
                        :min="1"
                    ></InpComponent>
                    /
                    <InpComponent
                      disabled='true'
                        label=""
                        type="number"
                        theme="light"
                        :max="2004"
                        :value="user.userInformation?.birth_date ? user.userInformation?.birth_date.slice(0,10).split('-')[0] : ''"
                    ></InpComponent>
                  </div>
      
                </div>
      
              </div>
              <div class='row'> 
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Id Number"
                      type="number"
                      theme="light"
                      :value="user.userInformation?.id_number"
                      
                  ></InpComponent>
                </div>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Nationality"
                      type="options"
                      :options="[{text: user.userInformation?.nationality, value: user.userInformation?.nationality}]"
                      theme="light"
                      :value="user.userInformation?.nationality"
                      
                  ></InpComponent>
                </div>
              </div>
              <div class='row'> 
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Occupation"
                      type="text"
                      theme="light"
                      :value="user.userInformation?.occupation"
                      
      
                  ></InpComponent>
                </div>
                <div class="input-wrapper"></div>
              </div>
              <div class='row'> 
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Pasport Number"
                      type="text"
                      theme="light"
                      :value="user.userInformation?.passport_number"
                      
                      
                  ></InpComponent>
                </div>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                    label="Pasport Country"
                    type="options"
                    :options="[{text: user.userInformation?.pasport_country, value: user.userInformation?.pasport_country}]"
                    theme="light"
                    :value="user.userInformation?.pasport_country"
      
                  ></InpComponent>
                </div>
              </div>
            </div>
          </div>
      
          
          <div class='section'>
            <div class="input-section">
              <div class='section-header'>Address (addresses of the reporting entity)</div>
              <!-- {text: 'Private', value: 'Private'}, 
              {text: 'Business', value: 'Business'},
              {text: 'Operational', value: 'Operational'},
              {text: 'IP Address', value: 'IP Address'} -->
              <div class='row'>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                    label="Type"
                    type="options"
                    :options="[
                      {text: 'Business', value: 'Business'},
                    ]"
                    theme="light"
                    :value="user.userAddress?.address_type"
                    
                  ></InpComponent>
                </div>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Address"
                      type="text"
                      theme="light"
                      :value="user.userAddress?.address"
                      
      
                  ></InpComponent>
                </div>
              </div>
              <div class='row'>
      
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Town"
                      type="text"
                      theme="light"
                      :value="user.userAddress?.town"
                      
      
                  ></InpComponent>
                </div>
      
      
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="City"
                      type="text"
                      theme="light"
                      :value="user.userAddress?.city"
                      
                  ></InpComponent>
                </div>
              </div>
              <div class='row'>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                    label="Zip"
                    type="number"
                    theme="light"
                    :value="user.userAddress?.zip"
                    
                  ></InpComponent>
                </div>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                    label="Country"
                    type="options"
                    :options="[{text: user.userAddress?.country, value: user.userAddress?.country}]"
                    theme="light"
                    :value="user.userAddress?.country"
                    
                  ></InpComponent>
                </div>
              </div>
              <div class='row'>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                    label="State"
                    type="text"
                    theme="light"
                    :value="user.userAddress?.state"
                    
      
                  ></InpComponent>
                </div>
                <div class="input-wrapper"></div>
              </div>
            </div>
          </div>
      
          <div class='section'>
            <div class="input-section">
              <div class='section-header'>Phone</div>
      
              <div class='row'>
                <div class="input-wrapper">
                  <!-- {text: 'Operational', value: 'Operational'},
                  {text: 'IP Address', value: 'IP Address'} -->
                  <InpComponent
                    disabled='true'
                    label="Contact type"
                    type="options"
                    :options="[
                      {text: 'Private', value: 'Private'}, 
                      {text: 'Business', value: 'Business'},
                      
                    ]"
                    theme="light"
                    :value="user.userPhone?.phone_type"
                  ></InpComponent>
                </div>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Communication type"
                      type="options"
                      :options="[
                        {text: 'Mobile Phone', value: 'Mobile Phone'},
                        {text: 'Landline Phone', value: 'Landline Phone'},
                        {text: 'Fax', value: 'Fax'}, 
                      ]"
                      theme="light"
                      :value="user.userPhone?.communication_type"
                  ></InpComponent>
                </div>
              </div>
      
              <div class='row'>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                    label="Country Prefix"
                    type="options"
                    :options="[{text: user.userPhone?.phone_prefix, value: user.userPhone?.phone_prefix}]"
                    theme="light"
                    :value="user.userPhone?.phone_prefix"
                    
      
                  ></InpComponent>
                </div>
                <div class="input-wrapper">
                  <InpComponent
                    disabled='true'
                      label="Number"
                      type="number"
                      theme="light"
                      :value="user.userPhone?.phone_number"
                      
      
                  ></InpComponent>
                </div>
              </div>
            </div>
          </div>
    </div>
</template>

<script>

import InpComponent from '@/components/InpComponent.vue'

import { mapState } from 'vuex';
import BO_API from '../api/bo_api.js'
import API from '../api/index.js'
export default {
    name: 'UserView',
    components: {
        InpComponent
    },
    props: {
        user: Object,
    },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
    :deep(div label) {
        color: black;
    }
}
.home {
    width: 50%;
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  }
  
  .date {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &:deep(input){
      width: 70%;
      margin-right: 5px;
    }
  
  }
  .input-holder {
    display: flex;
    align-items: center;
  
  }
  .button-holder {
    width: 150px;
    height: 40px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    button {
      height: 40px;
  
    }
  }
  
  .input-section {
    box-sizing: border-box;
  }
  
  .input-wrapper {
    flex: 1;  
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    
  }
  .section {
    max-width: 650px;
    width: 600px;
  }
  
  .section-header {
    color: black;
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    margin: 20px 0;
  }
  .error {
    margin-top: -10px;
    margin-bottom: 10px;
    color: red;
  }
  .text {
    color: #3D5873;
    margin-right: 5px;
  }



</style>