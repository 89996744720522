<template>
  <div id="app" v-if="loaded">
    <div class='popup-dialog'>
      <div v-for="(item, index) in notifications" :class="`popup-item ${item.type}`">
        {{item.text}}
      </div>
    </div>
    <Header/>
    <router-view class="view"/>
  </div>
  <div class='loading-screen' v-else>
    <h1>Loading...</h1>
  </div>

</template>


<script setup>

import { mapState } from 'vuex';
import Header from './components/Header.vue'
import ResponseInfo from './components/ResponseInfo.vue'
import API from './api/index.js'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()


import { onMounted, watch, computed } from 'vue'

import store from './store';


const user = computed(() => store.state.user);
const notifications = computed(() => store.state.notifications);
const loaded = computed(() => store.state.loaded);



onMounted(async () => {
  console.log('App component mounted.')
  if (localStorage.getItem('token')) {
    await API.getUser()
    if (user.value && user.value.type == 'COMPLIANCE_OFFICER') {
      await API.getNotificationCount();
    }
  }
})



watch(user, async (newUser, oldUser) => {
  if (!newUser && loaded.value) {
    router.push('/login')

  }
})
  // watch: {
  //   user: function (val) {
  //     console.log('user changed', val)
  //     if (!val && this.loaded) {
        
  //     }
  //   },
  // },


</script>
<style lang="scss">
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #f3f5f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3D5873;
  font-size: 2em;

}
.view {
  padding-top: 60px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3D5873;
}
.popup-dialog {
  position: fixed;
  top: 30px;
  right: 30px;
  width: 240px;
  z-index: 9999;
  overflow-y: auto;
  max-height: calc(100vh - 30px);
}
.popup-item {
  position: relative;
  background: #f7f7f7;
  color: #071422;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  &::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: red;
  }
    &.success {
      &::before {
        background: green;
      }
    }
    &.error {
      &::before {
        background: red;
      }
    }
}
html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
  // width: 100vw;
  background: white;
  background-color: #f3f5f6;
  color: #3D5873;
  box-sizing: border-box;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #3c556e;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: -3px;
        right: -3px;
        height: 4px;
        background: #3c556e;
      }
    }

  }
}
.doc-header {
  text-align: left;
  margin: 0;
}

.margin-top-20 {
  margin-top: 20px;
}
button {
  background: #003F89;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px ;
  width: calc(100% + 10px);
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &.fit-content {

    width: fit-content;
    padding: 10px 15px;
  }

  cursor: pointer;
  &:hover {
    background: #00408985;
  }
}
input {
  border-radius: 10px;
  background: #38314e;
  border: 1px solid #2b263a;
  outline: none;
  caret-color: #2b263a;
  color: white;
  padding: 3px 10px;
  &.error {
    border: 1px solid red;
  }
}

.label-like {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #3D5873;
  text-align: left;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
td input {
  width: 99%;
  margin: auto;
  height: 26px;
  border-radius: unset;
  padding: 0px;
  font-size: 16px;
  border: none;
  background: transparent;
  color: black;
  caret-color: black;
  outline: 1px solid blue;
}
td   select {
  height: 28px;
  max-width: 190px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-top-40 {
  margin-top: 40px;
}
.space-between {
  justify-content: space-between;
}
.wrap {
  flex-wrap: wrap;
}
.flex {
  display: flex;
} 
.column {
  flex-direction: column;
} 
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.gap-40 {
  gap: 40px;
}
.gap-10 {
  gap: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-40 {
  margin-bottom: 40px;
}
.full-width {
  width: 100%;
}
.gap-30 {
  gap: 30px;
}
.gap-40 {
  gap: 40px;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.width-1of3 {
  width: 33%;
}

</style>
