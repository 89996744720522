<template>
    <div class="kyc-view">
        <div v-if="loaded=false">LOADING...</div>
        <div class="header-section">

            <div class="kyc-header">
                KYC Management
                <div class="tab-views">
                    <div v-if="user && user.type == 'COMPLIANCE_OFFICER'" class='tab' :class="`${stateView == 'dashboard' ? 'active' : ''}`" @click="changeView('dashboard')"><div class="line"></div>Dashboard</div>
                    <div class='tab' :class="`${stateView == 'corporate' ? 'active' : ''}`" @click="changeView('corporate')"><div class="line"></div>Corporate</div>
                    <div class='tab' :class="`${stateView == 'ubo' ? 'active' : ''}`" @click="changeView('ubo')"><div class="line"></div>UBO</div>
                    <div class='tab' :class="`${stateView == 'mbod' ? 'active' : ''}`" @click="changeView('mbod')"><div class="line"></div>Managing board of directors</div>
                    <div 
                        v-if="user && !['COMPLIENCE_OFFICER','EXTERNAL_AUDITOR','INTERNAL_AUDITOR'].includes(user.type)"
                        class='tab' :class="`${stateView == 'comment_section' ? 'active' : ''}`" @click="changeView('comment_section')"><div class="line"></div>Comment Section</div>
                    
                    <div class='tab notifications' :class="`${stateView == 'notifications' ? 'active' : ''}`" @click="changeView('notifications')"><div class="line"></div>
                        Notifications
                        <div class='notification-counter' v-if="notificationsCount"> {{ notificationsCount }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="kyc-fields-container" v-if="dataStructure">
            <div class="ubos">
                <button class="add-new fit-content" @click="Save()"> Save </button>
            </div>

            <kycTabSetting 
                v-if="stateView === 'dashboard' && user.type == 'COMPLIANCE_OFFICER'"
                :companyData="companyData"
                :dataStructure="dataStructure"  
                @changeCompanyData="companyUpdate"
            />
            <generalKYCView 
                v-if="stateView === 'corporate'"
                :dataStructure="dataStructure" 
                @handleFileChange="handleFileChange"
                @textareaUpdates="textareaUpdates"
                @handleDateChange="handleDateChange"
                @commentChange="commentChange"
                @addnewDocument="addnewDocument"
                @changeInfo="changeInfo"
            />
            <div class="ubos" v-if="stateView === 'ubo'">

                <div class="flex row align-center mb-20">
                    <h3 class="doc-header">Ultimate Beneficial Owners that own more than 10% of the company </h3>
                    <div style="margin-left: auto">
                        <button class="add-new no-margin fit-content" @click="createUbo()">Add UBO</button>
                    </div>
                </div>
                <UBO v-for="(ubo, index) in dataStructure['ubos']"
                    :key="`index_ubo-${ubo.id}`"
                    :ubo="ubo.struct"
                    :location_type="ubo.location_type"
                    :id="ubo.id"
                    @handleStructureUpdates="handleStructureUpdates"
                    @update_ubo="update_ubo"
                    @deleteUbo="deleteUbo"
                    >
                </UBO>
            </div>
            <div class="ubos" v-if="stateView === 'mbod'">
                <div class="flex row align-center mb-20">
                    <h3 class="doc-header">Managing board of directors</h3>
                    <div style="margin-left: auto">
                        <button class="add-new no-margin fit-content" @click="createUbo()">Add Managing Director</button>
                    </div>
                </div>
                <ManagingDirrector 
                    v-for="(mbd, index) in dataStructure['mbds']"
                    :key="`index_mbd-${mbd.id}`"
                    :mbd="mbd"
                    
                    @handleFileChange="handleFileChange"
                    @inputChangeFunc="inputChangeFunc"
                    @update_mbd="update_mbd"
                    @deleteMBD="deleteMBD"
                    ref="ManagingDirrectorRefs"
                    class="managing-director"
                />
            </div>
            <div v-if="stateView === 'notifications' && user && user.type == 'COMPLIANCE_OFFICER'">

                <NotificationsView @updatingURL="updatingURL"/>

            </div>
            <div v-if="stateView === 'comment_section'">
                <CommentSection 
                    :commentSection="commentSection"
                    @quillContent="quillContent"    
                />
            </div>  
            <div v-else></div>

        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'

import ManagingDirrector from '@/components/ManagingDirrector.vue'

import API from '../api/index.js'
import UBO from '@/views/UBO.vue'
import generalKYCView from './generalKYCView.vue'
import CommentSection from './CommentSection.vue'
import NotificationsView from './NotificationsView.vue'
import kycTabSetting from './kycTabSetting.vue'
import {

    getUboStructure, getMbodStructure,
    addPersonToUBO,
    objectId
} from '../utils/kyc_utils.js'

import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'
import Utils from '@/utils/index.js'
import store from '../store';
import { mapState } from 'vuex';
import { compileString } from 'sass'


const router = useRouter();
const route = useRoute();


const query = ref(route.query)

const pageView =  ref(query.value.view)
const focusElement = ref(query.value.focus_element)

const loaded = ref(false)
const selectedFiles = ref({})
const dataStructure = ref(null);
const companyData = ref(null);
const companyDataInitial = ref(null);

const commentSection = ref(null);
const commentSectionInitial = ref(null);


const popupShown = ref(false);
const popupType = ref('');
const stateView = ref(pageView.value || 'corporate')
const ManagingDirrectorRefs = ref([])
const user = computed(() => store.state.user);
const notificationsCount = computed(() => store.state.notificationsCount);
const validationOn = computed(() => store.state.validationOn);

const initialCommentString = computed(() => {
    if (!commentSectionInitial.value) {
        return null;
    }
    
    return JSON.stringify(commentSectionInitial.value)

})

const commentSectionString = computed(() => {
    if (!commentSection.value) {
        return null;
    }
    return JSON.stringify(commentSection.value)
})

onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }

    const response = await API.getKYCFilesList();

    loaded.value = true;
    dataStructure.value = response.data.data;
    companyData.value = response.data.companyData;
    companyDataInitial.value = JSON.parse(JSON.stringify(response.data.companyData));

    commentSection.value = JSON.parse(response.data.commentSection.comment_structure);
    commentSectionInitial.value = JSON.parse(response.data.commentSection.comment_structure);
    dataStructure.value['textarea'].prev_val = dataStructure.value['textarea'].value;
});


function quillContent(event) {
    commentSection.value = event;
}

function changeView(event){
    stateView.value = event;
    router.push({ query: { view: event } });
    // stateView.value = event;
}


function updatingURL(event) {
    changeView(event.base);
    setTimeout(() => {
        const element = document.getElementById(event.id)
        if (!element) {
            alert('Element not found' +  element)
            return;
        }
        const y = element.getBoundingClientRect().top + window.scrollY - 100;

        window.scrollTo({top: y, behavior: 'smooth'});
        // element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }, 300)

}
function textareaUpdates(value){
    dataStructure.value['textarea'].new_value = value;
}


function createUbo(){
    dataStructure.value['ubos'].unshift(
        getUboStructure()
    )
}
function createMdob() {
    dataStructure.value['mbds'].unshift(
        getMbodStructure({is_new: true})
    )
}
function addnewDocument(structure) {
    dataStructure.value[structure].unshift({
        id: objectId(),
        new_file: '',
        file: null,
    })
}
function clearPersonFields(person){
    for (let personProp in person) {
        if (personProp === 'id') continue;
        if (['percentage_held', 'location_type', 'title'].includes(personProp)) {
            person[personProp].new_value = ''
        }
        for(let personField in person[personProp]) {
            if (personField == 'title' || personField == 'file'){
                continue;
            }
            if (person[personProp][personField] && person[personProp][personField].new_value) {
                person[personProp][personField].new_value = ''
            }
        }
    }
}
function clearEntityFields(legalEntity) {
    for (let prop in legalEntity) {
        if (['id', 'mbd_ubo', ''].includes(prop)) {
            continue;
        }
        if (prop == 'address') {
            for (let addressProp in legalEntity[prop]) {
                if (legalEntity[prop][addressProp].new_value) {
                    legalEntity[prop][addressProp].new_value = ''
                }
            }
            continue
        }
        if (['name','supervised','location_type', 'entity_type'].includes(prop)) {
            if (legalEntity[prop].new_value || legalEntity[prop].new_value === false) {
                legalEntity[prop].new_value = ''
            }
            continue
        }
    }
}
async function uploadPerson(person, ubo, mbd){
    const fields_key = []

    for (let personProp in person) {

        if (personProp === 'id') continue;

        if (['percentage_held', 'location_type', 'title'].includes(personProp)) {
            if (person[personProp].new_value) {
                console.log(personProp, person[personProp].new_value)
                fields_key.push({ 
                    [personProp]: person[personProp].new_value
                })
            }
        }

        // CAN be redone to send all the fields at once
        for(let personField in person[personProp]) {
            if ([
                'new_comment_data_handler', 
                'new_comment_compliance_officer', 
                'new_dateIssued'].includes(personField)
                && person[personProp][personField]
            ) {
                const objectToSend = {
                    fileType: personProp, 
                    person_id: person.id,
                    ubo: ubo ? JSON.stringify(ubo) : '',
                    mbd: mbd ? JSON.stringify(mbd) : '',
                    location_type: ubo?.location_type || mbd?.location_type.value,
                }
                const key = personField.replace('new_', '')
                if (person[personProp][personField]) {
                    objectToSend[key] = person[personProp][key]
                }
              
                const response = await API.uploadKYCFile(
                    null, 
                    objectToSend
                );
                if (response.status == 'success') {
                    person[personProp][personField] = false;
                }
                continue;
            }
            if (personField == 'title'){
                continue;
            }
            if (personField == 'file'){
                continue;
            }
            if (person[personProp][personField].new_value) {
                fields_key.push({ 
                    [personField]: person[personProp][personField].new_value
                })
                continue;
            }
            if (personField == 'new_file' && person[personProp][personField]) {
                const response = await API.uploadKYCFile(
                    person[personProp]['file'], 
                    { 
                        person_id: person.id,
                        ubo: ubo ? JSON.stringify(ubo) : '',
                        mbd: mbd ? JSON.stringify(mbd) : '',
                        fileType: personProp, 
                        location_type: ubo?.location_type || mbd?.location_type.value,

                        type: 'ubo_person'
                    }
                );
                person[personProp][personField] = false;
            }
        }
    }

    //save file fields to the documents
    if (Object.keys(fields_key).length) {
        let response = await API.uploadKYCFile(
            null, 
            { 
                person_id: person.id,
                ubo: ubo ? JSON.stringify(ubo) : '', 
                mbd: mbd ? JSON.stringify(mbd) : '',
                text_field_values: JSON.stringify(fields_key), 
            }
        );
        if (response.status == 'success') {
            clearPersonFields(person)
        }
    }
}
function deleteMBD(event) {
    let index = dataStructure.value.mbds.findIndex(el => el.id = event.id)
    if (index != -1) {
        store.dispatch('addNotification', {text: 'Something went wrong', type: 'error'})
        return;
    }
    dataStructure.value.mbds.splice(index, 1);
}
function deleteUbo(event) {
    let index = dataStructure.value.ubos.findIndex(el => el.id = event.id)
    if (index != -1) {
        store.dispatch('addNotification', {text: 'Something went wrong', type: 'error'})
        return;
    }
    dataStructure.value.ubos.splice(index, 1);
}
async function uploadMBD(mbds) {
    let mdbAPIData = {
        id: mbds.id,
        location_type: mbds.location_type.new_value || mbds.location_type.value,
        entity_type: mbds.entity_type.new_value || mbds.entity_type.value,
        supervised: mbds.supervised.new_value || mbds.supervised.value,
    }
    if (mbds.struct.person){
        for (let mbdsPerson of mbds.struct.person) {
            await uploadPerson(mbdsPerson, null, mdbAPIData)
        }
    }
    let mbd_fields = []
    
    if (mbds.location_type.new_value) {
        mbd_fields.push({ location_type: mbds.location_type.new_value })
        mbds.location_type.value = mbds.location_type.new_value
    }
    if (mbds.entity_type.new_value) {
        mbd_fields.push({ entity_type: mbds.entity_type.new_value })
        mbds.entity_type.value = mbds.entity_type.new_value
    }
    if (mbds.supervised.new_value) {
        mbd_fields.push({ supervised: mbds.supervised.new_value })
        mbds.supervised.value = mbds.supervised.new_value
    }
    if (mbd_fields.length){
        let response = await API.uploadKYCFile(
            null, 
            { 
                mbd: JSON.stringify(mdbAPIData),
                text_field_values: JSON.stringify(mbd_fields), 
            }
        );
        if (response.status == 'success') {
            mbds.location_type.new_value = ''
            mbds.entity_type.new_value = ''
            mbds.supervised.new_value = ''
        }
    }

    if (mbds.struct.legalEntity) {
        
        const legalEntity = mbds.struct.legalEntity;
        const entity_fields = []

        for (let prop in legalEntity) {
            if (prop == 'id') {
                continue;
            }

            if (prop == 'address') {
                for (let addressProp in legalEntity[prop]) {
                    if (legalEntity[prop][addressProp].new_value) {
                        entity_fields.push({ [addressProp]: legalEntity[prop][addressProp].new_value })
                    }
                }
                continue
            }

            if (['name','supervised','location_type', 'entity_type'].includes(prop)) {
                if (legalEntity[prop].new_value || legalEntity[prop].new_value === false) {
                    entity_fields.push({ [prop]: legalEntity[prop].new_value })
                }
                continue
            }
            if (prop == 'mbd_ubo') {
                for (let ubo of legalEntity[prop]) {
                    await uploadUbo(ubo, mbds)
                }
                continue
            }
            if (
                legalEntity[prop]['new_comment_data_handler'] ||
                legalEntity[prop]['new_comment_compliance_officer']
            ) {
                const objectToSend = {
                    fileType: prop, 
                    legalEntity_id: legalEntity.id,
                    mbd: JSON.stringify(mdbAPIData),
                }
                if (legalEntity[prop]['new_comment_data_handler']) {
                    objectToSend['comment_data_handler'] = legalEntity[prop]['comment_data_handler']
                }
                if (legalEntity[prop]['new_comment_compliance_officer']) {
                    objectToSend['comment_compliance_officer'] = legalEntity[prop]['comment_compliance_officer']
                }
                const response = await API.uploadKYCFile(
                    null, 
                    objectToSend
                );
                if (response.status == 'success') {
                    legalEntity[prop]['new_comment_data_handler'] = false;
                    legalEntity[prop]['new_comment_compliance_officer'] = false;
                }
            }
            if (!legalEntity[prop].new_file) {
                continue;
            }  

            const response = await API.uploadKYCFile(
                legalEntity[prop].file, 
                { 
                    mbd: JSON.stringify(mdbAPIData),
                    fileType: prop, 
                    legalEntity_id: legalEntity.id,

                }
            );
        }
        if (Object.keys(entity_fields).length) {
            let response = await API.uploadKYCFile(
                null, 
                { 
                    legalEntity_id: legalEntity.id,
                    mbd: JSON.stringify(mdbAPIData),
                    text_field_values: JSON.stringify(entity_fields), 
                }
            );
            if (response.status == 'success') {
                clearEntityFields(legalEntity)
            }
        }
    }
}

async function uploadUbo(ubo, mbd){
    let uboApiData = ubo ? {
        id: ubo?.id,
        location_type: ubo?.location_type,
    } : null
    
    let mdbAPIData = mbd ? {
        id: mbd?.id,
        location_type: mbd?.location_type.new_value || mbd?.location_type.value,
        entity_type: mbd?.entity_type.new_value || mbd?.entity_type.value,
        supervised: mbd?.supervised.new_value || mbd?.supervised.value,
    } : null

    for (let prop in ubo.struct) {
        if (prop == 'person') {
            const person = ubo.struct[prop];
            await uploadPerson(person, uboApiData, mdbAPIData)
        }
        else if (prop == 'location_type') {
            if (!ubo.struct[prop].new_value) {
                continue;
            }
            let response = await API.uploadKYCFile(
                null,
                { 
                    ubo: JSON.stringify(uboApiData),
                    text_field_values: JSON.stringify([{ [prop]: ubo.struct[prop].new_value}]), 
                }
            );
            if (response.status == 'success') {
                ubo.struct[prop].new_value = ''
            }
        } else {
            if (!ubo.struct[prop].new_file) {
                continue;
            }  
            const response = await API.uploadKYCFile(
                ubo.struct[prop].file, 
                { 
                    ubo: JSON.stringify(uboApiData),
                    mbd: JSON.stringify(mdbAPIData),
                    fileType: prop, 
                }
            );
        }
    }
}


async function Save(){
    if (JSON.stringify(companyDataInitial.value) != JSON.stringify(companyData.value)) {
        await API.uploadSettings(companyData.value)
        companyDataInitial.value = JSON.parse(JSON.stringify(companyData.value))
    }
    if ( initialCommentString.value != commentSectionString.value ) {
        await API.uploadComment(JSON.stringify(commentSection.value))
        commentSectionInitial.value = JSON.parse(JSON.stringify(commentSection.value))
    }
 
    for(let key in dataStructure.value) {
        if (key === 'ubos') {

            for (let ubo of dataStructure.value[key]) {
                await uploadUbo(ubo, null)
            }
        }
        if (key === 'mbds') {
            
            for (let mbds of dataStructure.value[key]) {
                await uploadMBD(mbds)
            }
        };
        
        if (key === 'textarea') {
            if (dataStructure.value['textarea'].new_value) {
                const response = await API.uploadKYCFile(null, { textarea_value: dataStructure.value['textarea'].new_value, fileType: 'textarea' });
                dataStructure.value['textarea'].new_value = ''
            }
        };
        if (['cosbaof', 'scfba'].includes(key)) {
            for(let file of dataStructure.value[key]) {
                
                if (file.new_file) {
                    const response = await API.uploadKYCFile(
                        file.file, 
                        { 
                            fileType: key,
                            file_id: file.id, 
                        }
                    );
                    if (response.kyc_file) {
                        file.file = response.kyc_file;
                        file.new_file = false
                    }
                }
            }
        }
        if (
            dataStructure.value[key].new_dateIssued ||
            dataStructure.value[key].new_comment_data_handler ||
            dataStructure.value[key].new_comment_compliance_officer ||
            dataStructure.value[key].new_year ||
            dataStructure.value[key].new_fiscal_year_ending_on

        ) {

            const objectToSend = {
                fileType: key, 
            }
            if (dataStructure.value[key].new_dateIssued) {
                objectToSend['dateIssued'] = dataStructure.value[key].dateIssued
            }
            if (dataStructure.value[key].new_comment_data_handler) {
                objectToSend['comment_data_handler'] = dataStructure.value[key].comment_data_handler
            }
            if (dataStructure.value[key].new_comment_compliance_officer) {
                objectToSend['comment_compliance_officer'] = dataStructure.value[key].comment_compliance_officer
            }
            if (dataStructure.value[key].new_fiscal_year_ending_on) {
                objectToSend['fiscal_year_ending_on'] = dataStructure.value[key].fiscal_year_ending_on
            }
            if (dataStructure.value[key].new_year) {
                objectToSend['year'] = dataStructure.value[key].year
            }

            const response = await API.uploadKYCFile(
                null, 
                objectToSend
            );
            if (response.status == 'success') {
                dataStructure.value[key].new_dateIssued = false;
                dataStructure.value[key].new_comment_data_handler = false;
                dataStructure.value[key].new_comment_compliance_officer = false;
                dataStructure.value[key].new_year = false;
                dataStructure.value[key].new_fiscal_year_ending_on = false;
            }
        }
        //regular files
        if (dataStructure.value[key].new_file) {
            const response = await API.uploadKYCFile(
                dataStructure.value[key].file, 
                { 
                    fileType: key, 
                }
            );
            if (response.kyc_file) {
                dataStructure.value[key].file = response.kyc_file;
                dataStructure.value[key].new_file = false

            }
            continue
        }
    }
}

function handleStructureUpdates(event) {
    if (validationOn.value) {
        let errors = Utils.validateDataStructure(dataStructure.value);
        if (errors) {
            store.dispatch('setValue', { key: 'validationOn', value: true })
            return;
        }
        else {
            store.dispatch('setValue', { key: 'validationOn', value: false })
        }
    }

    let dataUbo = dataStructure.value['ubos'].find(el => event.index === el.id)
    dataUbo = event.ubo

    if (event.file) {
        let cell = selectedFiles.value[event.file.index] || [];
        cell.push({
            type: event.file.type,
            file: event.file.file, 
        }) 
        selectedFiles.value[event.file.index] = cell;
    }
}

function handleFileChange(event) {
    try {
        let cell = dataStructure.value[event.fileType] 
        if (event.id) {
            cell = dataStructure.value[event.fileType].find(el => el.id === event.id)   
            if (!cell) {
                alert('error updating the files')
            }
        }
        cell.file = event.file
        cell.new_file = true
    } catch(error) {
        throw error
    }
  
}

function commentChange(event) {
    const comment_type = event.comment_type;
    dataStructure.value[event.fileType][comment_type] = event.comment;
    dataStructure.value[event.fileType][`new_${comment_type}`] = true;
}
function changeInfo(event) {
    dataStructure.value[event.fileType][event.event_type] = event.event_value
    dataStructure.value[event.fileType][`new_${event.event_type}`] = event.event_value
}

function handleDateChange(event) {
    dataStructure.value[event.fileType].dateIssued = event.date;
    dataStructure.value[event.fileType].new_dateIssued = true;
}

function update_ubo(event) {
    let uboIndex = dataStructure.value['ubos'].findIndex(el => event.ubo === el.id)
    let ubo = dataStructure.value['ubos'][uboIndex]
    ubo[event.field] = event.value;
    
    if (!ubo.struct.person) {
        addPersonToUBO(ubo)
    } else {
        ubo.struct.person.location_type.new_value = event.value
    }

}
function companyUpdate(value) {
    companyData.valu = value
}
function update_mbd(event) {
    if (validationOn.value) {
        let errors = Utils.validateDataStructure(dataStructure.value);
        if (errors) {
            store.dispatch('setValue', { key: 'validationOn', value: true })
            return;
        }
        else {
            store.dispatch('setValue', { key: 'validationOn', value: false })
        }
    }
    if (!event.field) {
        return;
    }
    let mbdIndex = dataStructure.value['mbds'].findIndex(el => event.mbd === el.id)
    let mbd = dataStructure.value['mbds'][mbdIndex]
    mbd[event.field].new_value = event.value;
    if (event.field == 'supervised') {
        mbd.struct.legalEntity[event.field].new_value = event.value
    }
    if (event.field == 'location_type') {
        mbd.struct.legalEntity[event.field].new_value = event.value
        
        mbd.struct.person && mbd.struct.person.forEach(el => {
            el.location_type.new_value = event.value
        })
    }


}

</script>

<style scoped lang="scss">


    .notifications {
       position: relative;

    }

    .notification-counter {
        background: #f59f9f;
        border-radius: 50%;
        padding: 5px;
        top: -2px;
        right: -20px;
        position: absolute;
        min-height: 16px;
        min-width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .popup-content {
        position: relative;
        width: 100%;
        min-height: 150px;
    }
    .popup-close {
        position: absolute;
        right: 10px;
        top: -5px;
        cursor: pointer;
    }
    .content-popup {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex: 1;
        align-items: center;


        .option-select {
            padding: 10px 20px;
            cursor: pointer;
            border: 1px solid #3c556e;
            border-radius: 5px;
        }
    }
    .popup-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        .option-select {
            padding: 10px 20px;
            height: fit-content;
            cursor: pointer;
            border: 1px solid #3c556e;
            border-radius: 5px;
        }
    }
    .header-section {
        background-color: white;
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .kyc-header {
        max-width: 1350px;
        width: 100%;
        text-align: left;
        font-size: 28px;
        position: relative;

        .tab-views {
            font-size: 14px;
            position: absolute;
            left: -18px;
            top: 37px;
            display: flex;
            .tab {
                padding: 10px 20px;
                cursor: pointer;
                &.active {
                    position: relative;
                    .line {
                        position: absolute;
                        left: 10px;
                        right: 10px;
                        bottom: -6px;
                        height: 5px;
                        background-color: #3c556e;
                    }
                }
            }
        
        }
    }
    .kyc-fields-container {
        width: 100%;
        max-width: 1350px;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // align-items: center;
    }
    .kyc-fields-area {
        max-width: 1300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;

    }
    .kyc-item-section {
        width: 1300px;
        margin-bottom: 30px;
        h3 {
            text-align: left;
        }
        &.extra-margin {
            margin-bottom: 50px;
        }
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .no-margin[class] {
        margin-top: 0;
        margin-bottom: 0;
    }
    .margin-top-20 {
        margin-top: 20px;
    }
    .header-of-section {
        font-size: 24px;
    }
    .managing-director {
        max-height: 80vh;
    }

</style>