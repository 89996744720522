<template>
    <div class="kyc-fields-area mb-40">
        <div class="flex full-width gap-40">
            <div class="section mb-40 flex-1">
                <h3>Company Data</h3>
                <div class="flex column">
                    <InpComponent 
                        type="text" 
                        theme="light"
                        light_label="true"
                        key_ref="name"
                        :value="companyData.name" 
                        @inputChange="inputChangeFunc"
                        label="Name"
                    />
                    <AddressComp
                        key="company-address"
                        class="full-width address"
                        :address="localAddress"
                        @addressChange="changeAddress">
                    </AddressComp>

                    <InpComponent 
                        type="text" 
                        theme="light"
                        light_label="true"
                        key_ref="chamber_of_commerce"
                        :value="companyData.chamber_of_commerce" 
                        @inputChange="inputChangeFunc"
                        label="Chamber of Commerce"
                    />

                    <InpComponent 
                        type="text" 
                        theme="light"
                        light_label="true"
                        key_ref="activity"
                        :value="companyData.activity" 
                        @inputChange="inputChangeFunc"
                        label="Activity"
                    />
                </div>
            </div>
            <ChartComponent
                :dataStructure="props.dataStructure"
                class="flex-2 flex justify-center align-center"
                style="height: 450px; width: 450px;"
            ></ChartComponent>
        </div>

            <div class="section full-width mb-40">
                <div class="flex row gap-40">
                    <div class="inner-section flex-1">
                        <h3>Risk Classification</h3>
                        <div class="flex column">
                            <div class="flex">
                                <input type="radio" id="low" name="risk_level" value="low" :checked="localCompanyData.risk_level == 'low'" @change="handleChange('risk_level', 'low')">
                                <label for="low">Low</label>
                            </div>
                            <div class="flex">
                                <input type="radio" id="medium" name="risk_level" value="medium" :checked="localCompanyData.risk_level == 'medium'" @change="handleChange('risk_level', 'medium')">
                                <label for="medium">Medium</label>
                            </div>
                            <div class="flex">
                                <input type="radio" id="high" name="risk_level" value="high" :checked="localCompanyData.risk_level == 'high'" @change="handleChange('risk_level', 'high')">
                                <label for="high">High</label>
                            </div>
                        </div>
                    </div>
                    <div class="inner-section flex-1">
                        <h3 class="">Annual Accounts</h3>
                        <div class="flex column">
                            <div class="label-like"> Annual accounts confirm calendar year</div>
                            <div class="flex row gap-10 mb-15" >
                                <div class="flex align-center justify-center">
                                    <input 
                                        type="radio" 
                                        id="YES" 
                                        style="margin: 0 5px"
                                        name="annual_account_conform_calendar_year"
                                        value="YES" 
                                        :checked="localCompanyData?.annual_account_conform_calendar_year == 'YES'" 
                                        @change="handleChange('annual_account_conform_calendar_year', 'YES')"
                                    >
                                    <label for="YES">YES</label>
                                </div>
                                <div class="flex">
                                    <input 
                                        type="radio" 
                                        id="NO" 
                                        style="margin: 0 5px"
                                        name="annual_account_conform_calendar_year"
                                        value="NO" 
                                        :checked="localCompanyData?.annual_account_conform_calendar_year == 'NO'" 
                                        @change="handleChange('annual_account_conform_calendar_year', 'NO')"
                                    >
                                    <label for="NO">NO</label>
                                </div>
                            </div>
                            <InpComponent 
                                type="options"
                                :options="yearOptions"  
                                theme="light"
                                label="Current fiscal year"
                                key_ref="current_fiscal_year"
                                :value="localCompanyData?.current_fiscal_year" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent 
                                v-if="localCompanyData?.annual_account_conform_calendar_year == 'NO'"
                                type="date"
                                theme="light" 
                                label="Fiscal Year runs from"
                                key_ref="fiscal_year_starts_on"
                                :value="localCompanyData?.fiscal_year_starts_on" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent 
                                v-if="localCompanyData?.annual_account_conform_calendar_year == 'NO'"
                                type="date"
                                theme="light" 
                                label="Fiscal Year runs up till"
                                key_ref="fiscal_year_ending_on"
                                :value="localCompanyData?.fiscal_year_ending_on" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent 
                                type="options"
                                :options="yearOptions"  
                                theme="light" 
                                label="Last Annual Accounts on file"
                                key_ref="last_annual_account_on_file"
                                :value="localCompanyData?.last_annual_account_on_file" 
                                @inputChange="inputChangeFunc"
                            />

                        </div>
                    </div>
                    <div class="inner-section flex-1">
                        <h3>Profit Tax</h3>
                        <div class="flex column">
                            <InpComponent 
                                type="options"
                                :options="yearOptions"                   
                                theme="light"
                                label="Last filed Profit Tax on file "
                                key_ref="last_filed_profit_tax_on_file"
                                :value="localCompanyData?.last_filed_profit_tax_on_file" 
                                @inputChange="inputChangeFunc"
                            />
                            <div class="label-like"> Extension Profit Tax</div>

                            <div class="flex row gap-10 mb-15" >
                                <div class="flex align-center justify-center">
                                    <input 
                                        type="radio" 
                                        id="YES" 
                                        style="margin: 0 5px"
                                        name="extension_profit_tax"
                                        value="YES" 
                                        :checked="localCompanyData?.extension_profit_tax == 'YES'" 
                                        @change="handleChange('extension_profit_tax', 'YES')"
                                    >
                                    <label for="YES">YES</label>
                                </div>
                                <div class="flex">
                                    <input 
                                        type="radio" 
                                        id="NO" 
                                        style="margin: 0 5px"
                                        name="extension_profit_tax"
                                        value="NO" 
                                        :checked="localCompanyData?.extension_profit_tax == 'NO'" 
                                        @change="handleChange('extension_profit_tax', 'NO')"
                                    >
                                    <label for="NO">NO</label>
                                </div>
                            </div>


                            <InpComponent 
                                v-if="localCompanyData?.extension_profit_tax == 'YES'"
                                type="date"
                                theme="light" 
                                label="Extension up till"
                                key_ref="extension_up_till"
                                :value="localCompanyData?.extension_up_till" 
                                @inputChange="inputChangeFunc"
                            />
                        </div>
                    </div>
                </div>
            </div>
            

        <div class="section mb-40 width-1of3">
            <h3>Kyc Review</h3>
            <div class="flex column">
                <InpComponent 
                    type="date" 
                    theme="light"
                    label="Date previous compliance review"
                    key_ref="previous_review_date"
                    :value="localCompanyData?.previous_review_date" 
                    @inputChange="inputChangeFunc"
                />
                <InpComponent 
                    type="date"  
                    theme="light"
                    label="Date proposed compliance review"
                    key_ref="next_review_date"
                    :value="localCompanyData?.next_review_date" 
                    @inputChange="inputChangeFunc"
                />
                <InpComponent 
                    type="date" 
                    theme="light" 
                    label="Date final compliance review"
                    key_ref="date_final_compliance_review"
                    :value="localCompanyData?.date_final_compliance_review" 
                    @inputChange="inputChangeFunc"
                />
            </div>
        </div>

        <div class="mb-40 margin-top-40 accounts-section">
            <div class="flex row align-center">
            <h3>
                Accounts
            </h3>
            <button v-if="!showNewUser" 
                @click="addNewUser"
                style="width: 140px; height: 40px; margin-left: 20px;"

                >Create User</button>
            </div>
            <div>
                <table class="table-users">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Last Login</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="account in UserAccounts" :key="'account' + account.id">
                            <td>{{ account.username }}</td>
                            <td>{{ account.email }}</td>
                            <td>{{ account.last_login }}</td>
                            <td>{{ account.type }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="new-user-section flex row gap-10 margin-top-20" v-if="showNewUser">
                    <InpComponent 
                        type="text" 
                        theme="light"
                        light_label="true"
                        key_ref="username"
                        :value="newUserData.username" 
                        @inputChange="inputChangeFunc"
                        label="Name"
                    />
                    <InpComponent 
                        type="text" 
                        theme="light"
                        light_label="true"
                        key_ref="email"
                        :value="newUserData.email" 
                        @inputChange="inputChangeFunc"
                        label="Email"
                    />
                    <InpComponent 
                        type="text" 
                        theme="light"
                        light_label="true"
                        key_ref="password"
                        :value="newUserData.password" 
                        @inputChange="inputChangeFunc"
                        label="Password"
                    />
                    <InpComponent 
                        type="options" 
                        :options="options"
                        theme="light"
                        light_label="true"
                        key_ref="type"
                        :value="newUserData.type" 
                        @inputChange="inputChangeFunc"
                        label="Role"
                    />
                </div>
                <button v-if="showNewUser" @click="createUser" class="save-button">Save</button>

            </div>
        </div>
    </div>
</template>

<script setup>

import InpComponent from '@/components/InpComponent.vue'
import AddressComp from '@/components/AddressComp.vue'
import { ref, defineEmits, onMounted } from 'vue' 

import ChartComponent from '@/components/ChartComponent.vue'

import API from '@/api/index.js'

const emit = defineEmits(['changeCompanyData'])

const yearOptions = ref([
    {value: '2021', text: '2021'},
    {value: '2022', text: '2022'},
    {value: '2023', text: '2023'},
    {value: '2024', text: '2024'},
    {value: '2025', text: '2025'},
    {value: '2026', text: '2026'},
    {value: '2027', text: '2027'},
    {value: '2028', text: '2028'},
    {value: '2029', text: '2029'},
    {value: '2030', text: '2030'},
    {value: '2031', text: '2031'},
    {value: '2032', text: '2032'},
    {value: '2033', text: '2033'},
    {value: '2034', text: '2034'},
    {value: '2036', text: '2036'},
    {value: '2037', text: '2037'},
])


const props = defineProps(['companyData','dataStructure'])
const UserAccounts = ref([])
const newUserData = ref({
    username: '',
    email: '',
    password: '',
    type: ''
});
const options = [
    {value: 'DATA_PROCESSOR', text: 'DATA PROCESSOR'},
    {value: 'DATA_CONTROLLER', text: 'DATA CONTROLLER'},
    {value: 'EXTERNAL_AUDITOR', text: 'EXTERNAL AUDITOR'},
    {value: 'INTERNAL_AUDITOR', text: 'INTERNAL AUDITOR'},

]
const showNewUser = ref(false)
const localCompanyData = ref(props.companyData)
function changeAddress(event) {

    localAddress.value[event.property] = event.value;
    localCompanyData.value[event.property] = event.value;
    emit('changeCompanyData', localCompanyData)

}

const localAddress = ref({
    address_line_1: {
        value: props.companyData.address_line_1,
        new_value: '',
        error: ''
    },
    address_line_2: {
        value: props.companyData.address_line_2,
        new_value: '',
        error: ''
    },
    address_country: {
        value: props.companyData.address_country,
        new_value: '',
        error: ''
    },
    city: {
        value: props.companyData.city,
        new_value: '',
        error: ''
    },
    address_country: {
        value: props.companyData.address_country,
        new_value: '',
        error: ''
    },
    zip: {
        value: props.companyData.zip,
        new_value: '',
        error: ''
    },
})

onMounted(async () => {
    const response = await API.getUserAccounts()
    UserAccounts.value = response;
})

function addNewUser() {
    showNewUser.value = !showNewUser.value
}
async function createUser(){
    const respons = await API.createNewUser(localCompanyData.value);
    if (!respons.error) {
        return
    } 
    UserAccounts.value.push(respons);
    showNewUser.value = !showNewUser.value
}
function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    
    localCompanyData.value[event_type] = event_value;

    emit('changeCompanyData', localCompanyData)
}


function handleChange(field, event) {
    localCompanyData.value[field] = event
    emit('changeCompanyData', localCompanyData)

}
</script>

<style lang="scss" scoped>

    .table-users {
        width: 100%;
        border-collapse: collapse;
        border-bottom: 1px solid #e6eaed;
    }
  .kyc-fields-area {
        max-width: 1300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        background-color: white;
        box-sizing: border-box;

        border-radius: 5px;
        padding: 30px;
    }
    h3 {
        text-align: left;
    }
    .save-button {
        width: 100px;
        height: 40px;
    }
    .new-user-section {
        gap: 20px;
        justify-content: space-between;
        padding: 10px 30px;
        background-color: #F3F5F6;
        border-radius: 5px;
    }
    .table-users {
        width: 100%;
        border-collapse: collapse;
        border-bottom: 1px solid #e6eaed;

    }
    td {
        padding: 10px 30px;
        text-align: left;
    }
    th {
        text-align: left;
        padding: 10px 30px;
        background: #F3F5F6;
    }
    .accounts-section {
        width: 80%;
    }
    .address[class] {
        max-width: unset;
    }

</style>