import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    userAddress: null,
    userPhone: null,
    userInformation: null,
    loaded: false,
    notificationsCount: null,
    validationOn: false,
    credits_balance: null,
    response_text: null,
    notifications: [],
    fileList: null,
    wallet: {
      loaded: false,
      address: null,
    }
  },
  getters: {
  },
  mutations: {
    setValue(state, {key, value}) {
      state[key] = value;
    },
    addNotification(state, notification) {
      console.log('addNotification mutations')

      const id = Math.floor(Math.random() * 1000);
      state.notifications.push({...notification, id});
      setTimeout(() => {
        state.notifications = state.notifications.filter(n => n.id !== id);
      }
      , 4_000);
    }
  },
  actions: {
    setValue({ commit }, newValue) {
      commit('setValue', newValue);
    },
    addNotification({ commit }, notification) {
      commit('addNotification', notification);
    }
  },
  modules: {
  }
})
