

class ID_Number_Validator {
    static validateIdNumber(idNumber, country) {
        if (!idNumber) {
            return 'Please provide ID Number';
        } else {
            // if (country === 'NETHERLANDS') {
            //   if (validateBSN(idNumber)) {
            //     return '';
            //   } else {
            //     return 'Invalid BSN';
            //   }
            // }
            if (idNumber.toString().length < 9) {
                return 'ID Number should be at least 9 characters long';
            }
        }
    }
}


class Utils {
    static calculateFileSize(bytes) {
        if (bytes < 1024) {
            return bytes + ' B';
        } else if (bytes < 1048576) {
            return (bytes / 1024).toFixed(2) + ' KB';
        } else if (bytes < 1073741824) {
            return (bytes / 1048576).toFixed(2) + ' MB';
        } else {
            return (bytes / 1073741824).toFixed(2) + ' GB';
        }
    }
    static NotNull(value) {
        console.log('NOT NULL CHECK', value)
        if (!value) {
            return 'Please provide a value';
        } else {
            return '';
        }
    }
    static validateDateIssued(date) {
        if (!date) {
            return 'Please provide Date Issued';
        } else {
            const now = new Date();
            const dateIssued = new Date(date);
            if (dateIssued > now) {
                return 'Date Issued cannot be in the future';
            }
        }
    }
    static validateDateExpiry(date) {
        if (!date) {
            return 'Please provide Date Expiry';
        } else {
            const now = new Date();
            const dateExpiry = new Date(date);
            if (dateExpiry < now) {
                return 'Date Expiry cannot be in the past';
            }
        }
    }
    static validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    static validateLength(value, min, max) {
        if (!value) {
            return 'Value is not provided'
        }
        return (value.toString().length >= min && value.toString().length <= max);
    }
    static validateEntityId(id) {
        if (!id) {
            return 'Please provide Organization Id from FIY';
        } else {
            if (id.length < 3) {
                return 'Organization Id should be at least 3 characters long';
            } else {
                return '';
            }
        }
    }
    static validateEntityName(name,) {
        if (!name) {
            return 'Please provide User Name(entity branch name)';
        } else {
            if (!this.validateLength(name, 5, 255)) {
              return 'User Name should be from 3 to 255 characters long';
            } else {
              return '';
            }
        }
    }

    static validateEntity(entity, error) {
        let errors_present = false;
        error.uboErrors = []
        const nameError = this.validateLength(entity.name.new_value || entity.name.value, 8, 200);
        entity.name.error = nameError;
        nameError && error.push(nameError)

        const addressError = this.validateAddress(entity.address.address_line_1.new_value || entity.address.address_line_1.value);
        entity.address.address_line_1.error = addressError;
        addressError && error.push(addressError)

        const addressError2 = this.validateAddress(entity.address.address_line_2.new_value || entity.address.address_line_2.value);
        entity.address.address_line_2.error = addressError2;
        addressError2 && error.push(addressError2)

        const cityError = this.validateCity(entity.address.city.new_value || entity.address.city.value);
        entity.address.city.error = cityError;
        cityError && error.push(cityError)

        const addressCountryError = this.NotNull(entity.address.address_country.new_value || entity.address.address_country.value);
        entity.address.address_country.error = addressCountryError;
        addressCountryError && error.push(addressCountryError)

        const zipError = this.NotNull(entity.address.zip.new_value || entity.address.zip.value) || this.validateZip(entity.address.zip.new_value || entity.address.zip.value);
        entity.address.zip.error = zipError;
        zipError && error.push(zipError)


        const totalError = nameError + addressError + addressError2 + cityError + addressCountryError + zipError
        


        if (totalError){
            errors_present = true
        }
        for (let ubo of entity.mbd_ubo) {
            let errors = []
            errors_present = this.validateUbo(ubo, errors) || errors_present;
            if (errors.length){
                error.uboErrors.push(errors)
            }
        }
        return errors_present
    }
    static validateDataStructure(dataStructure){
        console.log('VALIDATE')
        const DataErrors = {
            ubo: [],
            mbd: [],
        }
        let errors_present = false;
        for (let ubo of dataStructure.ubos) {
            let error = []
            let errors = this.validateUbo(ubo, error); 
            if (error.length) {
                DataErrors.ubo.push(error)
            }
            errors_present = errors_present || errors;
        }
        for (let mbd of dataStructure.mbds) {
            let error = []

            let errors = this.validateMBD(mbd, error); 
            if (errors.length) {
                DataErrors.mbd.push(error)
            }

            errors_present = errors_present || errors;
        }
        console.log('DataErrors', DataErrors)
        return errors_present;
    }
    
    static validateUbo(ubo, error_tmp) {
        console.log('validateUbo', ubo);
        let errors_present = false;
        let person_error = this.validatePerson(ubo.struct.person, error_tmp);
        errors_present = errors_present || person_error;
        return errors_present;
    }

    static validateMBD(mdb, error_tmp) {
        error_tmp.person_error = []
        error_tmp.entity_error = []
        console.log('validateMBD', mdb)
        let errors_present = false;
        let entity_error = false
        let person_error = false
        if (mdb.struct.legalEntity) {
            entity_error = this.validateEntity(mdb.struct.legalEntity, error_tmp.entity_error)
        }
        if (mdb.struct.person.length) {
            let official_error = [];
            
            for (let person of mdb.struct.person) {
                person_error = this.validatePerson(person, official_error) || person_error;
                error_tmp.person_error.push(person_error);
            }
        }
        errors_present = entity_error || person_error
        return errors_present
    }

    static validatePerson(person, personErrors) {
        console.log('VALIDATING_PERSON', person, this)
        let key = null;
        try {

            console.log('validate Person')
            let errors_present = false;

            const keys_to_check = [
                'passport_file',
                'address_file',
                'secondPassport_file',
            ]

            const key_to_method = {
                'firstName': this.validateNameError.bind(this),
                'lastName': this.validateNameError.bind(this),
                'passportNumber': function(value) {
                    console.log('aaaaa', value, this)
                    const not_null = this.NotNull(value)
                    if (not_null) {
                        return not_null
                    } 
                    const error = this.validatePassportNumber(value)
                    return error
                }.bind(this),
                'tinNumber': this.validateTinNumber.bind(this),
                'passportCountry': this.NotNull.bind(this),
                'dateIssued': this.validateDateIssued.bind(this),
                'dateExpiry': this.validateDateExpiry.bind(this),
                'secondPassportType': this.NotNull.bind(this),
                'secondPassportNumber': function(value) {
                    console.log('aaaaa', value, this)
                    const not_null = this.NotNull(value)
                    if (not_null) {
                        return not_null
                    } 
                    const error = this.validatePassportNumber(value)
                    return error
                }.bind(this),
                'secondPassportCountry': this.NotNull.bind(this),
                'secondDateIssued': this.validateDateIssued.bind(this),
                'secondDateExpiry': this.validateDateExpiry.bind(this),
                'address_line_1': this.validateAddress.bind(this),
                'address_line_2': this.validateAddress.bind(this),
                'city': this.validateCity.bind(this),
                'address_country': this.NotNull.bind(this),
                'zip': function(value) {
                    const not_null = this.NotNull(value)
                    if (not_null) {
                        return not_null
                    } 
                    const error = this.validateZip(value)
                    return error
                }.bind(this),
            }
            const ignore_fields = ['file', 'title', 'new_file', 'file_error']
            for (let key of keys_to_check) {
                console.log('key', key)
                let item = person[key]

                if (key == 'secondPassport_file' && (
                    person.location_type.new_value == 'local'
                    || (person.location_type.value == 'local' && person.location_type.new_value == '')
                )) {
                    console.log('no need for second passport', key, person.location_type)
                    continue
                }

                for (let field in item) {
                    if (key_to_method[field] == undefined) {
                        continue;
                    }
                    console.log('field', field)
                    if (ignore_fields.includes(field)) {
                        continue
                    }
                   

                    const error = key_to_method[field](item[field].new_value || item[field].value);
                    item[field].error = error
                    if (error) {
                        personErrors.push(error)
                        errors_present = true
                    }
                }

                // if (!item.file && !item.new_file) {
                //     item.file_error = 'Please upload a file'
                //     personErrors.push(`Please upload a file (${item})`)
                //     errors_present = true
                // }

            }
            return errors_present
        }
        catch (error) {
            console.log('error', key, person, error)
            return true
            throw error
        }
    }

    static validateTinNumber(tinNumber) {
        if (!tinNumber) {
            return 'Please provide TIN Number';
        } else {
            if (tinNumber.toString().length != 10) {
                return 'TIN Number should be 10 characters long';
            } else if (!/^\d+$/.test(tinNumber)) {
                return 'TIN Number should contain only numbers';
            } else {
                // Add any additional validation logic here if needed
                return '';
            }
        }
    }

    static validateTitle(title) {
        if (!title) {
            return '';
        } else {
            if (!this.validateLength(title, 4, 30)) {
              return 'Title should be from 3 to 30 characters long';
            } else {
              return '';
            }
        }
    }

    static validateInputName(name, fieldName) {

        if (!name) {
            console.log('issue in top')
            return 'Please provide ' + fieldName;
        } else {
            if (!this.validateLength(name, 3, 100)) {
                console.log('issue is down')

              return fieldName + ' should be from 3 to 100 characters long';
            } else {
                console.log('all good')
              return '';
            }
        }
    }

    static validateOccupation(occupation) {
        if (!occupation) {
            return 'Please provide Occupation';
        } else {
            if (!this.validateLength(occupation, 3, 100)) {
              return 'Occupation should be from 3 to 100 characters long';
            } else {
              return '';
            }
        }
    }
    static validateState(state) {
        if (!state) {
            return '';
        } else {
            if (!this.validateLength(state, 3, 255)) {
              return 'State should be from 3 to 255 characters long';
            } else {
              return '';
            }
        }
    }
    static validatePhoneNumber(phone) { 
        if (!phone) {
            return 'Please provide Phone Number';
        } else {
                // Regular expression to match phone number without country code
                var phoneNumberRegex = /^\d{7,14}$/;
                // Check if the phone number is valid
                if (!phoneNumberRegex.test(phone)) {
                    return 'Phone number is not valid'; // Phone number format is invalid
                }
            return ''; // Both country code and phone number are valid
        }
    }
    static validateAddress(address) { 
        console.log('validate address', this, this.validateLength)
        if (!address) {
            return 'Please provide Address';
        } else {
            if (!this.validateLength(address, 15, 100)) {
              return 'Address should be from 15 to 100 characters long';
            } else {
              return '';
            }
        }
    }
    static validateTown(town) {
        if (!town) {
            return '';
        } else {
            if (!this.validateLength(town, 3, 255)) {
              return 'Town should be from 3 to 255 characters long';
            } else {
              return '';
            }
        }
    }
    static validateCity(city) {
        if (!city) {
            return 'Please provide City';
        } else {
            if (!this.validateLength(city, 3, 255)) {
              return 'City should be from 3 to 255 characters long';
            } else {
              return '';
            }
        
        }
    }
    static validateZip(zip){
        if (!zip) {
            return '';
        } else {
            if (!this.validateLength(zip, 5, 5)) {
              return 'Zip Code should be 5 characters long';
            } else {
              return '';
            }
        }
    }
    static validateIdNumber(idNumber) {
        return ID_Number_Validator.validateIdNumber(idNumber);
    }
    static validatePassportNumber(passportNumber) {
        if (!passportNumber) {
            return '';
        } else {
            if (passportNumber.length < 5) {
                return 'Passport Number should be at least 5 characters long';
            }
        }
    }
    static validatePassword(password) {
        return (password.length >= 6 && password.length <= 30);
    }
    static validateName(name) {
        return (name.length >= 3 && name.length <= 20);
    }
    static validateNameError(name) {
        console.log('validateNameError', this)
        if (!name) {
            return 'Please provide a Name';
        } else {
            if (!(name.length >= 3 && name.length <= 20)) {
                return 'Name should be from 3 to 20 characters long';
            } else {
                return '';
            }
        }
        return ''
    }
    static isValidDate(year, month, day) {

        // Check if the year is a leap year

        console.log({year, month, day})
        const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (year < 1930 || year > 2005) { 
            return 'Year should be between 1930 and 2002'
        }
        // Define the number of days in each month
        const daysInMonth = [31, (isLeapYear ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Validate month
        if (month < 1 || month > 12) {
            return 'Month out of range'
        }
        // Validate day
        if (day < 1 || day > daysInMonth[month - 1]) {
            return 'Day out of range'
        }

        return ''; // Date is valid
    }


    static getCountryList() {
        const country = {
            "AFGHANISTAN": "AF",
            "ALBANIA": "AL",
            "ALGERIA": "DZ",
            "AMERICAN SAMOA": "AS",
            "ANDORRA": "AD",
            "ANGOLA": "AO",
            "ANGUILLA": "AI",
            "ANTARCTICA": "AQ",
            "ANTIGUA AND BARBUDA": "AG",
            "ARGENTINA": "AR",
            "ARMENIA": "AM",
            "ARUBA": "AW",
            "AUSTRALIA": "AU",
            "AUSTRIA": "AT",
            "AZERBAIJAN": "AZ",
            "BAHAMAS": "BS",
            "BAHRAIN": "BH",
            "BANGLADESH": "BD",
            "BARBADOS": "BB",
            "BELARUS": "BY",
            "BELGIUM": "BE",
            "BELIZE": "BZ",
            "BENIN": "BJ",
            "BERMUDA": "BM",
            "BHUTAN": "BT",
            "BOLIVIA": "BO",
            "BONAIRE, SAINT EUSTATIUS and SABA": "BQ",
            "BOSNIA AND HERZEGOVINA": "BA",
            "BOTSWANA": "BW",
            "BOUVET ISLAND": "BV",
            "BRAZIL": "BR",
            "BRITISH INDIAN OCEAN TERRITORY": "IO",
            "BRUNEI DARUSSALAM": "BN",
            "BULGARIA": "BG",
            "BURKINA FASO": "BF",
            "BURUNDI": "BI",
            "CAMBODIA": "KH",
            "CAMEROON": "CM",
            "CANADA": "CA",
            "CAPE VERDE": "CV",
            "CAYMAN ISLANDS": "KY",
            "CENTRAL AFRICAN REPUBLIC": "CF",
            "CHAD": "TD",
            "CHILE": "CL",
            "CHINA": "CN",
            "CHRISTMAS ISLAND": "CX",
            "COCOS (KEELING) ISLANDS": "CC",
            "COLOMBIA": "CO",
            "COMOROS": "KM",
            "CONGO": "CG",
            "COOK ISLANDS": "CK",
            "COSTA RICA": "CR",
            "COTE D'IVOIRE": "CI",
            "CROATIA": "HR",
            "CUBA": "CU",
            "CURACAO": "CW",
            "CYPRUS": "CY",
            "CZECH REPUBLIC": "CZ",
            "DENMARK": "DK",
            "DJIBOUTI": "DJ",
            "DOMINICA": "DM",
            "DOMINICAN REPUBLIC": "DO",
            "ECUADOR": "EC",
            "EGYPT": "EG",
            "EL SALVADOR": "SV",
            "EQUATORIAL GUINEA": "GQ",
            "ERITREA": "ER",
            "ESTONIA": "EE",
            "ETHIOPIA": "ET",
            "FALKLAND ISLANDS (MALVINAS)": "FK",
            "FAROE ISLANDS": "FO",
            "FIJI": "FJ",
            "FINLAND": "FI",
            "FRANCE": "FR",
            "FRENCH GUIANA": "GF",
            "FRENCH POLYNESIA": "PF",
            "FRENCH SOUTHERN TERRITORIES": "TF",
            "GABON": "GA",
            "GAMBIA": "GM",
            "GEORGIA": "GE",
            "GERMANY": "DE",
            "GHANA": "GH",
            "GIBRALTAR": "GI",
            "GREECE": "GR",
            "GREENLAND": "GL",
            "GRENADA": "GD",
            "GUADELOUPE": "GP",
            "GUAM": "GU",
            "GUATEMALA": "GT",
            "GUERNSEY": "GG",
            "GUINEA": "GN",
            "GUINEA-BISSAU": "GW",
            "GUYANA": "GY",
            "HAITI": "HT",
            "HEARD ISLAND AND MCDONALD ISLANDS": "HM",
            "HOLY SEE (VATICAN CITY STATE)": "VA",
            "HONDURAS": "HN",
            "HONG KONG": "HK",
            "HUNGARY": "HU",
            "ICELAND": "IS",
            "INDIA": "IN",
            "INDONESIA": "ID",
            "IRAN, ISLAMIC REPUBLIC OF": "IR",
            "IRAQ": "IQ",
            "IRELAND": "IE",
            "ISLE OF MAN": "IM",
            "ISRAEL": "IL",
            "ITALY": "IT",
            "JAMAICA": "JM",
            "JAPAN": "JP",
            "JERSEY": "JE",
            "JORDAN": "JO",
            "KAZAKHSTAN": "KZ",
            "KENYA": "KE",
            "KIRIBATI": "KI",
            "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF": "KP",
            "KOREA, REPUBLIC OF": "KR",
            "KOSOVO": "XK",
            "KOSOVO (Old - KS)": "KS",
            "KUWAIT": "KW",
            "KYRGYZSTAN": "KG",
            "LAO PEOPLE'S DEMOCRATIC REPUBLIC": "LA",
            "LATVIA": "LV",
            "LEBANON": "LB",
            "LESOTHO": "LS",
            "LIBERIA": "LR",
            "LIBYA": "LY",
            "LIECHTENSTEIN": "LI",
            "LITHUANIA": "LT",
            "LUXEMBOURG": "LU",
            "MACAO": "MO",
            "MADAGASCAR": "MG",
            "MALAWI": "MW",
            "MALAYSIA": "MY",
            "MALDIVES": "MV",
            "MALI": "ML",
            "MALTA": "MT",
            "MARSHALL ISLANDS": "MH",
            "MARTINIQUE": "MQ",
            "MAURITANIA": "MR",
            "MAURITIUS": "MU",
            "MAYOTTE": "YT",
            "MEXICO": "MX",
            "MICRONESIA, FEDERATED STATES OF": "FM",
            "MOLDOVA, REPUBLIC OF": "MD",
            "MONACO": "MC",
            "MONGOLIA": "MN",
            "Montenegro": "ME",
            "MONTSERRAT": "MS",
            "MOROCCO": "MA",
            "MOZAMBIQUE": "MZ",
            "MYANMAR": "MM",
            "NAMIBIA": "NA",
            "NAURU": "NR",
            "NEPAL": "NP",
            "NETHERLANDS": "NL",
            "NEW CALEDONIA": "NC",
            "NEW ZEALAND": "NZ",
            "NICARAGUA": "NI",
            "NIGER": "NE",
            "NIGERIA": "NG",
            "NIUE": "NU",
            "NORFOLK ISLAND": "NF",
            "NORTHERN MARIANA ISLANDS": "MP",
            "NORWAY": "NO",
            "OMAN": "OM",
            "PAKISTAN": "PK",
            "PALAU": "PW",
            "PANAMA": "PA",
            "PAPUA NEW GUINEA": "PG",
            "PARAGUAY": "PY",
            "PERU": "PE",
            "PHILIPPINES": "PH",
            "PITCAIRN": "PN",
            "POLAND": "PL",
            "PORTUGAL": "PT",
            "PUERTO RICO": "PR",
            "QATAR": "QA",
            "REUNION": "RE",
            "ROMANIA": "RO",
            "RUSSIAN FEDERATION": "RU",
            "RWANDA": "RW",
            "SAINT BARTHÉLEMY": "BL",
            "SAINT HELENA": "SH",
            "SAINT KITTS AND NEVIS": "KN",
            "SAINT LUCIA": "LC",
            "SAINT MARTIN (FRENCH PART)": "MF",
            "SAINT PIERRE AND MIQUELON": "PM",
            "SAINT VINCENT AND THE GRENADINES": "VC",
            "SAMOA": "WS",
            "SAN MARINO": "SM",
            "SAO TOME AND PRINCIPE": "ST",
            "SAUDI ARABIA": "SA",
            "SENEGAL": "SN",
            "SERBIA": "RS",
            "SEYCHELLES": "SC",
            "SIERRA LEONE": "SL",
            "SINGAPORE": "SG",
            "SINT MAARTEN (Dutch part)": "SX",
            "SLOVAKIA": "SK",
            "SLOVENIA": "SI",
            "SOLOMON ISLANDS": "SB",
            "SOMALIA": "SO",
            "SOUTH AFRICA": "ZA",
            "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS": "GS",
            "SOUTH SUDAN": "SS",
            "SPAIN": "ES",
            "SRI LANKA": "LK",
            "STATE OF PALESTINE": "PS",
            "SUDAN": "SD",
            "SURINAME": "SR",
            "SVALBARD AND JAN MAYEN": "SJ",
            "SWEDEN": "SE",
            "SWITZERLAND": "CH",
            "SYRIAN ARAB REPUBLIC": "SY",
            "TAIWAN, PROVINCE OF CHINA": "TW",
            "TAJIKISTAN": "TJ",
            "TANZANIA, UNITED REPUBLIC OF": "TZ",
            "THAILAND": "TH",
            "TIMOR-LESTE": "TL",
            "TOGO": "TG",
            "TOKELAU": "TK",
            "TONGA": "TO",
            "TRINIDAD AND TOBAGO": "TT",
            "TUNISIA": "TN",
            "TURKEY": "TR",
            "TURKMENISTAN": "TM",
            "TURKS AND CAICOS ISLANDS": "TC",
            "TUVALU": "TV",
            "UGANDA": "UG",
            "UKRAINE": "UA",
            "UNITED ARAB EMIRATES": "AE",
            "UNITED KINGDOM": "GB",
            "UNITED STATES": "US",
            "UNITED STATES MINOR OUTLYING ISLANDS": "UM",
            "URUGUAY": "UY",
            "UZBEKISTAN": "UZ",
            "VANUATU": "VU",
            "VENEZUELA": "VE",
            "VIET NAM": "VN",
            "VIRGIN ISLANDS, BRITISH": "VG",
            "VIRGIN ISLANDS, U.S.": "VI",
            "WALLIS AND FUTUNA": "WF",
            "WESTERN SAHARA": "EH",
            "YEMEN": "YE",
            "ZAMBIA": "ZM",
            "ZIMBABWE": "ZW"
        }

        return Object.keys(country).map((key) => {
            return { text: key, value: key }
        });
    }
    static getCountryPrefixes() {
        const countryPrefixes = [
            { country: 'Afghanistan', prefix: '+93' },
            { country: 'Albania', prefix: '+355' },
            { country: 'Algeria', prefix: '+213' },
            { country: 'American Samoa', prefix: '+1684' },
            { country: 'Andorra', prefix: '+376' },
            { country: 'Angola', prefix: '+244' },
            { country: 'Anguilla', prefix: '+1264' },
            { country: 'Antarctica', prefix: '+672' },
            { country: 'Antigua and Barbuda', prefix: '+1268' },
            { country: 'Argentina', prefix: '+54' },
            { country: 'Armenia', prefix: '+374' },
            { country: 'Aruba', prefix: '+297' },
            { country: 'Australia', prefix: '+61' },
            { country: 'Austria', prefix: '+43' },
            { country: 'Azerbaijan', prefix: '+994' },
            { country: 'Bahamas', prefix: '+1242' },
            { country: 'Bahrain', prefix: '+973' },
            { country: 'Bangladesh', prefix: '+880' },
            { country: 'Barbados', prefix: '+1246' },
            { country: 'Belarus', prefix: '+375' },
            { country: 'Belgium', prefix: '+32' },
            { country: 'Belize', prefix: '+501' },
            { country: 'Benin', prefix: '+229' },
            { country: 'Bermuda', prefix: '+1441' },
            { country: 'Bhutan', prefix: '+975' },
            { country: 'Bolivia', prefix: '+591' },
            { country: 'Bosnia and Herzegovina', prefix: '+387' },
            { country: 'Botswana', prefix: '+267' },
            { country: 'Brazil', prefix: '+55' },
            { country: 'British Indian Ocean Territory', prefix: '+246' },
            { country: 'British Virgin Islands', prefix: '+1284' },
            { country: 'Brunei', prefix: '+673' },
            { country: 'Bulgaria', prefix: '+359' },
            { country: 'Burkina Faso', prefix: '+226' },
            { country: 'Burundi', prefix: '+257' },
            { country: 'Cambodia', prefix: '+855' },
            { country: 'Cameroon', prefix: '+237' },
            { country: 'Canada', prefix: '+1' },
            { country: 'Cape Verde', prefix: '+238' },
            { country: 'Cayman Islands', prefix: '+1345' },
            { country: 'Central African Republic', prefix: '+236' },
            { country: 'Chad', prefix: '+235' },
            { country: 'Chile', prefix: '+56' },
            { country: 'China', prefix: '+86' },
            { country: 'Christmas Island', prefix: '+61' },
            { country: 'Cocos Islands', prefix: '+61' },
            { country: 'Colombia', prefix: '+57' },
            { country: 'Comoros', prefix: '+269' },
            { country: 'Cook Islands', prefix: '+682' },
            { country: 'Costa Rica', prefix: '+506' },
            { country: 'Croatia', prefix: '+385' },
            { country: 'Cuba', prefix: '+53' },
            { country: 'Curacao', prefix: '+5999' },
            { country: 'Cyprus', prefix: '+357' },
            { country: 'Czech Republic', prefix: '+420' },
            { country: 'Democratic Republic of the Congo', prefix: '+243' },
            { country: 'Denmark', prefix: '+45' },
            { country: 'Djibouti', prefix: '+253' },
            { country: 'Dominica', prefix: '+1767' },
            { country: 'Dominican Republic', prefix: '+1809' },
            { country: 'Dominican Republic', prefix: '+1829' },
            { country: 'Dominican Republic', prefix: '+1849' },
            { country: 'East Timor', prefix: '+670' },
            { country: 'Ecuador', prefix: '+593' },
            { country: 'Egypt', prefix: '+20' },
            { country: 'El Salvador', prefix: '+503' },
            { country: 'Equatorial Guinea', prefix: '+240' },
            { country: 'Eritrea', prefix: '+291' },
            { country: 'Estonia', prefix: '+372' },
            { country: 'Ethiopia', prefix: '+251' },
            { country: 'Falkland Islands', prefix: '+500' },
            { country: 'Faroe Islands', prefix: '+298' },
            { country: 'Fiji', prefix: '+679' },
            { country: 'Finland', prefix: '+358' },
            { country: 'France', prefix: '+33' },
            { country: 'French Polynesia', prefix: '+689' },
            { country: 'Gabon', prefix: '+241' },
            { country: 'Gambia', prefix: '+220' },
            { country: 'Georgia', prefix: '+995' },
            { country: 'Germany', prefix: '+49' },
            { country: 'Ghana', prefix: '+233' },
            { country: 'Gibraltar', prefix: '+350' },
            { country: 'Greece', prefix: '+30' },
            { country: 'Greenland', prefix: '+299' },
            { country: 'Grenada', prefix: '+1473' },
            { country: 'Guam', prefix: '+1671' },
            { country: 'Guatemala', prefix: '+502' },
            { country: 'Guernsey', prefix: '+44-1481' },
            { country: 'Guinea', prefix: '+224' },
            { country: 'Guinea-Bissau', prefix: '+245' },
            { country: 'Guyana', prefix: '+592' },
            { country: 'Haiti', prefix: '+509' },
            { country: 'Honduras', prefix: '+504' },
            { country: 'Hong Kong', prefix: '+852' },
            { country: 'Hungary', prefix: '+36' },
            { country: 'Iceland', prefix: '+354' },
            { country: 'India', prefix: '+91' },
            { country: 'Indonesia', prefix: '+62' },
            { country: 'Iran', prefix: '+98' },
            { country: 'Iraq', prefix: '+964' },
            { country: 'Ireland', prefix: '+353' },
            { country: 'Isle of Man', prefix: '+44-1624' },
            { country: 'Israel', prefix: '+972' },
            { country: 'Italy', prefix: '+39' },
            { country: 'Ivory Coast', prefix: '+225' },
            { country: 'Jamaica', prefix: '+1876' },
            { country: 'Japan', prefix: '+81' },
            { country: 'Jersey', prefix: '+44-1534' },
            { country: 'Jordan', prefix: '+962' },
            { country: 'Kazakhstan', prefix: '+7' },
            { country: 'Kenya', prefix: '+254' },
            { country: 'Kiribati', prefix: '+686' },
            { country: 'Kosovo', prefix: '+383' },
            { country: 'Kuwait', prefix: '+965' },
            { country: 'Kyrgyzstan', prefix: '+996' },
            { country: 'Laos', prefix: '+856' },
            { country: 'Latvia', prefix: '+371' },
            { country: 'Lebanon', prefix: '+961' },
            { country: 'Lesotho', prefix: '+266' },
            { country: 'Liberia', prefix: '+231' },
            { country: 'Libya', prefix: '+218' },
            { country: 'Liechtenstein', prefix: '+423' },
            { country: 'Lithuania', prefix: '+370' },
            { country: 'Luxembourg', prefix: '+352' },
            { country: 'Macau', prefix: '+853' },
            { country: 'Macedonia', prefix: '+389' },
            { country: 'Madagascar', prefix: '+261' },
            { country: 'Malawi', prefix: '+265' },
            { country: 'Malaysia', prefix: '+60' },
            { country: 'Maldives', prefix: '+960' },
            { country: 'Mali', prefix: '+223' },
            { country: 'Malta', prefix: '+356' },
            { country: 'Marshall Islands', prefix: '+692' },
            { country: 'Mauritania', prefix: '+222' },
            { country: 'Mauritius', prefix: '+230' },
            { country: 'Mayotte', prefix: '+262' },
            { country: 'Mexico', prefix: '+52' },
            { country: 'Micronesia', prefix: '+691' },
            { country: 'Moldova', prefix: '+373' },
            { country: 'Monaco', prefix: '+377' },
            { country: 'Mongolia', prefix: '+976' },
            { country: 'Montenegro', prefix: '+382' },
            { country: 'Montserrat', prefix: '+1664' },
            { country: 'Morocco', prefix: '+212' },
            { country: 'Mozambique', prefix: '+258' },
            { country: 'Myanmar', prefix: '+95' },
            { country: 'Namibia', prefix: '+264' },
            { country: 'Nauru', prefix: '+674' },
            { country: 'Nepal', prefix: '+977' },
            { country: 'Netherlands', prefix: '+31' },
            { country: 'Netherlands Antilles', prefix: '+599' },
            { country: 'New Caledonia', prefix: '+687' },
            { country: 'New Zealand', prefix: '+64' },
            { country: 'Nicaragua', prefix: '+505' },
            { country: 'Niger', prefix: '+227' },
            { country: 'Nigeria', prefix: '+234' },
            { country: 'Niue', prefix: '+683' },
            { country: 'Norfolk Island', prefix: '+672' },
            { country: 'North Korea', prefix: '+850' },
            { country: 'Northern Mariana Islands', prefix: '+1670' },
            { country: 'Norway', prefix: '+47' },
            { country: 'Oman', prefix: '+968' },
            { country: 'Pakistan', prefix: '+92' },
            { country: 'Palau', prefix: '+680' },
            { country: 'Palestine', prefix: '+970' },
            { country: 'Panama', prefix: '+507' },
            { country: 'Papua New Guinea', prefix: '+675' },
            { country: 'Paraguay', prefix: '+595' },
            { country: 'Peru', prefix: '+51' },
            { country: 'Philippines', prefix: '+63' },
            { country: 'Pitcairn', prefix: '+64' },
            { country: 'Poland', prefix: '+48' },
            { country: 'Portugal', prefix: '+351' },
            { country: 'Puerto Rico', prefix: '+1787' },
            { country: 'Puerto Rico', prefix: '+1939' },
            { country: 'Qatar', prefix: '+974' },
            { country: 'Republic of the Congo', prefix: '+242' },
            { country: 'Reunion', prefix: '+262' },
            { country: 'Romania', prefix: '+40' },
            { country: 'Russia', prefix: '+7' },
            { country: 'Rwanda', prefix: '+250' },
            { country: 'Saint Barthelemy', prefix: '+590' },
            { country: 'Saint Helena', prefix: '+290' },
            { country: 'Saint Kitts and Nevis', prefix: '+1869' },
            { country: 'Saint Lucia', prefix: '+1758' },
            { country: 'Saint Martin', prefix: '+590' },
            { country: 'Saint Pierre and Miquelon', prefix: '+508' },
            { country: 'Saint Vincent and the Grenadines', prefix: '+1784' },
            { country: 'Samoa', prefix: '+685' },
            { country: 'San Marino', prefix: '+378' },
            { country: 'Sao Tome and Principe', prefix: '+239' },
            { country: 'Saudi Arabia', prefix: '+966' },
            { country: 'Senegal', prefix: '+221' },
            { country: 'Serbia', prefix: '+381' },
            { country: 'Seychelles', prefix: '+248' },
            { country: 'Sierra Leone', prefix: '+232' },
            { country: 'Singapore', prefix: '+65' },
            { country: 'Sint Maarten', prefix: '+1721' },
            { country: 'Slovakia', prefix: '+421' },
            { country: 'Slovenia', prefix: '+386' },
            { country: 'Solomon Islands', prefix: '+677' },
            { country: 'Somalia', prefix: '+252' },
            { country: 'South Africa', prefix: '+27' },
            { country: 'South Korea', prefix: '+82' },
            { country: 'South Sudan', prefix: '+211' },
            { country: 'Spain', prefix: '+34' },
            { country: 'Sri Lanka', prefix: '+94' },
            { country: 'Sudan', prefix: '+249' },
            { country: 'Suriname', prefix: '+597' },
            { country: 'Svalbard and Jan Mayen', prefix: '+47' },
            { country: 'Swaziland', prefix: '+268' },
            { country: 'Sweden', prefix: '+46' },
            { country: 'Switzerland', prefix: '+41' },
            { country: 'Syria', prefix: '+963' },
            { country: 'Taiwan', prefix: '+886' },
            { country: 'Tajikistan', prefix: '+992' },
            { country: 'Tanzania', prefix: '+255' },
            { country: 'Thailand', prefix: '+66' },
            { country: 'Togo', prefix: '+228' },
            { country: 'Tokelau', prefix: '+690' },
            { country: 'Tonga', prefix: '+676' },
            { country: 'Trinidad and Tobago', prefix: '+1868' },
            { country: 'Tunisia', prefix: '+216' },
            { country: 'Turkey', prefix: '+90' },
            { country: 'Turkmenistan', prefix: '+993' },
            { country: 'Turks and Caicos Islands', prefix: '+1649' },
            { country: 'Tuvalu', prefix: '+688' },
            { country: 'U.S. Virgin Islands', prefix: '+1340' },
            { country: 'Uganda', prefix: '+256' },
            { country: 'Ukraine', prefix: '+380' },
            { country: 'United Arab Emirates', prefix: '+971' },
            { country: 'United Kingdom', prefix: '+44' },
            { country: 'United States', prefix: '+1' },
            { country: 'Uruguay', prefix: '+598' },
            { country: 'Uzbekistan', prefix: '+998' },
            { country: 'Vanuatu', prefix: '+678' },
            { country: 'Vatican', prefix: '+379' },
            { country: 'Venezuela', prefix: '+58' },
            { country: 'Vietnam', prefix: '+84' },
            { country: 'Wallis and Futuna', prefix: '+681' },
            { country: 'Western Sahara', prefix: '+212' },
            { country: 'Yemen', prefix: '+967' },
            { country: 'Zambia', prefix: '+260' },
            { country: 'Zimbabwe', prefix: '+263' }
        ];
        const mapped = countryPrefixes.map((item) => {
            let text = item.country + ' (' + item.prefix + ')';
            return { text: text, value: text }
        })
        return mapped;
    }
}

export default Utils;