import axios from 'axios';
const API_URL = process.env.VUE_APP_API_BASE + '/api/v1/';

import store from '../store';
const instance = axios.create({
    // baseURL: 'http://your-api-base-url.com', // Replace with your API base URL
});

const setAuthToken = (token) => {
    instance.interceptors.request.use((config) => {
        // Add your custom headers here
        config.headers['Authorization'] = token; // Replace with your actual JWT token
      
        return config;
    });
}

if (localStorage.getItem('token')) {
    setAuthToken(localStorage.getItem('token'));
} else {
    store.dispatch('setValue', {key: 'loaded', value: true});
}

const saveUserChanges = async (user) => {
    try {
        const response = await instance.put(`${API_URL}save-user-changes`, user);
        if (response.data.error) {
            store.dispatch('addNotification', {type: 'error', text: response.data.error});
            return response;
        } else if (response.status == 200){
            store.dispatch('addNotification', {type: 'success', text: 'User changes applied successfully'});
        }
        // store.dispatch('setValue', {key: 'user', value: response.data});

        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error applying user changes'});
        throw new Error(error);
    }
}

const getFilesList = async () => {
    try {
        const response = await instance.get(`${API_URL}get-files-list`);
        store.dispatch('setValue', {key: 'fileList', value: response.data});
        console.log('response', response);

        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error loading user files list'});
        store
        throw new Error(error);
    }
}
const getUserBalance = async () => {
    try {
        const response = await instance.get(`${API_URL}get-balance`);
        store.dispatch('setValue', {key: 'credits_balance', value: response.data.credits_balance});
        console.log('response', response);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error updating user balance'});
        throw new Error(error);
    }
}

const updateSavedData = async (data) => {
    let response = null
    try {
        response = await instance.post(`${API_URL}save-saved-info`, data).catch((error) => {
            console.log('error', error);
            console.log('error.response', error.response)
            console.log('error.response.status', error.response.status)
            if (error.response.status == 400) {
                store.dispatch('addNotification', {type: 'error', text: 'Error updating user information'});
                return {error: error.response.data.error};
            }
        });
        console.log('response', response);
        if (response && response.status == 200) {
            store.dispatch('addNotification', {type: 'success', text: 'Information updated successfully'});
        }
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error updating user information'});
        
        throw new Error(error);
    }
}

const getSavedInfo = async () => {
    try {
        const response = await instance.get(`${API_URL}get-saved-info`);
        console.log('response', response);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error updating user balance'});
        throw new Error(error);
    }
}


const requestWallet = async () => {
    console.log('requestWallet');
    try {
        const response = await instance.post(`${API_URL}allocate-wallet`);
        store.dispatch('setValue', {key: 'wallet', value: {loaded: true, address: response.data.address}});
        console.log('response', response);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error requesting wallet'});
        throw new Error(error);
    }

}

const getUser = async (id = '') => {
    let response = null;
    try {
        response = await instance.get(`${API_URL}get-user?id=${id}`).catch((error) => {
            console.log('error', error);
            if (error.response.status >= 400 && error.response.status < 500) {
                store.dispatch('setValue', {key: 'loaded', value: true});
                if (error.response.status == 500) {
                    store.dispatch('addNotification', {type: 'error', text: 'Error loading user data\n, Plese contact support'});
                }
                
                if (!id) {
                    localStorage.removeItem('token');
                    setAuthToken(null);
                }
                return;
            }
        });
        if (response) {
            if (id) {
                return {
                    ...response.data,
                }
            } else {
                console.log('response', response);
                store.dispatch('setValue', {key: 'user', value: response.data.user});
                store.dispatch('setValue', {key: 'credits_balance', value: response.data.credits_balance});
                store.dispatch('setValue', {key: 'userAddress', value: response.data.userAddress});
                store.dispatch('setValue', {key: 'userPhone', value: response.data.userPhone});
                store.dispatch('setValue', {key: 'userInformation', value: response.data.userInformation});
                store.dispatch('setValue', { 
                    key: 'wallet', value: { 
                        loaded: true, 
                        address: response.data.wallet 
                    }
                });
                store.dispatch('setValue', {key: 'loaded', value: true});
            }
        }
        return response;
    } catch (error) {
        console.log('response', response)
        store.dispatch('addNotification', {type: 'error', text: 'Error loading user data'});
        throw new Error(error);
    }
}

const register = async ({
    password,
    username, 
    email
}) => {
    try {
        let error_ = null;
        const response = await instance.post(`${API_URL}register`, {
            password,
            username,
            email
        }).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
        });
        if (error_) {
            return error_;
        }
        
        setAuthToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        window.location.reload();
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during user registration.\nPlease try again or contact support'});
        throw new Error(error);
    }
};

const verifyToken = async (token) => { 
    try {
        const response = await instance.post(`${API_URL}verify-token`, {
            token
        }).catch((error) => {
            if (error.response.status == 400) {
                return {};
            }
        })
        return response;
    }
    catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during token verification.\nPlease try again or contact support'});
        throw new Error(error);
    }
}

const sendVerificationEmail = async (email) => {
    try {
        let error_ = null;
        const response = await instance.post(`${API_URL}send-verification-email`, {
            email
        }).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
        });
        if (error_) {
            return error_;
        }
        if (response.status == 200) {
            store.dispatch('addNotification', {type: 'success', text: 'Verification email sent successfully'});
        }
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error sending verification emaiil.\nPlease try again or contact support'});
        throw new Error(error);
    }

}


const login = async ({
    password,
    email
}) => {
    console.log('login')
    try {
        let error_ = null;
        const response = await instance.post(`${API_URL}login`, {
            password,
            email
        }).catch((error) => {
            store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
        });

        if (error_) {
            return error_;
        }
        
        setAuthToken(response.data.token);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
        throw new Error(error);
    }
};

const uploadFile = async (file) => {
    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        let response_info = null
        fetch(`${API_URL}upload-file`, {
            method: 'POST',
            body: formData,
            headers
        })
        .then(response => {
            response_info = response
            return response.json()
        })
        .then(data => {

            resolve(data); 
            if (response_info.status == 500) {
                throw Error('Failed to upload the file')
            }
            console.log('File uploaded successfully:', data, response_info.status);
        })
        .catch(error => {
            console.error('Error uploading file:', error);
            store.dispatch('addNotification', {type: 'error', text: 'There was an error uploading the file.\nPlease try again or contact the support'});
            reject(error);
        });
    })
}


const downloadFile = async (fileId,selectedFile) => {

    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    fetch(`${API_URL}get-file/?id=${fileId}`,{
        method: 'GET',
        headers: headers,
    })
    .then(response => response.blob())
    .then(async (blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        let file_name = selectedFile.name.split('.')
        file_name.pop()
        file_name = file_name.join('')

        link.setAttribute('download', `${file_name}.${fileId == 'sample' ? 'xlsx' : 'zip'}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        await getUserBalance()
    })
    .catch(error => {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
        console.error('Error downloading file:', error);
    });
}


const getKYCFilesList = async () => {
    try {
        let error_ = null;
        
        const response = await instance.get(`${API_URL}get-kyc-files-info`).catch((error) => {
            store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
        });

        if (error_) {
            return error_;
        }
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
        throw new Error(error);
    }

}


const uploadKYCFile = async (file, metadata) => {
    console.log('uploadKYCFile, file', file)
    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        for (let key in metadata) {
            formData.append(key, metadata[key]);
        }
        let response_info = null
        fetch(`${API_URL}upload-kyc-file`, {
            method: 'POST',
            body: formData,
            headers
        })
        .then(response => {
            response_info = response
            return response.json()
        })
        .then(data => {

            resolve(data); 
            if (response_info.status == 500) {
                throw Error('Failed to upload the file')
            }
            console.log('File uploaded successfully:', data, response_info.status);
        })
        .catch(error => {
            console.error('Error uploading file:', error);
            store.dispatch('addNotification', {type: 'error', text: 'There was an error uploading the file.\nPlease try again or contact the support'});
            reject(error);
        });
    })
}

async function DownloadKYCFile(id, name) {
    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    fetch(`${API_URL}get-kyc-file/?id=${id}`,{
        method: 'GET',
        headers: headers,
    })
    .then(response => response.blob())
    .then(async (blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // await getUserBalance()
    })
    .catch(error => {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
        console.error('Error downloading file:', error);
    });
}
async function deleteObject(object_id, type, objectName) {
    console.log('delete ubo', object_id)
    let error_ = null;

    const response = await instance.post(`${API_URL}delete-object`, {
        id: object_id,
        type,
    }).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error during ${objectName} deletion.\nPlease try again or contact the support`});
    });
    console.log('response', response)
    if (response.data.status != 'success') {
        store.dispatch('addNotification', {type: 'error', text: `There was an error during ${objectName} deletion.\nPlease try again or contact the support`});
        return false;
    }
    if (error_) {
        return false;
    }
    return true
}



async function uploadComment(content) {
    console.log('uploadComment')
    let error_ = null;

    const response = await instance.post(`${API_URL}comment-upload`, {comment_structure: content}).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
    });
    console.log('response', response)
    if (response && response.data.status != 'success') {
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
        return false;
    }
    if (error_) {
        return false;
    }
    return true




}



async function uploadSettings(settings) {
    console.log('uploadSettings')
    let error_ = null;

    const response = await instance.post(`${API_URL}upload-settings`, settings).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
    });
    console.log('response', response)
    if (response.data.status != 'success') {
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
        return false;
    }
    if (error_) {
        return false;
    }
    return true
}
async function forgotPassword(email) {
    let error_ = null;

    const response = await instance.post(`${API_URL}user/forgot-password-request`, {
        email: email
    }).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error sending email with reset link.\nPlease try again or contact the support`});
    })
    if (response && response.data && response.data.timeout) {
        store.dispatch('addNotification', {type: 'error', text: `You can send new email in ${Math.floor(response.data.timeout)} seconds`});
        return null
    }
    return response.data;
}

async function resetPassword(data) {
    let error_ = null;
    const response = await instance.post(`${API_URL}user/forgot-password-response`, data).catch((error) => {
        return error.response
    })
    return response;
}

async function getUserAccounts() {
    console.log('getUserAccounts')
    let error_ = null;
    const response = await instance.get(`${API_URL}user/accounts`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading users of the company.\nPlease try again or contact the support`});
    })
    return response.data;
}
async function createNewUser(user){ 
    const response = await instance.post(`${API_URL}user/account/create`, user).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error creating user for the company.\nPlease try again or contact the support`});
    })
    return response.data;
}

async function getNotificationList(page = 1 ) {
    const offset = (page - 1) * 50;
    const limit = 50;
    const response = await instance.get(`${API_URL}notifications?offset=${offset}&limit=${limit}`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading notifications.\nPlease try again or contact the support`});
    })
    return response.data;
}

async function markNotificationAsRead(notification_id) {
    const response = await instance.post(`${API_URL}notifications/read`, {notification_id}).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error updating notifications.\nPlease try again or contact the support`});
    })
    return response.data;
}


async function getNotificationCount() {
    const response = await instance.get(`${API_URL}notifications/count`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading notifications.\nPlease try again or contact the support`});
        return null
    })
    store.dispatch('setValue', {key: 'notificationsCount', value: response.data.count});
    console.log('response COUNT', response)

    return response.data;
}

export default {
    login,
    register,
    getUser,
    deleteObject,
    uploadFile,
    downloadFile,
    DownloadKYCFile,
    saveUserChanges,
    requestWallet,
    getUserBalance,
    createNewUser,
    getNotificationList,
    instance,
    getUserAccounts,
    getSavedInfo,
    updateSavedData,
    getFilesList,
    sendVerificationEmail,
    verifyToken,
    uploadComment,
    uploadKYCFile,
    markNotificationAsRead,
    getKYCFilesList,
    uploadSettings,
    getNotificationCount,
    forgotPassword,
    resetPassword,
};
