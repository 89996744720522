<template>
    <div class="file-list-section">
        <div class="file-list-container">
            <div class="file-item tb-header">
                <div class="file-date">Upload Date</div>
                <div class="file-name">File name</div>
                <div class="file-size">File Size</div>
                <div class="file-rows">Rows</div>
                <div class="file-fee">Fee</div>
                <div class="file-fee-comment">Comment</div>
                <div class="file-download">Action</div>
            
            </div>
            <div class="file-item" v-for="(file, index) in fileList">
                <div class="file-date">{{ formatDate(file.uploadDate) }}</div>
                <div class="file-name">{{ file.filename }}</div>
                <div class="file-size">{{ formatFileSize(file.filesize) }}</div>
                <div class="file-rows">{{ file.rows }}</div>
                <div class="file-fee">{{ file.conversionFee && file.conversionFee.toFixed(2) }}</div>
                <div class="file-fee-comment">{{ file.conversionFeeComment }}</div>

                <div class="file-download">
                    <button @click="downloadFile(file.serverName, {'name': file.filename})">Download</button>
                </div>
            </div>
            <div class='no-data-present' v-if="fileList && fileList.length == 0">
                Nothing here yet
            </div>
        </div>
      
    </div>
  </template>
  
  <script>
    import { mapState } from 'vuex';
    import API from '@/api/index.js'
    import Utils from '@/utils';
    export default {
    data() {
      return {
      };
    },
    async mounted() {
        await API.getFilesList();
    },
    computed: {
        ...mapState(['fileList']),
    },
    methods: {
        formatDate(date) {
          const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
          return new Date(date).toLocaleDateString('en-GB', options);
        },
        formatFileSize(bytes) {
          return Utils.calculateFileSize(bytes)
        },
        downloadFile(serverName, filename) {
          API.downloadFile(serverName, filename);
        },
    },
  };
  </script>
  
  
  
  <style scoped lang="scss">
  
  .about-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: white;
  
  }
  
  .drop-area {
    border: 2px dashed #ccc;
    padding: 40px 20px;
  
    text-align: center;
    cursor: pointer;
  }
  .about {
    color: white;
  }
  .drag-over {
    border-color: #2196F3;
  }
  .button-holder {
    width: 150px;
    margin: 25px auto;
    height: 30px;
      button {
        height: 30px;
      }
  }
  .error-container {
    height: 50vh;
    background-color: #f8d7da; /* Light red background color */
    color: #721c24; /* Dark red text color */
    border: 1px solid #f5c6cb; /* Border color */
    padding: 15px;
    overflow-y: auto;
    margin: 10px 0;
    border-radius: 5px;
    text-align: left;
  }
  .error-message {
    font-weight: bold;
  }
  .error-header {
    margin-top: 0;
  }
  .file-list-section {
    height: calc(100vh - 60px);
    width: 100%;
    overflow-y: auto;
  }
  .file-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #2b263a;
    * {
        text-align: left;
    }
    &.tb-header {
        * {
            font-weight: bold;
        }
    }
  }
  .file-list-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #091440;
  }
  .file-name {
    width: 330px;
  }
  .file-size {
    width: 100px;
  }
  .file-date {
    width: 200px;
  }
  .file-fee-comment {
    width: 280px;
  }
  .file-fee {
    width: 80px;
  }
  .file-rows {
    width: 70px;
  }
  .file-download {
    width: 100px;
    button {
        height: 20px;
        text-align: center;
    }
  }
  .no-data-present {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    height: calc(100vh - 120px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  </style>