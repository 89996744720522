<template>
  <div class="register">
    <div class="register-wrap">
      <div class="title">
        
        <text v-if="state == 'login'">AMLLINK tool</text>
        <text v-else>Reset Password</text>
      
      </div>
      <form @submit.prevent="saveData" v-if="state == 'login'">
        <input type="email" :class="{error: emailError}" v-model="email" placeholder="Email" @input="validateEmailField">
        <span class='error-item' v-if="emailError">{{emailError}}</span>
        <input type="password" :class="{error: passwordError}" v-model="password" placeholder="Password" @input="validatePasswordField">
        <span class='error-item' v-if="passwordError">{{passwordError}}</span>

        <button class='button' type="submit" @click="login()">Login</button>
        <div class='forgot-password' @click="stateChange('forgot')">Forgot Password?</div>
      </form>
      <form @submit.prevent="sendEmail" v-else>
        <input type="email" :class="{error: emailError}" v-model="email" placeholder="Email" @input="validateEmailField">
        <div class="flex flex-row gap-10">
        <button class='button' @click="stateChange('login')">Back To Login</button>
        <button class='button' type="submit">Send</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>


import API from '../api/index.js'
import { mapState } from 'vuex';
import Utils from '../utils/index.js'
import store from '../store/index.js'
export default {
  name: 'RegisterVue',
  components: {

  },
  data() {
    return {
      // Define your local data properties here
      username: '',
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      state: 'login',
    }
  },
  methods: {
    validateEmailField() {
      if (this.emailError) {
        if (Utils.validateEmail(this.email)) {
          this.emailError = false;
        }
      }
    },
    validatePasswordField() {
      if (this.passwordError) {
        if (Utils.validatePassword(this.password)) {
          this.passwordError = false;
        }
      }
    },
    async sendEmail(){
      let resp = await API.forgotPassword(this.email)
      if (resp) {
        store.dispatch('addNotification', {
          text: 'Reset Password email has been sent to your email',
          type: 'success'
        })
      }
    },
    stateChange(state) {
      console.log('state', state)
      this.state = state
    },
    async login() {
      let error = false
      if (!Utils.validateEmail(this.email)) {
        this.emailError = 'Invalid email';
        error = true;
      }
      if (!Utils.validatePassword(this.password)) {
        this.passwordError = 'Password must be from 6 to 30 characters long';
        error = true;
      }
     
      if (error) {
        return;
      }
      let resp = await API.login({
        email: this.email,
        password: this.password
      })
      if (resp.error) {
        this.emailError = resp.error;
        return
      }
      localStorage.setItem('token', resp.data.token);
      window.location.reload();
    },
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.$router.push('/user-info')
    }
  },

  computed: {
    ...mapState(['user']),
  },

}
</script>

<style scoped lang="scss">


  .title {
    margin-bottom: 20px;
  }
  .error-item {
    color: red;
    margin: 0;
    height: fit-content;
    text-align: left;
    font-size: 9px;
    margin: 0;
    margin-left: -15px;
    margin-top: -5px;
  }
  .register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: #38314e;
    font-size: 23px;
    font-weight: bold;
    text-align: left;


  }
  .register-wrap {
    background-color: white;
    padding: 30px;
    margin-top: -60px;
    border-radius: 5px;

  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 265px;
    > * {
      margin: 10px;
      width: 100%;
      height: 35px;
      font-size: 15px;
    }
    input {
      border: 1px solid #2b263a;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-radius: 0;
      outline: none;
      caret-color: #2b263a;
      color: #38314e;
      background-color: white;
      padding: 3px 10px;
      box-sizing: border-box;
      &.error {
        border: 1px solid red;
      }
    }
    button {
      height: fit-content;
    }   
  }
  .forgot-password {
    text-decoration: underline;
    cursor: pointer;
  }
</style>