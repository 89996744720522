<template>
    <div class="kyc-fields-area mb-20">
                <div class="kyc-item-section flex column">
                    <h3>Listing of services provided by trust service provider to company</h3>
                    <textarea v-model="textareaValue" placeholder="Write a comment here"
                    @input="textareaUpdates()"
                    ></textarea>
                </div>

                <table style="height: 100%;">
                    <thead>
                        <tr>
                            <td>Available Documents</td>
                            <td>Y</td>
                            <td>N</td>
                            <td>Comments Data Controller</td>
                            <td>Comments Complience Officer</td>
                        </tr>
                    </thead>
                    <tbody>

                        <TableFileRow 
                            :id="dataStructure['kyc-ma-1']?.id"
                            header="Management Agreement"
                            fileType='kyc-ma-1'
                            :file="dataStructure['kyc-ma-1']?.file"  
                            :filePresent="dataStructure['kyc-ma-1']?.file"  
                            :index="dataStructure['kyc-ma-1']?.file?.id" 
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-ma-1']?.comment_data_handler"
                            :comment_compliance_officer_value="dataStructure['kyc-ma-1']?.comment_compliance_officer"/>


                        <TableFileRow 
                            :id="dataStructure['kyc-ppa-2']?.id"
                            header="Principal Party Agreement"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-ppa-2']?.comment_data_handler"
                            :comment_compliance_officer_value="dataStructure['kyc-ppa-2']?.comment_compliance_officer"
                            fileType='kyc-ppa-2'
                            :filePresent="dataStructure['kyc-ppa-2']?.file"  
                            :file="dataStructure['kyc-ppa-2']?.file"  
                            :index="dataStructure['kyc-ppa-2']?.file?.id"  
                        />

                        <TableFileRow 
                            :id="dataStructure['kyc-feeibtbvdna-3']?.id"
                            header="Foreign Exchange Exemption issued by the Bank van de Nederlandse Antillen"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-feeibtbvdna-3']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-feeibtbvdna-3']?.comment_compliance_officer" 
                            fileType='kyc-feeibtbvdna-3'
                            :filePresent="dataStructure['kyc-feeibtbvdna-3']?.file"
                            @dateInputChangeFunc="handleDateChange"
                            label="Issue Date"
                            :issueDatePresent="true"  
                            :index="dataStructure['kyc-feeibtbvdna-3']?.file?.id"  
                            :file="dataStructure['kyc-feeibtbvdna-3']?.file"  
                            :issueDateValue="dataStructure['kyc-feeibtbvdna-3']?.dateIssued" 
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-lteabibtdoea-4']?.id"
                            header="License to Establish a Business issued by the Department of Economic Affairs"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-lteabibtdoea-4']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-lteabibtdoea-4']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-lteabibtdoea-4']?.file"    
                            :file="dataStructure['kyc-lteabibtdoea-4']?.file"  
                            fileType='kyc-lteabibtdoea-4'
                            @dateInputChangeFunc="handleDateChange"
                            label="Issue Date"
                            :index="dataStructure['kyc-lteabibtdoea-4']?.file?.id"  
                            :issueDatePresent="true"  
                            :issueDateValue="dataStructure['kyc-lteabibtdoea-4']?.dateIssued"  
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-mlibtdoea-4-1']?.id"
                            header="Management License issued by the Department of Economic Affairs"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-mlibtdoea-4-1']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-mlibtdoea-4-1']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-mlibtdoea-4-1']?.file"    
                            :file="dataStructure['kyc-mlibtdoea-4-1']?.file"  
                            fileType='kyc-mlibtdoea-4-1'
                            @dateInputChangeFunc="handleDateChange"
                            :issueDatePresent="true"  
                            label="Issue Date"
                            :index="dataStructure['kyc-mlibtdoea-4-1']?.file?.id"  
                            :issueDateValue="dataStructure['kyc-mlibtdoea-4-1']?.dateIssued"  
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-aoi-5']?.id"
                            header="Articles of Incorporation"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-aoi-5']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-aoi-5']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-aoi-5']?.file"   
                            :file="dataStructure['kyc-aoi-5']?.file"  
                            :index="dataStructure['kyc-aoi-5']?.file?.id"  
                            fileType='kyc-aoi-5'
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-attaoi-5-1']?.id"
                            header="Amendments to the Articles of Incorporation"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-attaoi-5-1']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-attaoi-5-1']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-attaoi-5-1']?.file"  
                            :file="dataStructure['kyc-attaoi-5-1']?.file"  
                            fileType='kyc-attaoi-5-1'
                            label="Issue Date"
                            @dateInputChangeFunc="handleDateChange"
                            :issueDatePresent="true"  
                            :index="dataStructure['kyc-attaoi-5-1']?.file?.id"  
                            :issueDateValue="dataStructure['kyc-attaoi-5-1']?.dateIssued"  
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-reftcoc-5-2']?.id"
                            header="Recent Extract from the Chamber of Commerce"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-reftcoc-5-2']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-reftcoc-5-2']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-reftcoc-5-2']?.file"    
                            :file="dataStructure['kyc-reftcoc-5-2']?.file"  
                            fileType='kyc-reftcoc-5-2'
                            @dateInputChangeFunc="handleDateChange"
                            :issueDatePresent="true"
                            :index="dataStructure['kyc-reftcoc-5-2']?.file?.id"  
                            :issueDateValue="dataStructure['kyc-reftcoc-5-2']?.dateIssued"  
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-oc-6']?.id"
                            header="Organizational Chart"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-oc-6']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-oc-6']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-oc-6']?.file"   
                            :file="dataStructure['kyc-oc-6']?.file"   
                            :index="dataStructure['kyc-oc-6']?.file?.id"  
                            fileType='kyc-oc-6'  
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-sr-7']?.id"
                            header="Shareholders' register"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-sr-7']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-sr-7']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-sr-7']?.file"   
                            :file="dataStructure['kyc-sr-7']?.file"   
                            :index="dataStructure['kyc-sr-7']?.file?.id"  
                            fileType='kyc-sr-7'
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-mreftcoc-7-1']?.id"
                            header="Share transfer agreement (after establishment)"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-mreftcoc-7-1']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-mreftcoc-7-1']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-reftcoc-7-1']?.file"    
                            :file="dataStructure['kyc-reftcoc-7-1']?.file"    
                            :index="dataStructure['kyc-reftcoc-7-1']?.file?.id"  
                            fileType='kyc-reftcoc-7-1'
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-sof-8']?.id"
                            header="Source of funds"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-sof-8']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-sof-8']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-sof-8']?.file"    
                            :file="dataStructure['kyc-sof-8']?.file"    
                            :index="dataStructure['kyc-sof-8']?.file?.id"  
                            fileType='kyc-sof-8'
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-ruling']?.id"
                            header="Tax Ruling"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['tax-ruling']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['tax-ruling']?.comment_compliance_officer" 
                            :filePresent="dataStructure['tax-ruling']?.file"    
                            :file="dataStructure['tax-ruling']?.file"    
                            :index="dataStructure['tax-ruling']?.file?.id"  
                            fileType='tax-ruling'
                        />

                        <TableFileRow 
                            v-for="(item, index) in dataStructure['cosbaof']"
                            :header="index == 0 ? 'Copy of signed bank account opening forms' : ''"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="item?.comment_data_handler"
                            :comment_compliance_officer_value="item?.comment_compliance_officer"
                            :filePresent="item?.file"    
                            :file="item?.file"    
                            fileType='cosbaof'
                            :index="item.id"
                            :id="item.id"

                        />

                        <tr class="no-border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <button class="add-new fit-content" @click="addnewDocument('cosbaof')">Add New</button>
                            </td>
                        </tr>
                        <tr class="no-border"><td class="margin-20"></td></tr>
                        <TableFileRow 
                            v-for="(item, index) in dataStructure['scfba']"
                            :header="index == 0 ? 'Signature card for bank account' : ''"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="item?.comment_data_handler"
                            :comment_compliance_officer_value="item?.comment_compliance_officer"
                            :filePresent="item?.file"    
                            :file="item?.file"    
                            fileType='scfba'
                            :index="item.id"
                            :id="item.id"
                        />
                        <tr class="no-border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <button class="add-new fit-content" @click="addnewDocument('scfba')">Add New</button>
                            </td>
                        </tr>
                        <tr class="no-border"
                        
                        ><td class="margin-20"></td></tr>

                        <TableFileRow 
                            :id="dataStructure['kyc-aa-y1-12']?.id"
                            header="Annual Accounts - last two (2) book years"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-aa-y1-12']?.comment_data_handler"
                            :comment_compliance_officer_value="dataStructure['kyc-aa-y1-12']?.comment_compliance_officer"
                            
                            :fiscal_year_ending_on="dataStructure['kyc-aa-y1-12']?.fiscal_year_ending_on"
                            :fiscal_year_ending_on_present="true"
                            :year="dataStructure['kyc-aa-y1-12']?.year"
                            yearPresent="true"
                            fileType='kyc-aa-y1-12'
                            year_title="Year 1"

                            @changeInfo="changeInfo"
                            title="Annual Accounts"
                            :index="dataStructure['kyc-aa-y1-12']?.file?.id"  
                            :filePresent="dataStructure['kyc-aa-y1-12']?.file"  
                            :file="dataStructure['kyc-aa-y1-12']?.file"  
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-aa-y2-12']?.id"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :comment_data_handler_value="dataStructure['kyc-aa-y2-12']?.comment_data_handler"
                            :comment_compliance_officer_value="dataStructure['kyc-aa-y2-12']?.comment_compliance_officer"
                            fileType='kyc-aa-y2-12'
                            title="Annual Accounts"

                            :fiscal_year_ending_on="dataStructure['kyc-aa-y2-12']?.fiscal_year_ending_on"
                            :fiscal_year_ending_on_present="true"

                            year_title="Year 2"
                            @changeInfo="changeInfo"
                            :year="dataStructure['kyc-aa-y2-12']?.year"
                            yearPresent="true"

                            :index="dataStructure['kyc-aa-y2-12']?.file?.id"  
                            :filePresent="dataStructure['kyc-aa-y2-12']?.file"  
                            :file="dataStructure['kyc-aa-y2-12']?.file"   
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-dogmos-13']?.id"
                            header="Discharge of General Meeting of Shareholders - last book year"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"

                            year_title="Year"
                            @changeInfo="changeInfo"
                            :year="dataStructure['kyc-dogmos-13']?.year"
                            yearPresent="true"

                            :comment_data_handler_value="dataStructure['kyc-dogmos-13']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-dogmos-13']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-dogmos-13']?.file"  
                            :file="dataStructure['kyc-dogmos-13']?.file"  
                            :index="dataStructure['kyc-dogmos-13']?.file?.id"  
                            fileType='kyc-dogmos-13'   
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-fpt-14']?.id"
                            header="Filing Profit Tax - last book year"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"

                            year_title="Year"
                            @changeInfo="changeInfo"
                            :year="dataStructure['kyc-fpt-14']?.year"
                            yearPresent="true"

                            :comment_data_handler_value="dataStructure['kyc-fpt-14']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-fpt-14']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-fpt-14']?.file"    
                            :file="dataStructure['kyc-fpt-14']?.file"    
                            :index="dataStructure['kyc-fpt-14']?.file?.id"  
                            fileType='kyc-fpt-14'
                        />
                        <TableFileRow 
                            :id="dataStructure['kyc-pcitaa-15']?.id"
                            header="Participation(s) contained in the annual accounts - last book year"
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"

                            :fiscal_year_ending_on="dataStructure['kyc-pcitaa-15']?.fiscal_year_ending_on"
                            :fiscal_year_ending_on_present="true"
                            
                            year_title="Annual accounts year"
                            @changeInfo="changeInfo"
                            :year="dataStructure['kyc-pcitaa-15']?.year"
                            yearPresent="true"

                            :comment_data_handler_value="dataStructure['kyc-pcitaa-15']?.comment_data_handler" 
                            :comment_compliance_officer_value="dataStructure['kyc-pcitaa-15']?.comment_compliance_officer" 
                            :filePresent="dataStructure['kyc-pcitaa-15']?.file"    
                            :file="dataStructure['kyc-pcitaa-15']?.file"    
                            :index="dataStructure['kyc-pcitaa-15']?.file?.id"  
                            fileType='kyc-pcitaa-15'
                        />

                    </tbody>
                </table>
            </div>
</template>


<script setup>
import { ref, computed, defineEmits, onMounted, defineProps } from 'vue'
import TableFileRow from '@/components/TableFileRow.vue'

const emit = defineEmits(['textareaUpdates', 'handleFileChange', 'handleDateChange', 'addnewDocument', 'changeInfop'])

const props = defineProps(['dataStructure'])


function commentChange(value) {
    emit('commentChange', value)
}

function textareaUpdates(value) {
    emit('textareaUpdates', textareaValue.value)
}
function addnewDocument(fileType) {
    emit('addnewDocument', fileType)
}

function changeInfo(event) {
    emit('changeInfo', event)
}
const textareaValue = ref(props.dataStructure.textarea.value)

function handleFileChange(event){
    emit('handleFileChange', event)
}
function handleDateChange(event){
    emit('handleDateChange', event)
}

</script>

<style scoped lang="scss">
    .header-section {
        background-color: white;
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .kyc-header {
        max-width: 1350px;
        width: 100%;
        text-align: left;
        font-size: 28px;
        position: relative;

        .tab-views {
            font-size: 14px;
            position: absolute;
            left: 0;
            top: 40px;
            display: flex;
            .tab {
                padding: 10px 20px;
                cursor: pointer;
                &.active {
                    background-color: #f5f5f5;
                }
            }
        
        }
    }
    .kyc-fields-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .kyc-fields-area {
        max-width: 1300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        background-color: white;
        border-radius: 5px;
        padding: 20px;
    }
    h3 {
        margin: 0px;
        margin-bottom: 4px;
    }
    .kyc-item-section {
        width: 1300px;
        margin-bottom: 30px;
        h3 {
            text-align: left;
        }
        &.extra-margin {
            margin-bottom: 50px;
        }
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        height: 150px;
    }
    .data-holder-comment {
        height: 120px;
        width: 500px;
    }
    .document-comments {
        vertical-align: bottom;
        
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .margin-top-20 {
        margin-top: 20px;
    }
    .margin-top-negative {
        margin-top: -40px;
        margin-bottom: 5px;
    }
    .limited-width {
        
    }


table {
    border-collapse: collapse;
    width: 100%;
}

thead {
    font-weight: bold;
}
td {
    padding: 10px;
    border: 1px solid black;
}

.no-border > td {
    border: none;
}
.margin-20 {
    height: 20px;
}
</style>