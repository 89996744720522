<template>
    <div class="preview-popup">
        <div class="preview">
        <table v-if="initial_data && initial_data[0]">
            <thead>
            <tr>
                <th v-for="(item, index) in savedInfoFormated(initial_data[0])" :class="`tabel-head ${item.value == 'Name Account Holder' ? 'account_holder': ''}`" >
                {{ formatItem(item) }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in initial_data" :key="`${index}-preview-row`">
                <td @click="makeInput($event) " v-for="(item2, index) in savedInfoFormated(item)" :key="`${item._id}___${item2}`" :data-id="`${item._id}___${item2}`">
                {{ item[item2] }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <button v-if="Object.keys(this.changed_data).length" @click="saveData" class="confirm-button" >Save</button>
    <div>
        <div v-if="errors" class="error-container">
            <h3 class="error-header">Errors:</h3>
            <div v-for="(error, index) in errors" :key="index" class="error-message">{{ error }}</div>
        </div>
    </div>

</div>
  </template>
  
  <script>
    import { mapState } from 'vuex';
    import API from '@/api/index.js'
    import Utils from '@/utils';
    export default {
    data() {
      return {
        initial_data: null,
        changed_data: {},
        mappedData: {},
        errors : null
      };
    },
    async mounted() {
        const respData = await API.getSavedInfo();
        this.initial_data = respData.data;
        this.mappedData = respData.data.reduce((acc, item) => {
            acc[item._id] = item;
            return acc;
        }, {});
    },
    computed: {
        Utils() {
            return Utils
        }
    },
    methods: {
        formatItem(item) {
          return item.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
        },
        savedInfoFormated(item){
            return Object.keys(item).filter(key => ['_id','user_id','__v'].indexOf(key) === -1);
        },
        makeInput(event){
            console.log('makeInput', event.target);
            const element = event.target;
            console.log('element', element)

            const dataId = element.getAttribute('data-id');
            let input = element.querySelector('input');
            if (input) return;
            let existingSelect = element.querySelector('select');
            if (existingSelect || element.tagName == 'SELECT') return;

            console.log('existingSelect', existingSelect)
            const [id, key] = dataId.split('___');
            const value = element.innerText;
            element.classList.add('focused');

            if (
                ['incorporation_country', 'country'].includes(key)
            )  {
                let select = document.createElement('select');
                select.classList.add('table-input');
                select.setAttribute('data-id', dataId);
                for (let country of this.Utils.getCountryList()) {
                    let option = document.createElement('option');
                    if (country.value === value) {
                        option.selected = true;
                    }
                    option.value = country.value;
                    option.text = country.text;
                    select.appendChild(option);
                    select.click();
                }
                select.addEventListener('change', (event) => {
                    if (element && select) {
                        select.blur();
                        // element.classList.remove('focused');
                        // if (this.mappedData[id][key] !== select.value) {
                        //     this.changed_data[dataId] = select.value
                        // } else {
                        //     delete this.changed_data[dataId]
                        // }
                        // element.innerHTML = select.value;
                    }
                });
                select.addEventListener('blur', () => {
                    console.log('blur');
                    if (element && select) {
                        element.classList.remove('focused');
                        if (this.mappedData[id][key] !== select.value) {
                            this.changed_data[dataId] = select.value
                        } else {
                            delete this.changed_data[dataId]
                        }
                        element.innerHTML = select.value;
                    }
                });
                element.innerHTML = '';
                element.appendChild(select);
            } else {
                element.innerHTML = `<input class='table-input' type="text" value="${value}" data-id="${dataId}" @blur="saveData(this)">`;
                input = element.querySelector('input');
                input.focus();
                input.addEventListener('blur', () => {
                    console.log('blur');
                    if (element && input) {
                        element.classList.remove('focused');
                        if (this.mappedData[id][key] !== input.value) {
                            this.changed_data[dataId] = input.value
                        } else {
                            delete this.changed_data[dataId]
                        }
                        element.innerHTML = input.value;
                    }
                });
                input.addEventListener('keydown', (event) => {
                    if (event.key === 'Enter') {
                        input.blur();
                    }
                });
            }
        },
        async saveData() {
          const objectKeys = {} 
          for (let dataId in this.changed_data) {
              const [id, key] = dataId.split('___');
              let object = objectKeys[id] || {};
              object[key] = this.changed_data[dataId];
              objectKeys[id] = object;
          }
          const resp = await API.updateSavedData(objectKeys);
          if (resp.error) {
            this.errors = resp.error;
          }
        }
    },
};
  </script>
  
  <style scoped lang="scss">
  .preview-popup {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 98vw;
    padding: 100px 2.5vw;
    overflow-y: auto;
    background: white;
    border-radius: 15px;
    color: black;
  }
  #close-popup {
    position: absolute;
    img {
      width: 30px;
    }
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .confirm-button {
    padding: 15px;
    font-size: 20px;
    width: 200px;
    margin-top: 20px;
  }
  .preview {
    max-width: 93vw;
  
    overflow-y: auto;
  }

  td, th {
    width: fit-content;
    white-space: nowrap;
    padding: 5px 10px;
    font-size: 16px;
    outline: 0.5px solid #bbbaba;
    &.modified {
      background-color: #afaf00;
      outline: 2px #afaf00 solid;
    }
   
  }
  .about-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: white;
    position: relative;
    background: #3a334e;
  
  }
  
  .drop-area {
    border: 2px dashed #ccc;
    padding: 40px 20px;
  
    text-align: center;
    cursor: pointer;
  }
  .about {
    color: white;
  }
  .drag-over {
    border-color: #2196F3;
  }
  .button-holder {
    width: 150px;
    margin: 25px auto;
    height: 30px;
      button {
        height: 30px;
      }
  }
  .error-container {
    height: 50vh;
    background-color: #f8d7da; /* Light red background color */
    color: #721c24; /* Dark red text color */
    border: 1px solid #f5c6cb; /* Border color */
    padding: 15px;
    overflow-y: auto;
    margin: 10px 0;
    border-radius: 5px;
    text-align: left;
  }
  .error-message {
    font-weight: bold;
  }
  .error-header {
    margin-top: 0;
  }
  td.focused {
    padding: 0;
  }
  
  </style>